<mobilize-page-header title="Contracte si servicii" [reverse]="true"></mobilize-page-header>

<mobilize-data-filter (onFilterExec)="execFilter($event)"
                      [processing]="filterProcessing"
                      [canExport]="false"
                      [filterOptions]="filterOptions"
                      page="contracts"
                      ></mobilize-data-filter>

<div class="contracts-page">
    @for (contract of contracts; track contract) {
        <mobilize-contract
            class="contract-row"
            section="contracts-list"
            [contract]="contract">
        </mobilize-contract>
    }

    @if (noContracts) {
        <div class="mobilize-card small-card text-center">
            Nu exista contracte
        </div>
    }
</div>

@if (!modal) {
    <mobilize-page-header title="Fa o plata" [reverse]="true"></mobilize-page-header>
}

<div class="mobilize-card">
    <div class="inner-page-title">Alege ce vrei sa achiti</div>
    <ng-template #targetAlertContainer></ng-template>

    <div class="bills-wrapper">
        @for (invoice of invoices; track invoice) {
            <div class="bill" [ngClass]="{'bill-checked': invoice.checked, 'bill-extended': invoice.extended}">
                <div class="bill-visible-info">
                    <div class="bill__check">
                        <div class="custom-checkbox">
                            <div class="fake-checkbox"
                                 [ngClass]="{'checked': invoice.checked}">
                                <span class="icon-mobilize-check-mark"></span>
                            </div>
                            <label>
                                <input type="checkbox" (change)="checkBill($event, invoice)"
                                       [(ngModel)]="invoice.checked">
                            </label>
                        </div>
                    </div>
                    <div class="bill__date">{{ parseDateToDisplay(invoice.invoice_date) }}</div>
                    <div class="bill__details">
                        <div class="bill__details-title">{{ invoice.detaliu_factura }}</div>
                        <div class="bill__details-due-status">
                            <span class="bill-status" [innerHTML]="invoiceStatus(invoice)"></span>
                            <span class="bill-due">{{ invoice.data_scadenta | date: 'dd/MM/YYYY' }}</span>
                        </div>
                    </div>
                    <div class="bill__amount" [innerHTML]="formatAmount(invoice.rest_amount, 'RON')"></div>
                    <div class="bill__download">
                        <span class="icon-mobilize-receipt"></span>
                    </div>
                    <div class="bill__extend" (click)="extendBill(invoice)"><span
                        class="icon-mobilize-chevron-down"></span>
                    </div>
                </div>

                <div class="bill__extension">
                    <div class="bill__extension-row">
                        <span>Facturat la:</span>
                        <span>{{ invoice.invoice_date | date: 'dd/MM/YYYY' }}</span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Nr. document:</span>
                        <span>{{ invoice.invoice_number }}</span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Total de plata:</span>
                        <span [innerHTML]="formatAmount(invoice.total, 'RON')"></span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Platit:</span>
                        <span [innerHTML]="formatAmount(invoice.paid_amount, 'RON')"></span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Rest de plata:</span>
                        <span [innerHTML]="formatAmount(invoice.rest_amount, 'RON')"></span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Stare:</span>
                        <span [innerHTML]="invoiceStatusText(invoice)"></span>
                    </div>
                </div>
            </div>
        }
    </div>

    <div class="total-payment" *ngIf="totalAmountForPayment > 0">
        <div class="title">total de plata</div>
        <div class="amount" [innerHTML]="formatAmount(totalAmountForPayment, 'RON')"></div>
        <div class="info">Vei fi transferat in pagina procesatorului de plati</div>
        <button class="mobilize-btn btn-orange" (click)="processPayment()" [mobilizeButtonLoader]="processing"
                originalContent="plateste securizat">plateste securizat
        </button>
    </div>

    <form #paymentForm action="https://www.activare3dsecure.ro/iteste3d/cgi-bin/" method="POST">
        @for (inp of inputsArray; track inp) {
            <input type="hidden" name="{{ inp.name }}" value="{{ inp.value }}"/>
        }
    </form>
</div>

<div class="outer-card-wrapper">
    <div class="title">
        Poti plati anticipat, integral sau partial
    </div>
    <button class="mobilize-btn btn-clear" [routerLink]="'/early-repayment'">plateste anticipat</button>
</div>

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    OnInit,
    SecurityContext,
    ViewChild
} from '@angular/core';
import { NgClass } from '@angular/common';
import { Contract } from '../../../interfaces/contract';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../services/loading.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { RequestService } from '../../../services/request.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { finalize, forkJoin } from 'rxjs';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'mobilize-new',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        FormsModule,
        ButtonLoaderDirective,
        MatProgressBar
    ],
    templateUrl: './new.component.html',
    styleUrl: './new.component.scss'
})
export class NewComponent implements OnInit, AfterViewInit {

    @ViewChild('requestsContainer', { static: false }) requestsContainer!: ElementRef;
    @ViewChild('contractsContainer', { static: false }) contractsContainer!: ElementRef;

    private resizeObserver!: ResizeObserver;
    private cdr = inject(ChangeDetectorRef);
    private _dashboard = inject(DashboardService);
    private _fb = inject(FormBuilder);
    private _loading = inject(LoadingService);
    private _request = inject(RequestService);
    private _sanitizer = inject(DomSanitizer);
    private _router = inject(Router);

    requestForm: FormGroup;
    selectedContract!: Contract;
    selectedRequest = '';
    changing = false;
    pageStep = 1;
    otherRequestText = '';
    requestsContainerHeight!: any;
    contractsContainerHeight!: any;
    contracts: Contract[] = [];
    types: any[] = [];
    showContractsList = false;
    showForm = false;
    hasOtherRequest = false;

    constructor() {
        this.requestForm = this._fb.group({
            message: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        this._loading.setLoadingState(true);

        forkJoin({
            dashboardContracts: this._dashboard.getDashboardContracts(),
            types: this._request.getTypes()
        }).subscribe({
            next: ({ dashboardContracts, types }) => {
                this.contracts = dashboardContracts;
                this.contracts.forEach((item, idx) => {
                    item.extendable = false;
                    item.visible = true;
                });
                this.types = types;
                this.types.forEach((item, idx) => {
                    item.visible = true;
                })
                this._loading.setLoadingState(false);
            },
            error: (err) => {
                // Handle errors here if needed
                console.error('Error occurred while fetching data:', err);
                this._loading.setLoadingState(false);
            }
        });
        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.target === this.requestsContainer.nativeElement) {
                    this.requestsContainerHeight = entry.contentRect.height;
                    this.cdr.detectChanges();
                }
                if (entry.target === this.contractsContainer.nativeElement) {
                    this.contractsContainerHeight = entry.contentRect.height;
                    this.cdr.detectChanges();
                }
            }
        });
    }

    sanitizeInput(input: string): any {
        return this._sanitizer.sanitize(SecurityContext.HTML, input);
    }

    ngAfterViewInit() {
        if (this.requestsContainer && this.requestsContainer.nativeElement) {
            this.requestsContainerHeight = this.requestsContainer.nativeElement.offsetHeight;
            this.resizeObserver.observe(this.requestsContainer.nativeElement);
        }
        if (this.contractsContainer && this.contractsContainer.nativeElement) {
            this.contractsContainerHeight = this.contractsContainer.nativeElement.offsetHeight;
            this.resizeObserver.observe(this.contractsContainer.nativeElement);
        }
    }

    selectContract(contract: any) {
        this.contracts.filter(item => {
            if (item.contract_id !== contract.contract_id) {
                item.visible = false;
            }
        });
        this.showForm = true;
        this.pageStep = 3;
        this.selectedContract = contract;
    }

    selectRequestType(req: any) {
        this.types.forEach(item => {
            if (item.request_type_name !== req.request_type_name) {
                item.visible = false;
            }
        });
        if (req.name === 'other') {
            if (this.sanitizeInput(this.otherRequestText) === '') {
                this.changeRequestSelection();
                return;
            }
            this.types.push({ request_type_name: this.otherRequestText, request_type_uid: '-', visible: true, temporary: true });
        }
        this.showContractsList = true;
        this.hasOtherRequest = false;
        this.selectedRequest = this.types.filter(req => req.visible)[0];
        this.pageStep = 2;
    }

    changeRequestSelection() {
        this.pageStep = 1;
        this.types.forEach(item => item.visible = true);
        this.types = this.types.filter(item => !item.temporary);
        this.hasOtherRequest = false;
        this.showContractsList = false;
        this.otherRequestText = '';
    }

    changeContractSelection() {
        this.pageStep = 2;
        this.contracts.forEach(item => item.visible = true);
        this.showForm = false;
    }

    otherRequest() {
        this.types.forEach(item => item.visible = false);
        this.hasOtherRequest = true;
        this.pageStep = 1;
    }

    sendRequest() {
        const payload = {
            request: this.selectedRequest,
            contract_id: this.selectedContract.contract_id,
            contract_number: this.selectedContract.contract_number,
            message: this.requestForm.value.message
        }
        this.changing = true;

        this._request.sendRequest(payload)
            .pipe(
                finalize(() => {
                    this.changing = false;
                })
            )
            .subscribe(rsp => {
            if (rsp.success) {
                this._router.navigateByUrl('/my-requests/inbox');
            } else {

            }
        });
    }
}

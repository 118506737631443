import { Component, inject, Input, OnInit } from '@angular/core';
import { HeaderButton } from '../../interfaces/header-button';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'mobilize-page-header',
	standalone: true,
    imports: [
        NgClass
    ],
	templateUrl: './page-header.component.html',
	styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent implements OnInit {

	@Input() title = '';
	@Input() section = '';
    @Input() button?: HeaderButton;
    @Input() reverse?: boolean = false;

    private _router = inject(Router);
    private _kc = inject(KeycloakService);
    private _user = inject(UserService);

    user!: any;
    userLoaded = false;

    ngOnInit() {
        this._user.userData().subscribe(data => {
            this.user = data;
            this.userLoaded = true;
        })
    }

    buttonAction() {
        switch (this.button?.action) {
            case 'route':
                this._router.navigateByUrl(this.button.actionData.route);
                break;
        }
    }

    getToken() {
        this._kc.getToken().then(rsp => {
            console.log(rsp)
        })
    }
}

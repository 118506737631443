import { APP_INITIALIZER, ApplicationConfig, Provider, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { backofficeRoutes, routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { apiInterceptor } from './interceptor/api.interceptor';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { CookieService } from 'ng2-cookies';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { tokenInterceptor } from './interceptor/token.interceptor';
import { DateAdapter, NativeDateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatIconRegistry } from '@angular/material/icon';

function initializeKeycloak(keycloak: KeycloakService) {
    const env = environment;

    return () =>
        keycloak.init({
            // Configuration details for Keycloak
            config: {
                url: env.authUrl, // URL of the Keycloak server
                realm: 'mobilize', // Realm to be used in Keycloak
                clientId: 'mobilize-fe',

            },
            // Options for Keycloak initialization
            initOptions: {
                onLoad: 'check-sso', // Action to take on load
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html', // URI for silent SSO checks,
                redirectUri: window.location.origin + '/dashboard'
            },
            // Enables Bearer interceptor
            enableBearerInterceptor: false,
            // Prefix for the Bearer token
            bearerPrefix: 'Bearer',

            // URLs excluded from Bearer token addition (empty by default)
            //bearerExcludedUrls: []
        });
}

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: KeycloakBearerInterceptor,
    multi: true
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService]
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimationsAsync(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter([ ...routes, ...backofficeRoutes ]),
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([
                apiInterceptor
            ])
        ),
        CookieService,
        KeycloakInitializerProvider, // Initializes Keycloak
        KeycloakBearerInterceptorProvider, // Provides Keycloak Bearer Interceptor
        KeycloakService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.captchaKey,
                size: 'invisible'
            } as RecaptchaSettings,
        },
    ],
};

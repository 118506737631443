import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CustomKeycloakService } from '../services/custom-keycloak.service';
import { KeycloakService } from 'keycloak-angular';

export const authGuard: CanActivateFn = async (route, state) => {
    const _kc = inject(CustomKeycloakService)
    const _keycloak = inject(KeycloakService);

    const keycloak = _keycloak.getKeycloakInstance();

    if (keycloak.authenticated) {
        return true;
    }

    if (!_kc.isLoginInProgress()) {
        _kc.setLoginInProgress();
        try {
            await keycloak.login({
                redirectUri: window.location.origin + state.url
            });
        } finally {
            _kc.unsetLoginInProgress();
        }
    }

    return false;

}

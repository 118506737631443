import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'mobilize-pagination',
    standalone: true,
    imports: [
        MatButton,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        MatButton,
        MatRipple,
    ],
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.scss'
})
export class PaginationComponent {

    @Output() onPageChange = new EventEmitter<number>();
    @Input() currentPage = 1;
    @Input() totalPages!: number;
    @Input() limit!: number;
    @Input() pagesArray: any[] = [];

    onPageSelect(page: number, limit?: number) {
        const payload: any = {
            page
        }

        if (limit) {
            payload.limit = limit;
        }
        this.onPageChange.emit(payload);
    }
}

<div class="app-pagination">
    <div class="pages">
        <div class="navigation navigation-left" (click)="onPageSelect(currentPage - 1)">
            <button mat-button [disabled]="currentPage === 1"><span class="icon-mobilize-chevron-left"></span>
            </button>
        </div>
        <div class="navigation navigation-page">
            <button class="current-page" mat-button [disabled]="totalPages === 1"
                    [matMenuTriggerFor]="menu">{{ currentPage }}
                /{{ totalPages }}
                <span
                    class="icon-mobilize-chevron-down"></span></button>
            <mat-menu #menu="matMenu">
                @for (page of pagesArray; track page) {
                    <button mat-menu-item (click)="onPageSelect(page)">{{ page }}
                        /{{ totalPages }}
                    </button>
                }
            </mat-menu>
        </div>
        <div class="navigation navigation-right" (click)="onPageSelect(currentPage + 1)">
            <button mat-button [disabled]="totalPages === 1 || currentPage === totalPages"><span
                class="icon-mobilize-chevron-right"></span></button>
        </div>
    </div>
    <div class="pages">
        <div class="navigation">
            <button class="current-page" mat-button [matMenuTriggerFor]="displayMenu">{{ limit }}/pagina <span
                class="icon-mobilize-chevron-down"></span></button>
            <mat-menu  #displayMenu="matMenu">
                <button mat-menu-item (click)="onPageSelect(currentPage, 5)">5/pagina</button>
                <button mat-menu-item (click)="onPageSelect(currentPage, 10)">10/pagina</button>
                <button mat-menu-item (click)="onPageSelect(currentPage, 20)">20/pagina</button>
                <button mat-menu-item (click)="onPageSelect(currentPage, 50)">50/pagina</button>
            </mat-menu>
        </div>
    </div>
</div>

<div class="mobilize-modal">
    <div class="modal-title">{{ data.title }}</div>

    <div class="modal-content">
        {{ data.content }}
    </div>

    <div class="modal-buttons">
        <button class="mobilize-btn btn-clear" (click)="close(false)">renunt</button>
        <button class="mobilize-btn btn-orange" (click)="close(true)">confirm</button>
    </div>
</div>

<mobilize-page-header title="Documente utile" [reverse]="true"></mobilize-page-header>

<div class="tabs-wrapper">
    <div class="tabs-header">
        <div class="tabs-header__tab" routerLink="/existing-documents"><span>Documente generate</span></div>
        <div class="tabs-header__tab selected"><span>Genereaza document</span></div>
    </div>
</div>

<div class="generate-document-wrapper">
    <div class="progress-tracking">
        <div class="bullet" [ngClass]="{'active': pageStep === 1, 'passed': pageStep === 2}">1</div>
        <div class="tracking-bar" [ngClass]="{'passed': pageStep === 2}"
             [style.height]="(documentsContainerHeight + 20) + 'px'"></div>
        <div class="bullet" [ngClass]="{'active': pageStep === 2}">2</div>
    </div>

    <div class="document-selection-wrapper">

        <div class="title">Ce document doresti?</div>

        <div class="document-selection" #documentsContainer>
            @for (doc of documents; track doc) {
                @if (doc.visible) {
                    <div class="document-selection__document card-row" (click)="selectDocument(doc)">
                        <div class="name">{{ doc.name }}</div>
                        <span class="icon-mobilize-chevron-right"></span>
                    </div>
                }
            }
        </div>

        <div class="title">Creeaza documentul</div>

        @if (showSelectContractSection) {
            <div class="select-contract-option">
                <div class="title">{{ showForm ? 'Ai ales contractul' : 'Alege contractul' }}</div>

                @for (contract of contracts; track contract) {
                    @if (contract.visible) {
                        <div class="contract-row card-row" (click)="selectContract(contract)">
                            <div class="name">
                                <span class="title">{{ contract.title }}</span>
                                <span class="subtitle">{{ contract.financedGood?.name }}
                                    , {{ contract.financedGood?.color }}
                                    , serie sasiu {{ contract.financedGood?.chassisNumber }}</span>
                            </div>
                            <span class="icon-mobilize-chevron-right"></span>
                        </div>
                    }
                }

                @if (!showForm) {
                    <button class="mobilize-btn btn-clear" (click)="changeDocumentSelection()">inapoi</button>
                }

                @if (showForm) {
                    <button class="mobilize-btn btn-clear change-contract-selection"
                            (click)="changeContractSelection()">schimba
                    </button>

                    @if (formData) {
                        <div class="title">Datele pe care le vrei pe document:</div>
                        <mobilize-json-form [jsonFormData]="formData"></mobilize-json-form>
                    } @else {
                        <button class="mobilize-btn btn-orange">genereaza document</button>
                    }
                }
            </div>
        }
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContractService {

    constructor(private _http: HttpClient) {
    }

    getContract(id: number): Observable<any> {
        return this._http.get(`contracts/contract-details/${id}`);
    }

    getContracts(payload: any): Observable<any> {
        return this._http.post(`contracts/list-contracts`, payload);
    }
}

<button class="mobilize-btn btn-orange" routerLink="/my-requests">inapoi la inbox</button>

<div class="details-wrapper">
    @if (request) {
        <div class="title">{{ request.request_subject }}</div>

        <div class="description">
            <div class="description-row">
                contract: <span>{{ request.contract_number }}</span>
            </div>
            <div class="description-row">
                data solicitarii: <span>{{ request.created_at | date: 'dd/MM/YYYY' }}</span>
            </div>
            <div class="description-row">
                nr. solicitare: <span>{{ request.request_number | padZero: 7 }}</span>
            </div>
        </div>

        @if (isNew) {
            <div class="mobilize-card new-request-confirmation">
                <div class="title">Confirmare de primire</div>
                <span class="icon-mobilize-check-mark"></span>
                <p>
                    Solicitarea ta a fost inregistrata cu <span class="font-green">numarul 20892.</span> In cel mai scurt timp vei primi o solutie.
                </p>
            </div>
        }
    }

    <div class="messages-wrapper">
        @for (message of messages; track message; let idx = $index; let first = $first) {
            <div class="message-box">
                <div class="title">
                    <span>({{ messages.length - idx }})</span>
                    {{ message.author }}
                </div>

                <div class="content">
                    <div class="receiver">catre: <span>{{ message.receiver }}</span></div>
                    <div class="created-at">{{ message.created_at | date: 'dd/MM/YYYY HH:mm' }}</div>
                    <div class="title" [ngClass]="{'fixed': message.fe_status === 'Finalizata' && message.isReply}">
                        @if (message.fe_status === 'Finalizata' && message.isReply ) {
                            <span class="icon-mobilize-check-mark"></span>
                            Solutionare
                        } @else {
                            <span class="subject" [innerHTML]="message.subject"></span>
                        }
                    </div>

                    <div class="text" [innerHTML]="message.content"></div>

                    @for (file of message.request_files; track file) {
                        <div class="download-document" (click)="downloadFile(file)">
                            <span class="icon-mobilize-export"></span> {{ file }}
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</div>

import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { Router, RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { InstallmentsSliderComponent } from '../../components/installments-slider/installments-slider.component';
import { ContractComponent } from '../../components/contract/contract.component';
import { Contract } from '../../interfaces/contract';
import { DashboardService } from './dashboard.service';
import { BaseComponent } from '../../components/base.component';
import { LoadingService } from '../../services/loading.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'mobilize-dashboard',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgOptimizedImage,
        InstallmentsSliderComponent,
        ContractComponent,
        RouterLink
    ],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent extends BaseComponent implements OnInit {

    private router = inject(Router);
    private _dashboard = inject(DashboardService);
    private _loading = inject(LoadingService);
    private _user = inject(UserService);

    contracts: Contract[] = [];
    unpaidAmount = 0;

    constructor() {
        super();
        this._loading.setLoadingState(true);
    }

    goToDocuments() {
        this.router.navigateByUrl('existing-documents');
    }

    ngOnInit() {
        this._dashboard.getDashboardContracts().subscribe(rsp => {
            this.contracts = rsp;
            this.contracts.forEach((item, idx) => {
                if (idx === 0) {
                    item.contractState = 'opened';
                } else {
                    item.contractState = 'closed';
                }
                item.extendable = true

                this.unpaidAmount += parseFloat(item.unpaid_invoices_ron);
            });

            this._loading.setLoadingState(false);
        });
    }
}

import { Component, Inject, inject } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'mobilize-add-user',
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        MatLabel,
        ReactiveFormsModule
    ],
    templateUrl: './add-user.component.html',
    styleUrl: './add-user.component.scss'
})
export class AddUserComponent {
    private _fb = inject(FormBuilder);
    private _user = inject(UserService);
    private _modalRef = inject(MatDialogRef<AddUserComponent>);

    data = inject(MAT_DIALOG_DATA);

    form: FormGroup;

    constructor() {
        this.form = this._fb.group({
            fullName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            role: [{value: 'eCare Admin', disabled: true}]
        })
    }

    closeModal() {
        this._modalRef.close();
    }

    createUser() {
        const userData = this.form.value;
        this._user.createMobilizeAdmin(userData)
            .pipe(
                catchError((err: any) => {
                    if (err.error.statusCode === 409) {
                        this._modalRef.close({error: 'Exista deja un cont cu adresa de email introdusa'});
                    }
                    if (err.error.statusCode === 400) {
                        this._modalRef.close({error: err.error.message});
                    }
                    return of({})
                })
            )
            .subscribe((rsp: any) => {
                if (rsp.success) {
                    this._modalRef.close({success: rsp.success});
                }
            });
    }
}

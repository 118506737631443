import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(private http: HttpClient) {}

    downloadFile(key: any): Observable<any> {
        return this.http.get(`upload/presigned-url?key=${key}`);
    }
}

<div class="mobilize-card">
    <div class="contact-options-wrapper">
        <div class="options-side">
            @for (option of contactOptions; track option) {
                <div class="contact-options-wrapper__row">
                    <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover">
                        <div class="fake-checkbox"
                             [ngClass]="{'checked': option.checked}">
                            <span class="icon-mobilize-check-mark"></span>
                        </div>
                        <label>
                            <input type="checkbox" [(ngModel)]="option.checked" (change)="onCheckboxChange()">
                        </label>
                    </div>
                    <div class="text">
                        {{ option.name }} {{ option.label }}
                    </div>
                </div>
            }
        </div>

        <div class="contact-methods-side">
            <div class="contact-options-wrapper__row">
                <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover"
                     [ngClass]="{'custom-checkbox__disabled': contactMethodsDisabled}">
                    <div class="fake-checkbox"
                         [ngClass]="{'checked': email}">
                        <span class="icon-mobilize-check-mark"></span>
                    </div>
                    <label>
                        <input type="checkbox" [(ngModel)]="email">
                    </label>
                </div>
                <div class="text">
                    Email (CMKEML)
                </div>
            </div>
            <div class="contact-options-wrapper__row">
                <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover"
                     [ngClass]="{'custom-checkbox__disabled': contactMethodsDisabled}">
                    <div class="fake-checkbox"
                         [ngClass]="{'checked': sms}">
                        <span class="icon-mobilize-check-mark"></span>
                    </div>
                    <label>
                        <input type="checkbox" [(ngModel)]="sms">
                    </label>
                </div>
                <div class="text">
                    Sms (CMKSMS)
                </div>
            </div>
            <div class="contact-options-wrapper__row">
                <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover"
                     [ngClass]="{'custom-checkbox__disabled': contactMethodsDisabled}">
                    <div class="fake-checkbox"
                         [ngClass]="{'checked': post}">
                        <span class="icon-mobilize-check-mark"></span>
                    </div>
                    <label>
                        <input type="checkbox" [(ngModel)]="post">
                    </label>
                </div>
                <div class="text">
                    Posta (CMKMIL)
                </div>
            </div>
            <div class="contact-options-wrapper__row">
                <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover"
                     [ngClass]="{'custom-checkbox__disabled': contactMethodsDisabled}">
                    <div class="fake-checkbox"
                         [ngClass]="{'checked': phone}">
                        <span class="icon-mobilize-check-mark"></span>
                    </div>
                    <label>
                        <input type="checkbox" [(ngModel)]="phone">
                    </label>
                </div>
                <div class="text">
                    Telefon (CMKTEL)
                </div>
            </div>
        </div>
    </div>
</div>

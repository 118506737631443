@if (!superAccount || !registerPage) {
    <div class="main-wrapper">
        @if (userLogged) {
            <div class="main-wrapper__menu">
                @if (showMenu) {
                    <mobilize-main-menu [backOffice]="backOffice"></mobilize-main-menu>
                }
            </div>

            <div class="main-wrapper__content">
                @if (dataLoading()) {
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                }
                <router-outlet/>

                @if (!backOffice) {
                    <div class="footer">
                        <div class="footer__left">
                            <a class="footer-link">Termeni si conditii</a>
                            <a class="footer-link">Politica de confidentialitate</a>
                            <a class="fooster-link">Date de contact</a>
                            <a class="footer-link">Intrebari frecvente</a>
                            <a class="footer-link">ANPC</a>
                        </div>
                        <div class="footer__right">
                            <div class="support-title">Suport clienti</div>
                            <div class="support-phone">021/201.20.00 (L-V: 9-18)</div>
                            <button class="mobilize-btn btn-clear" routerLink="/my-requests/new-request">trimite solicitare</button>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
} @else {
    <div class="main-wrapper" [ngClass]="{'register-wrapper': registerPage}">
        <div class="main-wrapper__content">
            <router-outlet/>
        </div>
    </div>
}

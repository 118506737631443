<form [formGroup]="myForm">
    <ng-container *ngFor="let control of jsonFormData?.controls">
        @if (control.type === 'text') {
            <div class="form-control">
                <label>{{ control.label }}</label>
                <input [placeholder]="control.placeholder" [formControlName]="control.name" [type]="control.type">
            </div>
        }

        @if (control.type === 'date') {
            <div class="form-control form-control__date">
                <label>{{ control.label }}</label>
                <input [placeholder]="control.placeholder!" matInput [matDatepicker]="picker" [formControlName]="control.name">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
        }
    </ng-container>

    <button class="mobilize-btn btn-orange" (click)="submitForm()">genereaza document</button>
</form>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'padZero',
    standalone: true
})
export class PadZeroPipe implements PipeTransform {

    transform(value: number, digits: number): string {
        let numAsString = value.toString();
        while (numAsString.length < digits) {
            numAsString = '0' + numAsString;
        }
        return numAsString;
    }

}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    private _http = inject(HttpClient);

    getInvoices(payload: any): Observable<any> {
        let query = '';
        if (payload.page) {
            query += `?page=${payload.page}`;
        } else {
            query += `?page=1`;
        }
        if (payload.limit) {
            query += `&limit=${payload.limit}`;
        } else {
            query += `&limit=10`;
        }
        return this._http.get(`invoices/list${query}`);
    }
}

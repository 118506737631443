import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private _http: HttpClient) {
    }

    getDashboardContracts(): Observable<any> {
        return this._http.post('dashboard/active-contracts', {});
    }
}

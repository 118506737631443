<form [formGroup]="myForm">
    <div class="form-group-grid">
        <ng-container *ngFor="let control of jsonFormData?.controls; let idx = index">
            @if (control.type === 'text') {
                <mat-form-field class="example-full-width">
                    <mat-label>{{ control.label }}</mat-label>
                    @if (control.name === 'name') {
                        <input matInput [placeholder]="control.placeholder" [formControlName]="control.name"
                               mobilizeAutoCapitalize>
                    } @else if (control.name === 'series') {
                        <input matInput [placeholder]="control.placeholder" [formControlName]="control.name"
                               mobilizeAutoUppercase>
                    } @else {
                        <input matInput [placeholder]="control.placeholder" [formControlName]="control.name">
                    }
                </mat-form-field>

                @if (control.label === 'Nume imputernicit') {
                    <div></div>
                }
            }

            @if (control.type === 'date') {
                <mat-form-field class="example-full-width">
                    <mat-label>{{ control.label }}</mat-label>
                    @if (control.name === 'startDate') {
                        <input [placeholder]="control.placeholder!" matInput [matDatepicker]="picker"
                               [formControlName]="control.name" [min]="minStartDate" (dateChange)="startDateChange('change', $event)">
                    } @else {
                        <input [placeholder]="control.placeholder!" matInput [matDatepicker]="picker"
                               [formControlName]="control.name" [min]="minDate()" [max]="maxEndDate">
                    }
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            }
        </ng-container>
    </div>

    <button class="mobilize-btn btn-orange" (click)="submitForm()" [mobilizeButtonLoader]="processing" originalContent="genereaza document">genereaza document</button>
</form>

@if (dataLoaded) {
    <mobilize-page-header [title]="'Contract ' + contract.contract_number" [reverse]="true"
                          [button]="headerButton"></mobilize-page-header>

    <div class="contract-details-wrapper">
        <div class="anchors">
            <div class="anchor" (click)="focusSection('documents')">
                <span class="icon-mobilize-attach"></span>
                <span>Documente ({{ contract.documents?.length }})</span>
            </div>
            <div class="anchor" (click)="focusSection('payment-schedule')">
                <span class="icon-mobilize-analytics"></span>
                <span>Scadentar</span>
            </div>
            <div class="anchor" (click)="focusSection('options')">
                <span class="icon-mobilize-cart"></span>
                <span>Servicii (0)</span>
            </div>
            <div class="anchor" (click)="focusSection('history')">
                <span class="icon-mobilize-history"></span>
                <span>Istoric actiuni</span>
            </div>
        </div>

        <div
            class="financed-good-bar">{{ contract.contract_objects[0].vehicle_brand }} {{ contract.contract_objects[0].vehicle_model }}
            - {{ contract.contract_objects[0]?.colour }}
        </div>

        <div class="mobilize-card">
            <div class="title">Date generale</div>

            <div class="details-row">
                <span>Numar contract</span>
                <span>{{ contract.contract_number }}</span>
            </div>
            <div class="details-row">
                <span>Tip contract</span>
                <span>{{ contract.contract_type }}</span>
            </div>
            <div class="details-row">
                <span>Stare</span>
                <span [ngClass]="{'inactive': contract.contract_state === 'Inchis', 'active': contract.contract_state !== 'Inchis'}">
                @if (contract.contract_state === 'Inchis') {
                    <i class="offline"></i>
                } @else {
                    <i class="online"></i>
                }
                    {{ contract.contract_state === 'Inchis' ? 'inactiv' : 'activ' }}
            </span>
            </div>
            <div class="details-row">
                <span>Sold restant</span>
                <span [innerHTML]="formatAmount(contract.overdue_invoices_ron, contract.currency_code)"></span>
            </div>
            <div class="details-row">
                <span>Nr. zile intarziere</span>
                <span>{{ contract.delay_days }}</span>
            </div>
            <div class="details-row">
                <span>Data</span>
                <span>{{ contract.contract_date | date: 'dd/MM/YYYY' }}</span>
            </div>
        </div>

        <div class="mobilize-card" #paymentScheduleSection>
            <div class="title font-orange">Scadentar</div>

            @if (contract.contract_state !== 'Inchis') {
                <mobilize-installments-slider [showInstallments]="contract.contract_state !== 'Inchis'"
                                              [type]="'extended'"
                                              [maxInstallments]="maxInstallments"
                                              [payedInstallments]="contract.previous_instalments + 1"></mobilize-installments-slider>

                <div class="payment-details">
                    <div>Ai achitat din capital: <span
                        [innerHTML]="formatAmount(payedAmount, contract.currency_code)"></span>
                    </div>
                    @if (contract.outstanding) {
                        <div>Rest de plata capital: <span
                            [innerHTML]="formatAmount(outstandingAmount(), contract.currency_code)"></span>
                        </div>
                    }
                </div>
            }

            <div class="payment-actions">
                <button class="mobilize-btn btn-orange">descarca scadentar</button>
                @if (contract.contract_state !== 'Inchis') {
                    <button class="mobilize-btn btn-clear">plateste anticipat</button>
                }
            </div>
        </div>

        <div class="mobilize-card" #optionsSection>
            <div class="title font-orange">Servicii ({{ services.length }})</div>

            <div class="options-wrapper">
                @for (option of services; track option) {
                    <mobilize-contract-option class="option-component" [contractState]="contract.contract_state" [contract]="contract" [option]="option"></mobilize-contract-option>
                }
            </div>
        </div>

        <div class="mobilize-card documents" #documentsSection>
            <div class="title font-orange">Documente ({{ contract.documents?.length }})</div>

            @for (document of contract.documents; track document) {
                <div class="document-row">
                    <span class="icon-mobilize-export"></span>

                    <div class="document-row__details">
                        <span>{{ document.name }}</span>
                        <span>generat la: {{ document.date }}</span>
                    </div>
                </div>
            }
        </div>

        <div class="mobilize-card">
            <div class="title">Date financiare</div>

            <div class="details-row">
                <span>Urmatoarea rata</span>
                @if (contract.next_installment) {
                    <span [innerHTML]="formatAmount(contract.next_installment, contract.currency_code)"></span>
                } @else {
                    <span>-</span>
                }
            </div>
            <div class="details-row">
                <span>Urmatoarea data scadenta</span>
                <span>{{ contract.next_due_date ? (contract.next_due_date | date: 'dd/LL/YYYY') : '-' }}</span>
            </div>
            <div class="details-row">
                <span>Dobanda anuala</span>
                <span>{{ contract.interest_percent }}%</span>
            </div>
            <div class="details-row">
                <span>Pret cu tot cu taxe</span>
                <span [innerHTML]="formatAmount(contract.purchasing_price_with_vat, contract.currency_code)"></span>
            </div>
            <div class="details-row">
                <span>Valoare avans (fara taxe)</span>
                <span [innerHTML]="formatAmount(contract.advance_amount, contract.currency_code)"></span>
            </div>
            <div class="details-row">
                <span>Avans</span>
                <span>{{ contract.advance_percent }}%</span>
            </div>

            @if (showMoreSections.financial) {
                <div [@toggleHeight]>
                    <div class="details-row">
                        <span>Valoare avans (cu taxe)</span>
                        <span [innerHTML]="formatAmount(contract.advance_amount, contract.currency_code)"></span>
                    </div>
                    @if (contract.contract_type !== 'Operational') {
                        <div class="details-row">
                            <span>Valoare reziduala</span>
                            <span [innerHTML]="formatAmount(contract.rezidual_amount, contract.currency_code)"></span>
                        </div>
                    }
                    <div class="details-row">
                        <span>Procent penalizari</span>
                        <span>{{ contract.penalty_percent }}%</span>
                    </div>
                </div>
            }

            <div class="show-more" [ngClass]="{'extended': showMoreSections.financial}" (click)="showMore('financial')">
                {{ showMoreSections.financial ? 'vezi mai putin' : 'vezi mai mult' }}
            </div>
        </div>

        <div class="mobilize-card">
            <div class="title">Bun finantat</div>

            <div class="details-row">
                <span>Descriere</span>
                <span>{{ contract.contract_objects[0].vehicle_brand }} {{ contract.contract_objects[0].vehicle_model }}</span>
            </div>
            <div class="details-row">
                <span>Numar inmatriculare</span>
                <span>{{ contract.contract_objects[0].registration_number }}</span>
            </div>
            <div class="details-row">
                <span>Culoare</span>
                <span>{{ contract.contract_objects[0]?.colour }}</span>
            </div>
            <div class="details-row">
                <span>Tip combustibil</span>
                <span>{{ contract.contract_objects[0]?.fuel }}</span>
            </div>
            <div class="details-row">
                <span>Avans</span>
                <span [innerHTML]="formatAmount(contract.advance_amount, contract.currency_code)"></span>
            </div>

            @if (showMoreSections.good) {
                <div class="details-row">
                    <span>Serie de sasiu</span>
                    <span>{{ contract.contract_objects[0]?.chassis_number }}</span>
                </div>
                <div class="details-row">
                    <span>Anul fabricatiei</span>
                    <span>{{ contract.contract_objects[0]?.made_year }}</span>
                </div>
                <div class="details-row">
                    <span>Capacite cilindrica</span>
                    <span>{{ contract.contract_objects[0]?.cap_cil }}</span>
                </div>
            }

            <div class="show-more" [ngClass]="{'extended': showMoreSections.good}" (click)="showMore('good')">
                {{ showMoreSections.good ? 'vezi mai putin' : 'vezi mai mult' }}
            </div>
        </div>
    </div>
}

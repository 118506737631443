<div class="modal-title">Avem nevoie de consimtamantul tau</div>

<div class="modal-content">
    <div class="agreements-wrapper">
        @for (agreement of agreements; track agreement) {
            <div class="agreements-wrapper__row">
                <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover">
                    <div class="fake-checkbox"
                         [ngClass]="{'checked': agreement.checked}">
                        <span class="icon-mobilize-check-mark"></span>
                    </div>
                    <label>
                        <input type="checkbox" (change)="checkAgreements($event, 2)" [(ngModel)]="agreement.checked">
                    </label>
                </div>
                <div class="text">
                    {{ agreement.name }}
                </div>
            </div>
        }
    </div>

    <ng-template #targetAlertContainer></ng-template>
</div>

<div class="modal-buttons">
    <button class="mobilize-btn btn-orange" (click)="close(true)">accepta tot</button>
    <button class="mobilize-btn btn-clear" (click)="close(false)">renunt</button>
</div>

import { Component, inject, OnInit } from '@angular/core';
import { AlertComponent } from '../../../../components/alert/alert.component';
import { NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonLoaderDirective } from '../../../../directives/button-loader.directive';
import { UserService } from '../../../../services/user.service';
import { LoadingService } from '../../../../services/loading.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'mobilize-user-data',
    standalone: true,
    imports: [
        AlertComponent,
        NgIf,
        ReactiveFormsModule,
        ButtonLoaderDirective,
        MatFormField,
        MatLabel,
        MatInput,

    ],
    templateUrl: './user-data.component.html',
    styleUrl: './user-data.component.scss'
})
export class UserDataComponent implements OnInit {

    private _fb = inject(FormBuilder);
    private _user = inject(UserService);
    private _loading = inject(LoadingService);

    form: FormGroup;
    changing = false;
    dataLoaded = false;

    constructor() {
        this.form = this._fb.group({
            county: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
            streetNumber: ['', Validators.required],
            building: ['', Validators.required],
            apartment: ['', Validators.required],
        })
        Object.keys(this.form.controls).forEach(controlName => {
            this.form.controls[controlName].disable();
        });
    }

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._user.getAddress().subscribe(rsp => {
            this.form.patchValue({
                county: rsp.partner_address[0].county,
                city: rsp.partner_address[0].city,
                street: rsp.partner_address[0].street_name,
                streetNumber: rsp.partner_address[0].street_number,
                building: rsp.partner_address[0].building_number,
                apartment: rsp.partner_address[0].flat_number,
            });
            this._loading.setLoadingState(false);
            this.dataLoaded = true;
        })
    }
}

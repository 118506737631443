<div class="mobilize-card">
    <div class="contact-options-wrapper">
        <div class="options-side">
            @for (option of agreementsOptions; track option) {
                <div class="contact-options-wrapper__row">
                    <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover">
                        <div class="fake-checkbox"
                             [ngClass]="{'checked': option.checked}">
                            <span class="icon-mobilize-check-mark"></span>
                        </div>
                        <label>
                            <input type="checkbox" [(ngModel)]="option.checked" (change)="onCheckboxChange()">
                        </label>
                    </div>
                    <div class="text">
                        {{ option.name }} {{ option.label }}
                    </div>
                </div>
            }
        </div>

        <div class="contact-methods-side">
            @for (option of contactOptions; track option) {
                <div class="contact-options-wrapper__row">
                    <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover"
                         [ngClass]="{'custom-checkbox__disabled': contactMethodsDisabled}">
                        <div class="fake-checkbox"
                             [ngClass]="{'checked': option.checked}">
                            <span class="icon-mobilize-check-mark"></span>
                        </div>
                        <label>
                            <input type="checkbox" [(ngModel)]="option.checked" (change)="onCheckboxChange()">
                        </label>
                    </div>
                    <div class="text">
                        {{ option.name }} {{ option.label }}
                    </div>
                </div>
            }
        </div>
    </div>

    <div class="send-agreements">
        <button class="mobilize-btn btn-orange" [mobilizeButtonLoader]="isProcessing" originalContent="salveaza" (click)="sendAgreements()">salveaza</button>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { Document } from '../../interfaces/document';
import { NgClass } from '@angular/common';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'mobilize-document-box',
    standalone: true,
    imports: [
        NgClass
    ],
    templateUrl: './document-box.component.html',
    styleUrl: './document-box.component.scss'
})
export class DocumentBoxComponent extends BaseComponent {

    @Input() document!: Document;

    documentDownload() {

    }
}

import { Component, ElementRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ResponseModalComponent } from './components/response-modal/response-modal.component';
import { registerMessages } from '../../constants/register-messages';
import { RegisterService } from '../../services/register.service';
import { CookieService } from 'ng2-cookies';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { MatTooltip } from '@angular/material/tooltip';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { v4 as uuidv4 } from 'uuid';
import { finalize } from 'rxjs';

@Component({
    selector: 'mobilize-register',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        FormsModule,
        MatProgressSpinner,
        NgIf,
        MatTooltip,
        ButtonLoaderDirective,
        RecaptchaModule
    ],
    templateUrl: './register.component.html',
    styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {

    @ViewChild('captchaRef', {static: false}) recaptchaComponent!: RecaptchaComponent;  // Correct typing

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler() {
        if (this.registerStatus !== 'initial' && this.registerStatus !== 'final') {
            return confirm('bla bla');
        }

        return true;
    }

    private _fb = inject(FormBuilder);
    private _route = inject(ActivatedRoute);
    private _router = inject(Router);
    private _dialog = inject(MatDialog);
    private _register = inject(RegisterService);
    private _cookies = inject(CookieService);
    private _snackBar = inject(MatSnackBar);

    showOverlay = true;
    termsAgreement = false;
    privacyAgreement = false;
    registerForm;
    otpForm;
    personalDataForm;
    registeredEmail: any = '';
    enrollmentUid: any = '';
    otpError = false;
    showPassword = false;
    agreementsError = false;
    isProcessing = false;
    registerStatus = 'initial';
    registerData: any;
    recaptchaToken: string = '';
    uniqueId = '';

    constructor() {
        this.registerForm = this._fb.group({
            contract_number: ['', Validators.required],
            partial_vin: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            recaptcha: ['']
        });

        this.otpForm = this._fb.group({
            code: ['', Validators.required]
        });

        this.personalDataForm = this._fb.group({
            fullName: ['', Validators.required],
            password: ['', Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()]).{8,}$/)],
            confirmPassword: ['', RxwebValidators.compare({fieldName: 'password'})],
        });
        this.uniqueId = uuidv4();
    }

    ngOnInit() {
        if (this._cookies.get('_dpa')) {
            this.showOverlay = false;
        }
        this._register.registerStep.subscribe(rsp => {
            if (rsp && rsp.step) {
                this.registerStatus = rsp.step;
                this.registerData = rsp.enrollment;
            }
        })
    }

    closeDataProtection() {
        this.showOverlay = false;
        this._cookies.set('_dpa', '1', undefined, '/');
    }

    validateData() {
        if (this.registerForm.invalid) {
            this.registerForm.markAllAsTouched();
            this.isProcessing = false;
            return;
        } else if (!this.privacyAgreement || !this.termsAgreement) {
            this.agreementsError = true;
            this.isProcessing = false;
            return;
        }

        this.recaptchaComponent.execute();

    }

    resolved(captchaResponse: any) {
        if (captchaResponse) {
            this.recaptchaToken = captchaResponse;
            this.registerForm.patchValue({recaptcha: this.recaptchaToken});
            this.step1();
        } else {
            this.isProcessing = false;
            console.error('reCAPTCHA token is missing.');
        }
    }


    step1() {
        this.agreementsError = false;

        if (this.registerForm.valid) {
            this.isProcessing = true;

            this._register.checkInitialData({
                ...this.registerForm.value
            })
                .pipe(
                    finalize(() => {
                        this.isProcessing = false;
                    })
                )
                .subscribe(rsp => {
                    if (rsp.responseCode === 202) {
                        this.registerStatus = 'confirm-email';
                        this.registeredEmail = rsp.email;
                        this.enrollmentUid = rsp.enrollment_uid;
                        this.isProcessing = false;
                    } else {
                        this.isProcessing = false;
                        const modalPayload = registerMessages.find(item => item.code === rsp.responseCode);
                        this._dialog.open(ResponseModalComponent, {
                            width: '1000px',
                            data: {...modalPayload},
                            autoFocus: 'dialog'
                        }).afterClosed().subscribe(() => {
                            this.registerForm.reset();
                            this.privacyAgreement = false;
                            this.termsAgreement = false;
                            this.recaptchaComponent.reset();
                            this._router.navigate([], {
                                relativeTo: this._route
                            });
                        });
                    }
                })
        } else {
            Object.keys(this.registerForm.controls).map((control: string) => {
                const formControl = this.registerForm.controls[control as keyof typeof this.registerForm.controls];
                formControl.markAsTouched({onlySelf: true});
            });
        }

        if (!this.registerForm.valid) {
            Object.keys(this.registerForm.controls).map(control => {
                // @ts-ignore
                this.registerForm.controls[control].markAsTouched({onlySelf: true});
                // @ts-ignore
                if (this.registerForm.controls[control].status.toLowerCase() !== 'valid') {
                }
            });
        }
    }

    step2() {
        this.isProcessing = true;
        this._register.validateOtp({
            otp: <string>this.otpForm.value.code,
            enrollment_uid: this.enrollmentUid
        }).subscribe(rsp => {
            if (rsp.responseCode === 706) {
                this.otpError = true;
            }
            if (rsp.responseCode === 705) {
                const snackBarRef = this._snackBar.open('Ai atins numarul maxim de incercati! Te rugam sa reiei procesul.', 'Ok');
                snackBarRef.onAction().subscribe(() => {
                    this.registerStatus = 'initial';
                    this.otpForm.controls['code'].setValue('');
                    this.otpForm.controls['code'].updateValueAndValidity();
                })

            }
            if (rsp.responseCode === 203) {
                this.otpError = false;
                this.registerStatus = 'password';
            }
            this.isProcessing = false;
        })

    }

    createAccount() {
        if (this.personalDataForm.invalid) {
            return;
        }

        const payload: any = {};

        if (this.registerData && this.registerData.enrollment) {
            const enrollment = this.registerData.enrollment;
            payload.email = enrollment.email;
            payload.enrollment_uid = enrollment.enrollment_uid;
            payload.process = 'register';
        } else {
            payload.email = this.registeredEmail;
            payload.enrollment_uid = this.enrollmentUid;
            payload.process = 'register';
        }

        this.isProcessing = true;
        this._register.createUser({
            ...this.personalDataForm.value,
            ...payload
        }).subscribe(rsp => {
            if (rsp && rsp.responseCode) {
                const modalPayload = registerMessages.find(item => item.code === rsp.responseCode);
                this.registerStatus = 'final';
                this.clearCookies()

                this._dialog.open(ResponseModalComponent, {
                    width: '1000px',
                    data: {...modalPayload},
                    autoFocus: 'dialog',
                    disableClose: true
                }).afterClosed().subscribe(rsp => {
                    if (rsp && rsp === 'login') {
                        this._router.navigateByUrl('/dashboard');
                    }
                });
                this.isProcessing = false;
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    //invitatia 10 zile
    // dezactiveaza -> sterge utilizator
    // paseasa -> transfera

    private clearCookies() {
        this._cookies.delete('_eid', '/');
        this._cookies.delete('_email', '/');
        this._cookies.delete('_op', '/');
    }

    login() {
        this.clearCookies()
        this._router.navigateByUrl('/dashboard');
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import {
    MatDatepickerToggle,
    MatDateRangeInput,
    MatDateRangePicker,
    MatEndDate,
    MatStartDate
} from '@angular/material/datepicker';
import { MatHint, MatLabel, MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractService } from '../../services/contract.service';
import { DatePipe, NgIf } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { SelectAllDirective } from '../../directives/select-all.directive';
import moment from 'moment';
import { MiscService } from '../../services/misc.service';
import { ActivatedRoute, Router } from '@angular/router';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-data-filter',
    standalone: true,
    imports: [
        MatFormField,
        MatDateRangeInput,
        MatLabel,
        MatSuffix,
        MatHint,
        MatIcon,
        MatDatepickerToggle,
        MatDateRangePicker,
        MatStartDate,
        DatePipe,
        MatEndDate,
        FormsModule,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
        ButtonLoaderDirective,
        MatProgressSpinnerModule,
        MatInput,
        SelectAllDirective,
        MatSelectTrigger,
        NgIf
    ],
    templateUrl: './data-filter.component.html',
    animations: [
        trigger('slideDown', [
            state('false', style({height: '0px', overflow: 'hidden', marginTop: '0px'})),
            state('true', style({height: '*', overflow: 'hidden'})),
            transition('false <=> true', animate('300ms ease-in-out'))
        ]),
        trigger('rotateChevron', [
            state('false', style({transform: 'rotate(0deg)'})),
            state('true', style({transform: 'rotate(180deg)'})),
            transition('false <=> true', animate('300ms ease-in-out'))
        ]),
        trigger('showButton', [
            state('false', style({display: 'none'})),
            state('true', style({display: 'block'})),
            transition('false <=> true', animate('300ms ease-in-out'))
        ]),
        trigger('buttonAnimation', [
            state('visible', style({
                opacity: 1,
                height: 40
            })),
            state('hidden', style({
                opacity: 0,
                height: 0
            })),
            transition('hidden => visible', [
                animate('0.1s ease-in')
            ]),
            transition('visible => hidden', [
                animate('0.1s ease-out')
            ])
        ])
    ],
    providers: [
        DatePipe,
        provideMomentDateAdapter(MY_FORMATS),
    ],
    styleUrl: './data-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFilterComponent implements OnInit {

    @Output() onFilterExec = new EventEmitter<any>();
    @Output() onContractsLoaded = new EventEmitter<any>();
    @Output() onExport = new EventEmitter<any>();

    @Input() processing = false;
    @Input() page!: string;
    @Input() canExport = true;
    @Input() filterOptions: string[] = [];

    private _fb = inject(FormBuilder);
    private _contracts = inject(ContractService);
    private _misc = inject(MiscService);
    private _route = inject(ActivatedRoute);
    private _router = inject(Router);

    filterForm!: FormGroup;
    contracts: any[] = [];
    contractsLoading = true;
    currentFilter = 'luna curenta';
    searchPlaceholder: string = '';
    isFilterContentVisible = false;
    states = [
        {
            id: 'Valid',
            name: 'Activ'
        },
        {
            id: 'invalid',
            name: 'Inactiv'
        }
    ];
    maxDate: any;
    minDate: any
    categories: any;

    constructor() {
    }

    getContractNumber(contracts: any) {
        contracts = contracts.filter((item: any) => item !== 'select-all');
        if (contracts.length > 1) {
            return `Contract ${this.contracts[0].contract_number}, Contract ${this.contracts[1].contract_number}`;
        }
        const contract = this.contracts.filter(item => item.contract_id === contracts[0]);

        const text: any[] = [];
        if (contract.length) {
            contract.forEach(item => {
                text.push(`Contract ${contract[0].contract_number}`)
            });
            return text.join(', ');
        }

        return '';
    }

    get otherLabelText() {
        let toSubtract = (this.filterForm.controls['contracts'].value.length === 5) ? 3 : 2;
        return `(+${(this.filterForm.controls['contracts'].value.length || 0) - toSubtract} ${(this.filterForm.controls['contracts'].value.length === 3 ? 'altul' : 'altele')})`
    }

    toggleFilterContent() {
        if (this.filterOptions.includes('contracts')) {
            if (!this.contracts.length) {
                return;
            }
        }
        this.isFilterContentVisible = !this.isFilterContentVisible;
    }

    ngOnInit() {
        if (this.page === 'future') {
            this.maxDate = new Date(2099, 11, 30);
            this.minDate = new Date();
        } else {
            this.maxDate = new Date();
            this.maxDate.setDate(this.maxDate.getDate() - 1);
        }
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        this.filterForm = this._fb.group({
            startDate: [this.page === 'current' || this.page === 'invoice' || this.page === 'requests' ? startOfMonth : currentDate],
            endDate: [this.page === 'current' ? yesterday : endOfMonth],
            contracts: [['all']],
            categories: [['all']],
            state: ['Valid'],
            query: ['']
        });

        switch (this.page) {
            case 'contracts':
            case 'insurance':
                this.searchPlaceholder = 'Cauta dupa numar contract sau numar de inmatriculare';
                break;
        }

        if (this.page === 'insurance' || this.page === 'contracts') {
            this.currentFilter = 'activ';
        }

        if (this.page === 'requests') {
            this.currentFilter = 'luna curenta';
        }

        this.contractsLoading = true;
        if (this.filterOptions.includes('contracts')) {
            this._contracts.getContracts({state: 'Valid'}).subscribe(rsp => {
                this.contracts = rsp.data;
                this.contractsLoading = false;
                this.onContractsLoaded.next(this.contracts.map(contract => contract.contract_id));
            });
        } else {
            this.onContractsLoaded.next({});
        }

        if (this.filterOptions.includes('categories')) {
            this._misc.getCategories().subscribe(rsp => {
                this.categories = rsp.categories;
            });
        }

        this.filterForm.get('startDate')?.valueChanges.subscribe((rsp: any) => {
            this.checkDateRange();
        });

        this.filterForm.get('endDate')?.valueChanges.subscribe((rsp: any) => {
            this.checkDateRange();
        });
    }

    export() {
        this.onExport.next(true)
    }

    exportClientFile() {

    }

    statusChange() {
        if (this.filterForm.value.state === 'Valid') {
            this.currentFilter = 'activ';
        } else {
            this.currentFilter = 'inactiv';
        }
    }

    execFilter() {
        const payload = this.filterForm.value;
        if (payload.contracts[0] === 'select-all' || payload.contracts.length === 0) {
            payload.contracts = this.contracts.map(contract => contract.contract_id);
        }
        this.processing = true;
        this.onFilterExec.next(payload);
    }


    checkDateRange(): void {
        const startDate: string = this.filterForm.get('startDate')?.value;
        const endDate: string = this.filterForm.get('endDate')?.value;

        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

        if (new Date(startDate) + '' === startOfMonth + '' && new Date(endDate) + '' === endOfMonth + '') {
            this.currentFilter = 'luna curenta'
        } else {
            this.currentFilter = moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY')
        }
    }

    reset(what: string) {
        switch (what) {
            case 'query':
                this.filterForm.controls['query'].reset();
        }

    }
}

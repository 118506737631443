import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'mobilize-contract-actions-history',
    standalone: true,
    imports: [
        PageHeaderComponent
    ],
    templateUrl: './contract-actions-history.component.html',
    styleUrl: './contract-actions-history.component.scss'
})
export class ContractActionsHistoryComponent implements OnInit {

    private _data = inject(DataService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);

    contractName!: string;
    contractId!: string;
    contractHistory: { date: string, name: string, action?: string }[] = [
        {
            date: '03 mar',
            name: 'Ai solicitat schimbarea datei de plata'
        },
        {
            date: '03 mar',
            name: 'Ai initiat o plata anticipata partiala',
            action: 'plateste'
        },
        {
            date: '03 mar',
            name: 'S-a generat polita CASCO CR32423423',
            action: 'descarca polita'
        },
    ]


    constructor() {
        this._route.params.subscribe((rsp: any) => {
            this.contractId = rsp.id;
        })
    }

    ngOnInit() {
        const signal = this._data.getData();
        if (signal) {
            this.contractName = signal?.contractName;
        } else {
            this._router.navigateByUrl(`/contract-details/${this.contractId}`)
        }
    }

    goBack() {
        this._router.navigateByUrl(`contract-details/${this.contractId}`)
    }
}

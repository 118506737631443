import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class CustomKeycloakService extends KeycloakService {
    private loginInProgress = false;

    isLoginInProgress(): boolean {
        return this.loginInProgress;
    }

    setLoginInProgress(): void {
        this.loginInProgress = true;
    }

    unsetLoginInProgress(): void {
        this.loginInProgress = false;
    }
}

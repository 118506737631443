import { Component, inject, OnInit } from '@angular/core';
import { RequestBoxComponent } from '../components/request-box/request-box.component';
import { RequestService } from '../../../services/request.service';
import { LoadingService } from '../../../services/loading.service';
import { SocketService } from '../../../services/socket.service';
import { DataFilterComponent } from '../../../components/data-filter/data-filter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

@Component({
    selector: 'mobilize-inbox',
    standalone: true,
	imports: [
		RequestBoxComponent,
		DataFilterComponent
	],
    templateUrl: './inbox.component.html',
    styleUrl: './inbox.component.scss'
})
export class InboxComponent implements OnInit {

    private _request = inject(RequestService);
    private _loading = inject(LoadingService);
    private _socket = inject(SocketService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);

    requests!: any[];
    filterProcessing = false;
    dataReady = false;
    filterArgs: any;
    filterOptions = ['range', 'categories', 'contracts'];
    contractsArrayIds: any;

    currentPage = 1;
    limit = 10;
    currentDate: any;
    startOfMonth: any;
    endOfMonth: any;

    ngOnInit() {
        this.currentDate = new Date();
        this.startOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.endOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);

        // this._socket.on('request-read').subscribe((rsp: any) => {
        //     this.requests.forEach((request: any) => {
        //         if (request.client_request_uid === rsp.request_uid) {
        //             request.fe_status = rsp.fe_status;
        //         }
        //     });
        // });
    }

    getRequests(payload?: any) {
        this._loading.setLoadingState(true);
        this.filterProcessing = true;

        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? + params['page'] : 1;
            this.limit = params['limit'] ? + params['limit'] : this.limit;

            this._request.getRequests({
                ...payload,
                page: this.currentPage,
                limit: this.limit,
                startDate: payload?.startDate || this.startOfMonth,
                endDate: payload?.endDate || this.endOfMonth
            })
                .pipe(
                    finalize(() => {
                        this._loading.setLoadingState(false);
                        this.filterProcessing = false;
                    })
                )
                .subscribe(rsp => {
                this.requests = rsp;
            });
        });
    }

    contractIds(e: any) {
        this.getRequests({contracts: e});
        this.contractsArrayIds = e;
    }

    execFilter(e: any) {
        this.dataReady = false;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.getRequests(e);
        });
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { RequestBoxComponent } from '../components/request-box/request-box.component';
import { RequestService } from '../../../services/request.service';
import { LoadingService } from '../../../services/loading.service';
import { SocketService } from '../../../services/socket.service';

@Component({
    selector: 'mobilize-inbox',
    standalone: true,
    imports: [
        RequestBoxComponent
    ],
    templateUrl: './inbox.component.html',
    styleUrl: './inbox.component.scss'
})
export class InboxComponent implements OnInit {

    private _request = inject(RequestService);
    private _loading = inject(LoadingService);
    private _socket = inject(SocketService);

    requests!: any[];

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._request.getRequests({}).subscribe(rsp => {
            this.requests = rsp;
            this._loading.setLoadingState(false);
        });

        this._socket.on('request-read').subscribe((rsp: any) => {
            this.requests.forEach((request: any) => {
                if (request.client_request_uid === rsp.request_uid) {
                    request.fe_status = rsp.fe_status;
                }
            });
        });
    }
}

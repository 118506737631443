import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private _http: HttpClient) {
    }

    getAssociatedUsers(payload: any): Observable<any> {
        return this._http.post('register/associated-users', payload);
    }

    resendInvite(payload: { activation_url: string; email: string; }): Observable<any> {
        return this._http.post('register/resend-invite', payload);
    }

    passAdminRights(payload: any): Observable<any> {
        return this._http.post('register/pass-admin-rights', payload);
    }

    deactivate(payload: {email: string}): Observable<any> {
        return this._http.post('register/deactivate', payload);
    }

    cancelInvite(payload: {enrollment_uid: string}): Observable<any> {
        return this._http.post('register/cancel-invite', payload);
    }
}

<div class="set-password-wrapper">
    <div class="set-admin-password">
        <div class="mobilize-card no-padding-card change-password-page">
            <form [formGroup]="form" class="custom-form">
                <div class="form-group">
                    <div class="form-control single-control">
                        <mat-form-field>
                            <mat-label>Parola noua:</mat-label>
                            <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="newPassword"
                                   placeholder="scrie parola noua" autocomplete="new-newPassword">
                        </mat-form-field>
                        <span class="input-icon icon-mobilize-info"
                              matTooltip="Minimum 8 caractere, o litera mica, o litera mare, un caracter special: !@#$%^&*()"></span>
                        @if (!showPassword) {
                            <span class="input-icon icon-mobilize-eye-off"
                                  (click)="showPassword = !showPassword"></span>
                        } @else {
                            <span class="input-icon icon-mobilize-eye"
                                  (click)="showPassword = !showPassword"></span>
                        }
                        <div class="form-error" *ngIf="form.controls['newPassword'].errors?.['pattern']">
                            Min. 8 caractere<br>
                            Min. o litera mica<br>
                            Min. o litera mare<br>
                            Min. un caracter special: !&#64;#$%^&*()
                        </div>
                        <div class="form-error"
                             *ngIf="form.controls['newPassword'].touched && form.controls['newPassword'].invalid">
                            Parola noua este obligatorie
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control single-control">
                        <mat-form-field>
                            <mat-label>Confirma parola noua:</mat-label>
                            <input matInput [type]="showConfirmPassword ? 'text' : 'password'"
                                   formControlName="newPasswordConfirmation"
                                   placeholder="reintrodu parola noua" autocomplete="new-newPasswordConfirmation">
                        </mat-form-field>
                        @if (!showConfirmPassword) {
                            <span class="input-icon icon-mobilize-eye-off"
                                  (click)="showConfirmPassword = !showConfirmPassword"></span>
                        } @else {
                            <span class="input-icon icon-mobilize-eye"
                                  (click)="showConfirmPassword = !showConfirmPassword"></span>
                        }
                        <div class="form-error"
                             *ngIf="form.controls['newPasswordConfirmation'].errors?.['compare']">
                            Parolele nu se potrivesc
                        </div>
                    </div>
                </div>

                <div class="form-actions">
                    <button class="mobilize-btn btn-orange" type="submit" (click)="setPassword()"
                            [mobilizeButtonLoader]="processing" originalContent="Schimba parola">Schimba parola
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

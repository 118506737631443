<button class="mobilize-btn btn-orange" routerLink="/my-requests">inapoi la inbox</button>

<div class="details-wrapper">
    @if (request) {
        <div class="title">{{ request.request_subject }}</div>

        <div class="description">
            <div class="description-row">
                contract: <span>{{ request.contract_number }}</span>
            </div>
            <div class="description-row">
                data solicitarii: <span>{{ request.created_at | date: 'dd/MM/YYYY' }}</span>
            </div>
            <div class="description-row">
                nr. solicitare: <span>{{ request.request_number | padZero: 7 }}</span>
            </div>
        </div>
    }

    <div class="messages-wrapper">
        @for (message of messages; track message; let idx = $index; let first = $first) {
            <div class="message-box">
                <div class="title">
                    <span>({{ messages.length - idx }})</span>
                    {{ message.author }}
                </div>

                <div class="content">
                    <div class="receiver">catre: <span>{{ message.receiver }}</span></div>
                    <div class="created-at">{{ message.created_at | date: 'dd/MM/YYYY HH:mm' }}</div>
                    <div class="title" [ngClass]="{'fixed': message.fe_status === 'Finalizata' && message.isReply}">
                        @if (message.fe_status === 'Finalizata' && message.isReply ) {
                            <span class="icon-mobilize-check-mark"></span>
                            Solutionare
                        } @else {
                            <span class="subject">{{ message.subject }}</span>
                        }
                    </div>

                    <div class="text" [innerHTML]="message.content"></div>

                    @if (message.document) {
                        <div class="download-document">
                            <span class="icon-mobilize-export"></span> {{ message.document }}
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</div>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    private _http = inject(HttpClient);

    getDocuments(payload: any): Observable<any> {
        return this._http.post('documents/list', payload);
    }
}

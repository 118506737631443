<div mat-dialog-title class="users-list">
    <span>Escaleaza catre</span>
    <span class="icon-mobilize-close" (click)="closeModal()"></span>
</div>

<mat-dialog-content>
    @if (usersLoading) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }

    <mat-form-field class="admin-list-wrapper">
        <mat-label>Alege un utilizator</mat-label>
        <mat-select class="admin-list" [(ngModel)]="selectedUser" [disabled]="usersLoading">
            @for (user of users; track user) {
                <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mobilize-btn btn-orange" (click)="escalate()">Trimite</button>
    <button class="mobilize-btn btn-clear" (click)="closeModal()">Cancel</button>
</mat-dialog-actions>

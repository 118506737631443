import {
    Component,
    ComponentRef,
    ElementRef,
    inject,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoRequestComponent } from '../../components/bo-request/bo-request.component';
import { SocketService } from '../../../services/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatLabel, MatOption, MatSelect } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, DatePipe, NgForOf } from '@angular/common';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RequestsFilterComponent } from '../../components/requests-filter/requests-filter.component';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { DataFilterComponent } from '../../../components/data-filter/data-filter.component';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-client-requests',
    standalone: true,
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    providers: [
        provideMomentDateAdapter(MY_FORMATS),
        DatePipe
    ],
    imports: [
        PageHeaderComponent,
        FormsModule,
        BoRequestComponent,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatDatepickerInput,
        MatInput,
        MatDatepickerToggle,
        MatDatepicker,
        MatIconModule,
        MatSuffix,
        MatAutocompleteTrigger,
        MatAutocomplete,
        AsyncPipe,
        NgForOf,
        ButtonLoaderDirective,
        RequestsFilterComponent,
        RequestsSearchComponent,
        PaginationComponent,
        DataFilterComponent
    ],
    templateUrl: './client-requests.component.html',
    styleUrl: './client-requests.component.scss'
})
export class ClientRequestsComponent implements OnInit {

    private _requests = inject(RequestService);
    private _loader = inject(LoadingService)
    private _socket = inject(SocketService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private filterRef!: ComponentRef<RequestsFilterComponent>;

    query = '';
    requests: any[] = [];
    showFilter = false;
    isProcessing = false;
    filtersLoaded = false;
    filterActive = false;
    escalated = false;
    pageTitle = 'Cereri clienti';
    currentPage = 1;
    limit = 3;
    dataReady = false;
    totalPages!: number;
    pagesArray: any = [];
    currentFilters!: any;

    constructor(private viewContainerRef: ViewContainerRef, private elementRef: ElementRef) {
        this._route.data.subscribe((rsp: any) => {
            this.escalated = rsp.escalated;
        });
    }

    ngOnInit() {
        console.log('asd')
        this._loader.setLoadingState(true);
        if (this.escalated) {
            this.pageTitle += ' escaladate';
            this._requests.getEscalatedRequests().subscribe(rsp => {
                this.requests = rsp.requests;
                this._loader.setLoadingState(false);
            });
        } else {
            this._route.queryParams.subscribe(params => {
                this.currentPage = params['page'] ? +params['page'] : 1;
                this.limit = params['limit'] ? +params['limit'] : this.limit;
                this.query = params['query']

                this.loadRequests(this.currentPage, this.query);
            });

            this._socket.on('request-read').subscribe((rsp: any) => {
                this.requests.forEach(request => {
                    if (request.client_request_uid === rsp.request_uid) {
                        request.bo_status = rsp.bo_status;
                    }
                })
            });

            this._socket.on('reply-read').subscribe((rsp: any) => {
                this.requests.forEach(request => {
                    if (request.client_request_uid === rsp.request_uid) {
                        request.bo_status = rsp.bo_status;
                    }
                });
            });
        }
    }

    loadRequests(page: number, searchQuery?: string) {
        this._loader.setLoadingState(true);
        if (this.filterActive) {
            this._requests.applyFilters({...this.currentFilters, page: this.currentPage, limit: this.limit}).subscribe(rsp => {
                this.query = '';
                this.requests = rsp.data;
                this.currentPage = parseInt(rsp.currentPage, 10);
                this.totalPages = rsp.totalPages;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
                this.isProcessing = false;
                this.filterActive = !this.currentFilters.reset;
                this._loader.setLoadingState(false);
            });
            return;
        }
        this.dataReady = false;
        this._requests.getRequests({page, limit: this.limit, searchQuery: this.query}).subscribe(rsp => {
            this.requests = rsp.data;
            this.currentPage = parseInt(rsp.currentPage, 10);
            this.totalPages = rsp.totalPages;
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            this.dataReady = true;
            this._loader.setLoadingState(false);
        });
    }

    search(event: any) {
        this.onPageChange({page: 1, limit: this.limit, query: event})
    }

    onPageChange(event: any) {
        if ((event.page < 1 || event.page > this.totalPages) && this.query === event.query) {
            return;
        }
        const queryParams: any = {page: event.page, limit: event.limit || this.limit}
        if (event.query && event.query !== '') {
            queryParams.query = event.query;
        }

        if (event.query === '') {
            this.query = '';
        }

        if (this.query && this.query !== '') {
            queryParams.query = this.query
        }

        this._router.navigate([], {
            relativeTo: this._route,
            queryParams,
        });
    }

    markAsRead(request: any) {
        this._router.navigateByUrl(`/backoffice/client-request/${request.client_request_uid}`)
    }

    toggleFilter() {
        if (!this.filtersLoaded) return;

        this.showFilter = !this.showFilter;
    }

    applyFilters(event: any) {
        this.currentFilters = event;
        if (!this.filterActive) {
            this.currentPage = 1;
            this.filterActive = true;
        }
        this.isProcessing = true;
        this._router.navigate([], {
            relativeTo: this._route
        }).then(() => {
            // Load requests with the new filters
            this.loadRequests(this.currentPage, this.query);
        });
    }

    canShowFilters(event: any) {
        this.filtersLoaded = event;
    }
}

import { Contract } from '../interfaces/contract';

export const CONTRACTS: any[] = [
        {
            id: 'g3673456f-64556g54f4-6f456f456g45',
            contract_id: 3,
            title: 'Contract credit n* CR RON220023',
            contract_number: '123123123',
            nextInstallment: 1459,
            next_installment: 1459,
            previous_instalments: 3,
            contract_schedules: [],
            contract_services_list: [],
            outstandingBalance: 34445,
            documents: [
                {
                    name: 'Contract credit nr. CR RON12312312',
                    date: '20/07/2021'
                },
                {
                    name: 'Act aditional 1 la Contract credit nr. CR RON220934',
                    date: '20/07/2021'
                },
                {
                    name: 'Polita CASCO',
                    date: '20/07/2021'
                },
                {
                    name: 'Polita RCA',
                    date: '20/07/2021'
                },
            ],
            payedInstallments: 45,
            maxInstallments: 80,
            options: [
                {
                    name: 'Casco',
                    date: '31/12/2024',
                    active: true,
                    details: {
                        insurerName: 'Generali',
                        issueDate: '25/05/2022',
                        validity: '25/05/2022 - 22/05/2025',
                        paymentFrequency: 'LUNAR',
                        nextInstallmentAmount: 180,
                        nextInstallmentDate: '25/06/2024',
                        premium: 8930,
                        insuredAsset: 'Renault Megane 1.6',
                        chassisNumber: 'SJNFBAF15U8065008',
                        policyNumber: 'CR234643'
                    }
                },
                {
                    name: 'RCA',
                    date: '31/12/2024',
                    active: true
                },
                {
                    name: 'Intretinere',
                    date: '31/12/2024',
                    active: true
                },
                {
                    name: 'Asistenta rutiera',
                    date: '',
                    active: false
                },
            ],
            nextInstallmentDate: '25/6/2025',
            contractState: 'opened',
            financedGood: {
                name: 'Renault Megane 1.6',
                licensePlate: 'CT-02-NPN',
                color: 'alb white',
                fuelType: 'diesel',
                downPayment: 12,
                chassisNumber: 'SJNFBAF15U8065008'
            },
            contractType: 'Contract credit',
            active: true,
            daysOfDelay: 0,
            contractDate: '12/10/2020',
            payedAmount: 4500,
            amountDue: 85500,
            fullPrice: 58934.3,
            annualInterestRate: 6.9,
            downPayment: 12,
            visible: true,
            contract_state: 'sasdas',
            delay_days: 4,
            contract_date: 'adadasd',
            valoare_finantata: 123,
            outstanding: 23423,
            contract_objects: {},
            interest_percent: 123,
            purchasing_price_with_vat: 123,
            advance_percent: 50,
            advance_amount: 123,
            advance_amount_with_vat: 123,
            currency_code: 'EUR',
            unpaid_invoices_ron: 1232,
            rezidual_amount: 12323,
            penalty_percent: 3,
            contract_type: 'asdasd'
        },
        {
            id: 'g3673456f-64556g54f4-6f446f456g45',
            contract_state: 'sasdas',
            delay_days: 4,
            contract_date: 'adadasd',
            valoare_finantata: 123,
            outstanding: 23423,
            contract_objects: {},
            contract_services_list: [],
            interest_percent: 123,
            purchasing_price_with_vat: 123,
            advance_percent: 50,
            advance_amount: 123,
            currency_code: 'EUR',
            unpaid_invoices_ron: 1232,
            contract_id: 3,
            title: 'Contract credit n* CR RON998877',
            contract_number: '123123',
            nextInstallment: 1459,
            previous_instalments: 2,
            next_installment: 1459,
            contract_schedules: [],
            advance_amount_with_vat: 123,
            outstandingBalance: 34445,
            documents: [
                {
                    name: 'Contract credit nr. CR 546546',
                    date: '20/07/2021'
                },
                {
                    name: 'Act aditional 1 la Contract credit nr. CR RON220934',
                    date: '20/07/2021'
                },
                {
                    name: 'Polita CASCO',
                    date: '20/07/2021'
                },
                {
                    name: 'Polita RCA',
                    date: '20/07/2021'
                },
            ],
            payedInstallments: 45,
            maxInstallments: 80,
            options: [
                {
                    name: 'Casco',
                    date: '31/12/2024',
                    active: true,
                    details: {
                        insurerName: 'Generali',
                        issueDate: '25/05/2022',
                        validity: '25/05/2022 - 22/05/2025',
                        paymentFrequency: 'LUNAR',
                        nextInstallmentAmount: 180,
                        nextInstallmentDate: '25/06/2024',
                        premium: 8930,
                        insuredAsset: 'Renault Megane 1.6',
                        chassisNumber: 'SJNFBAF15U8065008',
                        policyNumber: 'CR234643'
                    }
                },
                {
                    name: 'RCA',
                    date: '31/12/2024',
                    active: true
                },
                {
                    name: 'Intretinere',
                    date: '31/12/2024',
                    active: true
                },
                {
                    name: 'Asistenta rutiera',
                    date: '',
                    active: false
                },
            ],
            nextInstallmentDate: '25/6/2025',
            contractState: 'opened',
            financedGood: {
                name: 'Renault Megane 1.6',
                licensePlate: 'CT-02-NPN',
                color: 'alb white',
                fuelType: 'diesel',
                downPayment: 12,
                chassisNumber: 'SJNFBAF15U8065008'
            },
            contractType: 'Contract credit',
            active: true,
            daysOfDelay: 0,
            contractDate: '12/10/2020',
            payedAmount: 4500,
            amountDue: 85500,
            fullPrice: 58934.3,
            annualInterestRate: 6.9,
            downPayment: 12,
            rezidual_amount: 12323,
            penalty_percent: 3,
            visible: true,
            contract_type: 'asdasd'
        }
    ];

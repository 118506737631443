import { Menu } from '../interfaces/menu';

const iconPrefix = 'icon-mobilize-';
const routePrefix = '/account-settings/'
export const USER_MENU: Menu[] = [
    {
        title: 'Schimba parola',
        icon: iconPrefix + 'lock',
        route: routePrefix + 'change-password',
        canView: ['Admin', 'User']
    },
    {
        title: 'Schimba email (username)',
        icon: iconPrefix + 'mention',
        route: routePrefix + 'change-email',
        canView: ['Admin', 'User']
    },
    {
        title: 'Date utlizator',
        icon: iconPrefix + 'touch-id',
        route: routePrefix + 'user-data',
        canView: ['Admin']
    },
    {
        title: 'Utilizatori asociati',
        icon: iconPrefix + 'dashboard',
        route: routePrefix + 'associated-users',
        canView: 'Admin'
    },
    {
        title: 'Preferinte de comunicare',
        icon: iconPrefix + 'envelope-open',
        route: routePrefix + 'contact-preferences',
        canView: 'Admin'
    }
]

import { Component, computed, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { UserService } from './services/user.service';
import { filter } from 'rxjs';
import { NgClass } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoadingService } from './services/loading.service';
import { SocketService } from './services/socket.service';

@Component({
    selector: 'mobilize-root',
    standalone: true,
    imports: [RouterOutlet, MainMenuComponent, NgClass, MatProgressBar, RouterLink],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

    private _user = inject(UserService);
    private _kc = inject(KeycloakService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private _loading = inject(LoadingService);
    private _socket = inject(SocketService);

    superAccount = true;
    registerPage = false;
    dataLoading = computed(() => this._loading.getLoadingState());
    title = 'mobilize-fs';
    userLogged = false;
    backOffice = false;
    showMenu = false;

    ngOnInit() {
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                const route = this.getChild(this._route);

                route.data.subscribe(data => {
                    this.registerPage = data['registerPage'];
                    this.backOffice = data['backoffice'];
                    this.showMenu = true;
                });
            });

        this._kc.getToken().then(token => {
            if (token) {
                this.userLogged = true;
                this._user.superStatus$.subscribe(rsp => {
                    this.superAccount = rsp;
                });
            }
        });

        this._socket.initConnection();

        this._kc.keycloakEvents$.subscribe({
            next: (event) => {
                switch (event.type) {
                    case KeycloakEventType.OnAuthSuccess:
                        break;

                    case KeycloakEventType.OnTokenExpired:
                        this._kc.updateToken(2);
                        break;
                }
            }
        });
    }

    getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }
}

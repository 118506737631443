import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { BaseComponent } from '../../components/base.component';
import { InvoiceService } from '../../services/invoice.service';
import { DatePipe, DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Invoice } from '../../interfaces/invoice';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressBar } from '@angular/material/progress-bar';
import { UserService } from '../../services/user.service';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { finalize, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentsService } from '../../services/documents.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataFilterComponent } from '../../components/data-filter/data-filter.component';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-bills',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        DatePipe,
        NgForOf,
        NgClass,
        MatProgressBar,
        PaginationComponent,
        MatProgressSpinner,
        DataFilterComponent
    ],
    providers: [DecimalPipe, DatePipe,
        provideMomentDateAdapter(MY_FORMATS),],
    templateUrl: './invoices.component.html',
    styleUrl: './invoices.component.scss'
})
export class InvoicesComponent extends BaseComponent implements OnInit {

    private _invoice = inject(InvoiceService);
    private decimalPipe = inject(DecimalPipe);
    private _documents = inject(DocumentsService);
    private _route = inject(ActivatedRoute);
    private _router = inject(Router);
    private _snackbar = inject(MatSnackBar);
    private _user = inject(UserService);

    processedData: Invoice[] = [];
    dataReady = false;
    currentPage = 1;
    totalPages!: number;
    documentLoading = false;
    limit = 10;
    pagesArray: any;
    totalAmount!: number;
    totalAmountReady = false;
    filterProcessing = false;
    ngOnInit() {
        this._route.
        queryParams.subscribe(params => {
            this.currentPage = params['page'] ? + params['page'] : 1;
            this.limit = params['limit'] ? + params['limit'] : this.limit;

            this._user.userData().subscribe(rsp => {
                this.loadInvoices(this.currentPage, rsp.partner);
            });
        });
    }

    downloadInvoice(invoice: any) {
        this.documentLoading = true;
        this._documents.getDocuments({
            contract: invoice.contract.contract_number,
            invoiceNumber: invoice.prefix + invoice.invoice_number
        })
            .pipe(
                finalize(() => {
                    this.documentLoading = false;
                }),
                catchError(((err: any) => {
                    this._snackbar.open('Documentul nu a fost gasit', 'Ok', {
                        panelClass: 'error-snack'
                    })
                    return of(false);
                }))
            )
            .subscribe(response => {
                const base64String = response.fileData;
                if (base64String) {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'application/pdf'});

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = response.fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            })
    }

    loadInvoices(page: number, partner: any) {
        this.dataReady = false;

        this._invoice.getInvoices({partner, page, limit: this.limit}).subscribe((rsp: {
            data: Invoice[];
            currentPage: any;
            totalPages: number,
            totalAmount: any;
        }) => {
            this.processData(rsp.data);
            this.currentPage = parseInt(rsp.currentPage, 10);
            this.totalPages = rsp.totalPages;
            this.totalAmount = rsp.totalAmount;
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            this.totalAmountReady = true;
        });
    }

    shouldShowPaymentUrl(invoice: any): boolean {
        const status = this.invoiceStatusText(invoice);
        return status === 'restant' || status === 'neachitat, neincasat';
    }

    onPageSelect(event: any) {
        if (event.page < 1 || event.page > this.totalPages) {
            return;
        }
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { page: event.page, limit: event.limit || this.limit }
        });
    }

    formatNumber(value: number): string {
        return <string>this.decimalPipe.transform(value, '1.2-2'); // Format the number
    }

    processData(rsp: any[]) {
        const dataGroupedByYear = new Map<number, any[]>();

        rsp.forEach((row: any) => {
            const year = new Date(row.invoice_date).getFullYear();
            if (!dataGroupedByYear.has(year)) {
                dataGroupedByYear.set(year, []);
            }
            dataGroupedByYear.get(year)?.push(row);
        });

        // Flatten the grouped data with a year "header"
        this.processedData = [];
        dataGroupedByYear.forEach((rows, year) => {
            this.processedData.push({year} as unknown as any);
            this.processedData.push(...rows);
        });

        this.dataReady = true;
    }

    invoiceStatus(invoice: Invoice): string {
        if (new Date(invoice.data_scadenta) < new Date() && parseFloat(invoice.rest_amount) > 0) {
            return '<span class="invoice-status status-outstanding">restant</span>'
        }

        if (parseFloat(invoice.rest_amount) === 0) {
            return '<span class="invoice-status status-cashed">incasat</span>'
        } else {
            return '<span class="invoice-status status-not-cashed">neachitat</span>'
        }
    }

    invoiceStatusText(invoice: Invoice) {
        if (new Date(invoice.data_scadenta) < new Date() && parseFloat(invoice.rest_amount) > 0) {
            return 'restant'
        }

        if (parseFloat(invoice.rest_amount) === 0) {
            return 'incasat'
        } else {
            return 'neachitat, neincasat'
        }
    }

    toggleRow(row: any) {
        row.opened = !row.opened;
    }

    execFilter($event: any) {

    }

    export() {

    }

    contractIds($event: any) {

    }
}

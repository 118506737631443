@if (dataLoaded) {
    <div class="mobilize-card">
        <form [formGroup]="form" class="">

            <div class="form-group">
                <div class="form-control">
                    <label>Judet/Sector*:</label>
                    <input type="text" formControlName="county" placeholder="">
                </div>
                <div class="form-control">
                    <label>Oras*:</label>
                    <input type="text" formControlName="city" placeholder="">
                </div>
            </div>

            <div class="form-group">
                <div class="form-control">
                    <label>Strada*:</label>
                    <input type="text" formControlName="street" placeholder="">
                </div>
                <div class="form-control">
                    <label>Nr.*:</label>
                    <input type="text" formControlName="streetNumber" placeholder="">
                </div>
            </div>

            <div class="form-group">
                <div class="form-control">
                    <label>Bloc:</label>
                    <input type="text" formControlName="building" placeholder="">
                </div>
                <div class="form-control">
                    <label>Ap:</label>
                    <input type="text" formControlName="apartment" placeholder="">
                </div>
            </div>
        </form>
    </div>
}

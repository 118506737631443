<mobilize-page-header title="Documente utile" [reverse]="true"></mobilize-page-header>

<div class="tabs-wrapper">
    <div class="tabs-header">
        <div class="tabs-header__tab selected"><span>Documente generate</span></div>
        <div class="tabs-header__tab" routerLink="/generate-document"><span>Genereaza document</span></div>
    </div>
</div>

<div class="documents-wrapper">
    @for (doc of documents; track doc) {
        <mobilize-document-box [document]="doc"></mobilize-document-box>
    }
</div>

import {
    Component,
    inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import { DocumentBoxComponent } from '../../components/document-box/document-box.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DatePipe, NgClass } from '@angular/common';
import { BaseComponent } from '../../components/base.component';
import {
    DocumentDetailsDateControls,
    DocumentDetailsPersonControls
} from '../../constants/document-types';
import { Contract } from '../../interfaces/contract';
import { JsonFormComponent } from '../../components/json-form/json-form.component';
import { ContractService } from '../../services/contract.service';
import { LoadingService } from '../../services/loading.service';
import { GenerateDocumentService } from '../../services/generate-document.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'mobilize-generate-document',
    standalone: true,
    providers: [
        DatePipe
    ],
    imports: [
        DocumentBoxComponent,
        PageHeaderComponent,
        RouterLink,
        NgClass,
        JsonFormComponent,
        ButtonLoaderDirective
    ],
    templateUrl: './generate-document.component.html',
    styleUrl: './generate-document.component.scss'
})
export class GenerateDocumentComponent extends BaseComponent implements OnInit, OnDestroy {

    private _contract = inject(ContractService);
    private _loading = inject(LoadingService);
    private _generate = inject(GenerateDocumentService);
    private _datePipe = inject(DatePipe);
    private _router = inject(Router);
    private _user = inject(UserService);
    private _snackbar = inject(MatSnackBar);

    contracts: any = [];
    data: any;

    constructor() {
        super();

        const navigation: any = this._router.getCurrentNavigation();
        if (navigation?.extras.state) {
            this.data = navigation?.extras.state['data'];
        }
    }

    documents!: any;
    showForm = false;
    currentStep = 1;
    formData!: any;
    selectedContract: any;
    selectedDocument: any;
    processing = false;
    backOffice = false;
    impersonatedAccount = false;

    private subscription = new Subscription();

    ngOnInit(): void {
        this._loading.setLoadingState(true);
        this._contract.getContracts({flag: 'gdp'}).subscribe(rsp => {
            this.contracts = rsp.data;
            this.contracts.forEach((item: any) => {
                item.visible = true
            });
            if (this.contracts.length === 1) {
                this.selectContract(this.contracts[0]);
            }

            if (this.data?.contract) {
                const findContract = this.contracts.find((item: any) => {
                    return item.contract_id === this.data.contract.contract_id
                });
                this.selectContract(findContract);
            }
            this._loading.setLoadingState(false);
        });

        this.subscription = this._user.impersonatedStatus$.subscribe(rsp => {
            if (rsp && rsp.active) {
                this.backOffice = false;
                this.impersonatedAccount = rsp.active;
            }
        });
    }

    selectDocument(document: any) {
        if (this.impersonatedAccount) {
            return;
        }
        console.log(this.selectedContract)
        if (document.document_type_name === 'Imputernicire iesire din tara' &&
            this.selectedContract.overdue_invoices_ron > 0 &&
            this.selectedContract.delay_days > 30) {
            this._snackbar.open('Inregistrati intarzieri de plata de peste 30 de zile! Nu se poate genera documentul.', 'Ok', {
                duration: 5000
            });
            return;
        }
        this.documents.forEach((item: any) => {
            if (item.document_type_name !== document.document_type_name) {
                item.visible = false;
            } else {
                this.selectedDocument = item;
            }
        });
        this.currentStep = 3;

        switch (document.controls) {
            case 'type1':
                document.controls = DocumentDetailsPersonControls
                break;

            case 'type2':
                document.controls = [...DocumentDetailsPersonControls, ...DocumentDetailsDateControls]
                break;

            case 'download':
                document.controls = 'download';
                break;

            case 'mobilize':
                document.controls = 'mobilize-download';
                break;
        }

        if (typeof document.controls === 'object' && document.controls.length > 0) {
            this.generateForm(document);
        }
    }

    generateForm(doc: any) {
        this.formData = this.documents.filter((item: any) => item.document_type_name === doc.document_type_name)[0];
    }

    selectContract(contract: Contract) {
        this.contracts.forEach((item: any) => {
            if (item.contract_number !== contract.contract_number) {
                item.visible = false;
            } else {
                this.selectedContract = item;

                this._loading.setLoadingState(true);
                this._generate.getDocumentsTypes({contract: this.selectedContract.contract_number}).subscribe(rsp => {
                    this.documents = rsp;
                    this.documents.forEach((item: any) => item.visible = true);
                    this.currentStep = 2;
                    this._loading.setLoadingState(false);
                    if (this.data?.doc) {
                        const findDocument = this.documents.find((item: any) => {
                            return item.document_type_name === this.data.doc
                        });
                        this.selectDocument(findDocument);
                    }
                });
            }
        });
    }

    changeDocument() {
        this.documents.forEach((item: any) => item.visible = true);
        this.formData = null;
        this.currentStep = 2;
    }

    ngOnDestroy(): void {
        this.currentStep = 1;
        if (this.documents) this.documents.forEach((item: any) => item.visible = true);
        if (this.contracts) this.contracts.forEach((item: any) => item.visible = true);
        this.subscription.unsubscribe();
    }

    changeContract() {
        this.currentStep = 1;
        this.contracts.forEach((item: any) => item.visible = true);
    }

    onSubmit(e: any) {
        this.processing = true;
        const payload: any = {
            name: e.name,
            idSeries: e.series.toUpperCase(),
            idNumber: e.number,
            contractNumber: this.selectedContract.contract_number,
            document: this.selectedDocument.document_type_uid
        }

        if (e.startDate) {
            payload.startDate = this._datePipe.transform(e.startDate, 'dd/MM/YYYY');
            payload.endDate = this._datePipe.transform(e.endDate, 'dd/MM/YYYY');
        }

        this._generate.generatePdf(payload).subscribe((blob: Blob) => {
            this.processing = false;
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = `${this.selectedDocument.document_type_name}.pdf`;
            link.click();

            // Clean up the URL object after download
            window.URL.revokeObjectURL(downloadURL);

            this._router.navigateByUrl('/existing-documents');
        });
    }

    downloadPDF(pdf: any): void {
        const link = document.createElement('a');
        link.href = `assets/documents/${pdf}.pdf`;
        link.download = `${pdf}.pdf`;
        link.click();
        this.currentStep = 1;
        this.changeContract();
    }

    downloadStaticDocument() {
        this.downloadPDF(this.selectedDocument.document_type_name);
    }
}

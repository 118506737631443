import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[mobilizeAutoCapitalize]',
  standalone: true
})
export class AutoCapitalizeDirective {

    constructor(private control: NgControl) {}

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        const value = input.value;

        const transformedValue = this.capitalize(value);
        this.control.control?.setValue(transformedValue, { emitEvent: false });
    }

    private capitalize(value: string): string {
        return value.replace(/\b\w/g, char => char.toUpperCase());
    }
}

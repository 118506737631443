import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PartnersService } from '../../../services/partners.service';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { LoadingService } from '../../../services/loading.service';
import { UserService } from '../../../services/user.service';
import { StorageService } from '../../../services/storage.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { finalize } from 'rxjs';

@Component({
    selector: 'mobilize-impersonation',
    standalone: true,
    imports: [
        PageHeaderComponent,
        FormsModule,
        MatFormField,
        MatInput,
        MatSuffix,
        PaginationComponent,
        MatProgressSpinner
    ],
    templateUrl: './impersonation.component.html',
    styleUrl: './impersonation.component.scss'
})
export class ImpersonationComponent implements OnInit, OnDestroy {

    private _partners = inject(PartnersService);
    private _loading = inject(LoadingService);
    private _user = inject(UserService);
    private _router = inject(Router);
    private _kc = inject(KeycloakService);

    pageTitle = 'Impersonare client';
    query = '';
    partners: any[] = [];
    dataReady = false;
    totalPages: any;
    currentPage = 1;
    limit = 10;
    pagesArray: any;
    processing = false;

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._partners.getPartners().subscribe(rsp => {
            this.partners = rsp.data;
            this.dataReady = true;
            this._loading.setLoadingState(false);
            this.totalPages = rsp.totalPages
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
        });
    }

    search() {
    }

    onPageChange(e: any) {

    }

    accessPartnerAccount(partner: any) {
        this.processing = true;
        this._user.impersonate({partner_id: partner.partner_id, name: partner.partner_name})
            .pipe(finalize(() => {
                this.processing = false;
            }))
            .subscribe(rsp => {
            if (rsp.success) {
                this._kc.updateToken(-1).then(() => {
                    window.location.href= '/dashboard';
                });
            }
        });
    }

    ngOnDestroy() {
        this.processing = false;
    }
}

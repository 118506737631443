import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'countdown',
    standalone: true,
    pure: false
})
export class CountDownPipe implements PipeTransform {
    transform(text: string, args: number) {
        const maxLength = args || 140;
        const length = text?.length || 0;
        return (maxLength - length);
    }
}

<div class="document">
    <div class="header">
        <div class="document-name">{{ document.name }}</div>
    </div>

    <div class="content">
        <div class="details">
            <div class="details__row">generat la: <span>{{ document.createdAt }}</span>
            </div>
            <div class="details__row">valabil pana la: <span>{{ document.expireAt }}</span>
                <span class="bullet" [ngClass]="isValid(document.createdAt, document.expireAt) && 'online' || 'offline'"></span>
            </div>
            <div class="details__row">contract: <span>{{ document.contract }}</span>
            </div>

            <button class="mobilize-btn btn-orange" (click)="documentDownload()">descarca document</button>
        </div>
    </div>
</div>

import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MyRequest } from '../../../interfaces/request';
import { DatePipe, NgClass } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RequestService } from '../../../services/request.service';
import { LoadingService } from '../../../services/loading.service';
import { PadZeroPipe } from '../../../pipes/pad-zero.pipe';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DownloadService } from '../../../services/download.service';
import { basename } from '@angular/compiler-cli';
import { MiscService } from '../../../services/misc.service';

@Component({
    selector: 'mobilize-details',
    standalone: true,
    imports: [
        RouterLink,
        NgClass,
        DatePipe,
        PadZeroPipe,
        ButtonLoaderDirective,
        ReactiveFormsModule
    ],
    templateUrl: './details.component.html',
    styleUrl: './details.component.scss'
})
export class DetailsComponent implements OnInit {

    private _route = inject(ActivatedRoute);
    private _fb = inject(FormBuilder);
    private _download = inject(DownloadService);
    private _request = inject(RequestService);
    private _misc = inject(MiscService);
    private _loading = inject(LoadingService);
    private _sanitizer = inject(DomSanitizer);
    private _router = inject(Router);

    params: any = {};
    request!: MyRequest;
    showReplyForm = false;
    replyForm!: FormGroup;
    messages: any[] = [];
    data: any = {};
    isNew = false;

    constructor() {

        this._route.params.subscribe(rsp => {
            this.params = rsp;
        });

        this.replyForm = this._fb.group({
            subject: ['', Validators.required],
            message: ['', Validators.required],
            //files: [null]
        });
    }

    ngOnInit() {
        this.isNew = this._misc.newRequest;

        this._loading.setLoadingState(true);
        this._request.getRequest(this.params.id).subscribe(rsp => {
            this.request = rsp;
            this._loading.setLoadingState(false);

            const firstMessage = {
                author: this.request.sender_name,
                receiver: this.request.receiver,
                created_at: this.request.created_at,
                content: this.sanitizeHtml(this.request.request_body),
                subject: this.sanitizeHtml(this.request.request_subject),
                fe_status: this.request.fe_status,
                isReply: false,
                request_files: this.request.request_files
            }

            this.messages.push(firstMessage);

            if (this.request.reply) {
                const replyMessage = {
                    author: this.request.reply.sender_name + ' (Mobilize FS)',
                    receiver: this.request.sender_name,
                    created_at: this.request.reply.created_at,
                    content: this.request.reply.reply_body,
                    fe_status: this.request.fe_status,
                    isReply: true,
                    request_files: this.request.request_files.map(item => JSON.parse(item))
                }

                this.messages.push(replyMessage);
                this.messages.sort((a: any, b: any) => <any>new Date(b.created_at) - <any>new Date(a.created_at));
                if (this.request.fe_status === 'Finalizata' && this.request.bo_status === 'Rezolvata - Raspuns trimis') {
                    this._request.markAsRead({request_uid: this.request.client_request_uid}).subscribe();
                }
            }
        });
    }

    sanitizeHtml(html: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }

    reply() {
        this.showReplyForm = true;
        this.replyForm.reset();
    }

    downloadFile(key: string) {
        const filename: any = key.split('/').pop();
        this._download.downloadFile(filename).subscribe(
            (rsp) => {
                const a = document.createElement('a');
                a.href = rsp.url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(rsp.url);
            }
        );
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { RequestService } from '../../../services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PadZeroPipe } from '../../../pipes/pad-zero.pipe';
import { DatePipe, NgClass } from '@angular/common';
import { LoadingService } from '../../../services/loading.service';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { RequestsFilterComponent } from '../../components/requests-filter/requests-filter.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { saveAs } from 'file-saver';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-requests-reports',
    standalone: true,
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    providers: [
        provideMomentDateAdapter(MY_FORMATS),
        DatePipe,
        PadZeroPipe
    ],
    imports: [
        ReactiveTypedFormsModule,
        PageHeaderComponent,
        PadZeroPipe,
        DatePipe,
        NgClass,
        RequestsSearchComponent,
        RequestsFilterComponent
    ],
    templateUrl: './requests-reports.component.html',
    styleUrl: './requests-reports.component.scss'
})
export class RequestsReportsComponent implements OnInit {

    private _request = inject(RequestService);
    private _route = inject(ActivatedRoute);
    private _loader = inject(LoadingService)
    private _router = inject(Router);
    private _datePipe = inject(DatePipe);
    private _padZero = inject(PadZeroPipe);

    query: string = '';
    dataReady = false;
    currentPage = 1;
    limit = 15;
    reports: any = [];
    totalPages!: number
    pagesArray: any = [];
    counts: any;
    showFilter = false;
    isProcessing = false;
    filterActive = false;
    filtersLoaded = false;

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? + params['page'] : 1;
            this.limit = params['limit'] ? + params['limit'] : this.limit;
            this.query = params['query']

            this.loadReports(this.currentPage, this.query);
        });
    }

    loadReports(page: number, searchQuery?: string) {
        this._loader.setLoadingState(true);
        this.dataReady = false;

        this._request.getRequestReport({page, limit: this.limit, searchQuery: this.query}).subscribe((rsp: {
            data: any[];
            currentPage: any;
            counts: any;
            totalPages: number
        }) => {
            this.counts = rsp.counts;
            this.reports = rsp.data;
            this.currentPage = parseInt(rsp.currentPage, 10);
            this.totalPages = rsp.totalPages;
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            this.dataReady = true;
            this._loader.setLoadingState(false);
        });
    }

    search(event: any) {
        this.query = event;
        this.loadReports(1, event);
        this.onPageChange(1)
    }

    onPageChange(event: any) {
        if (event.page < 1 || event.page > this.totalPages) {
            return;
        }
        const queryParams: any = { page: event.page, limit: event.limit || this.limit }
        if (event.query && event.query !== '') {
            queryParams.query = event.query
        }

        this._router.navigate([], {
            relativeTo: this._route,
            queryParams,
        });
    }

    reportDetails(report: any) {
        this._router.navigateByUrl(`/backoffice/report-details/${report.client_request_uid}`);
    }

    downloadReport() {
        const preparedJson: any[] = []
        this.reports.forEach((report: any) => {
            preparedJson.push({
                'Referinta': this._padZero.transform(report.request_number, 7),
                'Partener': report.partner.partner_name,
                'Contract': report.contract_number,
                'Categorie': report.request_subject,
                'Status': report.bo_status,
                'Primita': this._datePipe.transform(report.created_at, 'dd/MM HH:mm'),
                'Last Update': this._datePipe.transform(report.updated_at, 'dd/MM HH:mm'),
                'In Time': report.inTime ? 'Yes' : 'No'
            })
        })
        this._request.downloadReport(preparedJson).subscribe(rsp => {
            // const blob = new Blob([rsp], { type: 'application/ece' });
            const date = this._datePipe.transform(new Date(), 'dd/MM/YYYY HH:mm')
            saveAs(rsp, `${date}-report.xlsx`);
        });
    }

    toggleFilter() {
        if (!this.filtersLoaded) return;
        this.showFilter = !this.showFilter;
    }

    applyFilters(event: any) {
        this.isProcessing = true;
        this._request.applyFilters(event).subscribe(rsp => {
            this.reports = rsp;
            this.isProcessing = false;
            this.filterActive = !event.reset;
        });
    }

    canShowFilters(event: any) {
        this.filtersLoaded = event;
    }
}

<div class="bo-user-modal">
    <div class="modal-title">Modifica utilizator</div>

    <form [formGroup]="form">
        <div class="form-group">
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Nume si prenume</mat-label>
                    <input matInput type="text" formControlName="fullName" placeholder="Andrei Popescu">
                </mat-form-field>
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Adresa de email</mat-label>
                    <input matInput type="text" formControlName="email" placeholder="andrei.popescu@mobilize-fs.com">
                </mat-form-field>
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Rol</mat-label>
                    <input matInput type="text" formControlName="role">
                </mat-form-field>
            </div>
        </div>
        <div class="form-actions">
            @if (data.user.role === 'eCare Admin') {
                <button class="mobilize-btn btn-orange" (click)="save()">Salveaza</button>
                @if (data.user.status === 'inactiv') {
                    <button class="mobilize-btn btn-clear-green" (click)="enableUser()">Activeaza</button>
                } @else {
                    <button class="mobilize-btn btn-clear-red" (click)="disableUser()">Inactiveaza</button>
                }
            } @else {
                <button class="mobilize-btn btn-clear-red" (click)="deleteUser()">Sterge</button>
            }
            <button class="mobilize-btn btn-clear" (click)="closeModal()">Cancel</button>
        </div>
    </form>
</div>

import { Injectable } from '@angular/core';
import { Schedule } from '../interfaces/schedule';

@Injectable({
    providedIn: 'root',
})
export class MiscService {
    processData(rsp: Schedule[]): any[] {
        const dataGroupedByYear = new Map<number, Schedule[]>();
        rsp.forEach((row: Schedule) => {
            const year = new Date(row.due_date).getFullYear();
            if (!dataGroupedByYear.has(year)) {
                dataGroupedByYear.set(year, []);
            }
            dataGroupedByYear.get(year)?.push(row);
        });
        // Flatten the grouped data with a year "header"
        const processedData: any[] = [];
        dataGroupedByYear.forEach((rows, year) => {
            processedData.push({ year } as unknown as Schedule);
            processedData.push(...rows);
        });
        return processedData;
    }

    onPageChange(component: any, event: any, router: any, route: any, totalPages: number): void {
        if (event.page < 1 || event.page > totalPages) {
            return;
        }
        const queryParams: any = { page: event.page, limit: event.limit || component.limit };
        if (event.query && event.query !== '') {
            queryParams.query = event.query;
        }
        router.navigate([], {
            relativeTo: route,
            queryParams,
        });
    }
}

import { Component } from '@angular/core';

@Component({
  selector: 'mobilize-not-allowed',
  standalone: true,
  imports: [],
  templateUrl: './not-allowed.component.html',
  styleUrl: './not-allowed.component.scss'
})
export class NotAllowedComponent {

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class GenerateDocumentService {

    constructor(private _http: HttpClient) {
    }

    getDocumentsTypes(payload: any): Observable<any> {
        return this._http.post('pdf-generator/documents-list', payload);
    }

    excel(payload: any) {
        return this._http.post('excel/export', payload, {responseType: 'blob'});
    }

    generatePdf(payload: any) {
        return this._http.post('pdf-generator/generate-document', {data: payload}, {responseType: 'blob'})
    }
}

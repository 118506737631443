import { Component, inject, Input, OnInit } from '@angular/core';
import { InstallmentsSliderComponent } from '../installments-slider/installments-slider.component';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { BaseComponent } from '../base.component';
import { MatDialog } from '@angular/material/dialog';
import { OptionDetailsComponent } from '../option-details/option-details.component';
import { Router } from '@angular/router';

@Component({
    selector: 'mobilize-insurance-box',
    standalone: true,
    imports: [
        InstallmentsSliderComponent,
        NgIf,
        NgClass,
        DatePipe
    ],
    templateUrl: './insurance-box.component.html',
    styleUrl: './insurance-box.component.scss'
})
export class InsuranceBoxComponent extends BaseComponent implements OnInit {

    @Input() insurance!: any;

    private _dialog = inject(MatDialog);
    private _router = inject(Router);

    contract: any;

    ngOnInit() {
        switch (this.insurance.insurance_type) {
            case 'RCA':
                this.contract = this.insurance.rcaContracts[0]
                break;
            case 'Polita CASCO':
                this.contract = this.insurance.cascoContracts[0]
                break;
            case 'Asigurare Trafic Accident':
                this.contract = this.insurance.adiContracts[0]
                break;
        }
    }


    goToDocuments(doc: string) {
        this._router.navigateByUrl('generate-document', {state: {data: {doc, contract: this.contract}}});
    }

    insuranceDetails() {
        this._dialog.open(OptionDetailsComponent, {
            width: '1000px',
            data: {
                option: this.insurance,
                contract: this.contract
            }
        })
    }

    get insuranceCurrency() {
        if (this.insurance.rcaContracts.length) {
            return this.insurance.rcaContracts[0].currency_code
        }
        if (this.insurance.cascoContracts.length) {
            return this.insurance.cascoContracts[0].currency_code
        }
        if (this.insurance.adiContracts.length) {
            return this.insurance.adiContracts[0].currency_code
        }

        return '';
    }
}

import { Component, inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { StorageService } from '../../../../services/storage.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegisterService } from '../../../../services/register.service';
import { UsersService } from '../../../../services/users.service';
import { AlertService } from '../../../../components/alert/alert.service';
import { NgClass } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../../components/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { ButtonLoaderDirective } from '../../../../directives/button-loader.directive';
import { finalize } from 'rxjs';

@Component({
    selector: 'mobilize-associated-users',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        MatProgressBar,
        ButtonLoaderDirective
    ],
    templateUrl: './associated-users.component.html',
    animations: [
        trigger('toggleOpen', [
            state('closed', style({
                height: '0',
                opacity: 0,
                overflow: 'hidden',
                padding: '0'
            })),
            state('opened', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden',
                padding: '*'
            })),
            transition('closed <=> opened', [
                animate('300ms ease-in-out')
            ])
        ])
    ],
    styleUrl: './associated-users.component.scss'
})
export class AssociatedUsersComponent implements OnInit {

    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;

    private _user = inject(UserService);
    private _users = inject(UsersService);
    private _storage = inject(StorageService);
    private _dialog = inject(MatDialog);
    private _fb = inject(FormBuilder);
    private _register = inject(RegisterService);
    private _alert = inject(AlertService);
    private _router = inject(Router);

    users!: any[];
    form!: FormGroup;
    user!: any;
    isProcessing = false;

    constructor() {
        this.form = this._fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    ngOnInit() {
        this._user.userData().subscribe(rsp => {
            this.user = rsp;
            this._users.getAssociatedUsers({partner_id: this.user.partner}).subscribe(users => {
                this.users = users;
                this.users.forEach(user => {
                    user.opened = false;
                })
                this.users = this.users.filter(user => user.email !== this.user.email);
            });
        });
    }

    impersonate(user: string) {
        this._user.impersonate(user).subscribe(rsp => {
            const parsedResponse = {
                identity: rsp[0].split(';')[0].split('=')[1], // Extract identity token
                session: rsp[1].split(';')[0].split('=')[1]  // Extract session token
            };

            this._storage.impersonatedSessionToken = parsedResponse.identity;
        });
    }

    registerUser() {
        this.isProcessing = true;
        this._register.createAssociatedUser({
            email: this.form.value.email,
            partner_id: this.user.partner
        })
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                    this.form.controls['email'].setValue('');
                })
            )
            .subscribe({
            next: (newUser: any) => {
                this.users.push(newUser);
                this._alert.showAlert('top-success', 'Utilizatorul a fost invitat!', '', this.targetViewContainerRef, 3);
            },
            error: (error) => {
                switch (error.error.status) {
                    case 409:
                        this._alert.showAlert('top-error', 'Adresa de email exista deja!', '', this.targetViewContainerRef, 3);
                        break;

                    default:
                        this._alert.showAlert('top-error', 'A intervenit o eroare! Va rugam reincercati.', '', this.targetViewContainerRef, 3);
                }
            }
        });
    }

    removeUser(user: any) {
        this.confirmAction({
            title: 'Esti sigur ca vrei sa stergi utilizatorul?'
        }).afterClosed().subscribe((rsp: any) => {
            if (rsp) {
                this._users.deactivate({email: user.email}).subscribe(() => {
                    this.users = this.users.filter(item => item.email !== user.email);
                    this._alert.showAlert('top-success', 'Utilizatorul a fost sters!', '', this.targetViewContainerRef, 3);
                });
            }
        });
    }

    passAdminRights(user: any) {
        this.confirmAction({
            title: 'Esti sigur ca vrei sa transferi dreptul de Admin?'
        }).afterClosed().subscribe((rsp: any) => {
            if (rsp) {
                const payload = {
                    user,
                    existingAdminUser: this.user.sub,
                    process: 'pass_admin_rights'
                }

                this._users.passAdminRights(payload).subscribe(() => {
                    window.location.href = '/account-settings';
                });
            }
        });
    }

    cancelInvite(user: any) {
        this.confirmAction({
            title: 'Esti sigur ca vrei sa anulezi invitatia?'
        }).afterClosed().subscribe((rsp: any) => {
            if (rsp) {
                this._users.cancelInvite({enrollment_uid: user.enrollment_uid}).subscribe(() => {
                    this._alert.showAlert('top-success', 'Invitatia a fost anulata!', '', this.targetViewContainerRef, 3);
                    this.users = this.users.filter(item => item.enrollment_uid !== user.enrollment_uid);
                });
            }
        });
    }

    resendInvite(user: any) {
        this.confirmAction({
            title: 'Esti sigur ca vrei sa retrimiti invitatia?'
        }).afterClosed().subscribe((rsp: any) => {
            if (rsp) {
                this._users.resendInvite({activation_url: user.activation_url, email: user.email}).subscribe(() => {
                    this._alert.showAlert('top-success', 'Invitatia a fost retrimisa!', '', this.targetViewContainerRef, 3);
                });
            }
        });
    }

    toggleUserOpen(user: any) {
        user.opened = !user.opened;
    }

    private confirmAction(payload: any) {
        return this._dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: payload
        })
    }
}

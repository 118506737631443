import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AsyncPipe, NgForOf } from '@angular/common';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import {
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate
} from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { map, Observable, startWith } from 'rxjs';
import { RequestService } from '../../../services/request.service';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'mobilize-users-filter',
    standalone: true,
    imports: [
        AsyncPipe,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatInput,
        MatLabel,
        MatOption,
        MatSuffix,
        NgForOf,
        ReactiveFormsModule,
        ReactiveTypedFormsModule,
        ButtonLoaderDirective,
        MatDateRangeInput,
        MatDateRangePicker,
        MatEndDate,
        MatStartDate,
        MatSelect
    ],
    templateUrl: './users-filter.component.html',
    styleUrl: './users-filter.component.scss'
})
export class UsersFilterComponent implements OnInit {

    private _fb = inject(FormBuilder);
    private _requests = inject(RequestService);

    @Input() processing: boolean = false;
    @Input() filterActive: boolean = false;
    @Input() section: string = '';
    @Output() filtersLoaded = new EventEmitter<any>();
    @Output() onCloseFilters = new EventEmitter<any>();
    @Output() onApplyFilters = new EventEmitter<any>();

    partners: any[] = [];
    contracts: any[] = [];
    categories: any[] = [];
    statuses: any[] = [];
    filterForm: FormGroup;
    filters = false;

    filteredPartners!: Observable<any[]>;

    constructor() {
        this.filterForm = this._fb.group({
            role: [null],
            partner: [null],
            status: [null]
        });
    }

    ngOnInit() {
        this._requests.getFilters().subscribe(rsp => {
            this.partners = rsp.partners;
            this.filtersLoaded.next(true);
            this.filters = true;
        });
        this.filteredPartners = this.filterForm.controls['partner'].valueChanges.pipe(
            startWith(''),
            map(value => {
                return this._filterPartners(value || '')
            }),
        );
    }

    private _filterPartners(value: string): string[] {
        const filterValue = value;

        if (!Number.isInteger(filterValue)) {
            return this.partners.filter(partner => partner.partner_name.toLowerCase().includes(filterValue));
        } else {
            return this.partners.filter(partner => partner.partner_id.toString().includes(filterValue));
        }
    }

    getPartnerName(partnerId: any) {
        if (this.partners.find((partner) => partner.partner_id === partnerId)) {

            return this.partners.find((partner) => partner.partner_id === partnerId).partner_name;
        }
    }

    applyFilters() {
        if (Object.keys(this.filterForm.value).every(key => this.filterForm.value[key] === null)) {
            return;
        }
        this.processing = true;
        this.filterActive = true;
        this.onApplyFilters.next(this.filterForm.value);
    }

    closeFilter() {
        this.filterForm.reset();
        this.onCloseFilters.next(true);
    }

    resetFilter() {
        this.filterForm.reset();
        this.onApplyFilters.next({...this.filterForm.value, reset: true });
        this.onCloseFilters.next(true);
        this.filterActive = false;
    }
}

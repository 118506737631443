<mobilize-page-header title="User management" [reverse]="true"></mobilize-page-header>

<div class="requests-filter">
    <div class="requests-filter__search">
        <mobilize-requests-search (onSearch)="search($event)" [query]="query" placeholder="Cauta dupa nume sau email"></mobilize-requests-search>
    </div>
    <div class="requests-filter__filter-trigger" (click)="toggleFilter()">
        <div class="label"><i class="icon-mobilize-filter"></i> Filtreaza</div>
        <i class="icon-mobilize-chevron-down" [@rotateChevron]="showFilter ? 'open' : 'closed'"></i>
    </div>
    <button class="mobilize-btn btn-white" (click)="downloadReport()">Descarca Raport</button>
    <button class="mobilize-btn btn-orange" (click)="addUser()">Adauga utilizator</button>
</div>

<div class="filter-wrapper mobilize-card" [@slideToggle]="showFilter ? 'open' : 'closed'">
    <mobilize-users-filter
        [processing]="isProcessing"
        [filterActive]="filterActive"
        (filtersLoaded)="canShowFilters($event)"
        (onApplyFilters)="applyFilters($event)"
        section="user-management"
        (onCloseFilters)="toggleFilter()"></mobilize-users-filter>
</div>

<div class="users-table">
    <div class="users-table__header">
        <div class="users-table-row">
            <div class="table-cell username">Username</div>
            <div class="table-cell name">Nume</div>
            <div class="table-cell partner">Partener</div>
            <div class="table-cell status">Status</div>
            <div class="table-cell role">Rol</div>
        </div>
    </div>
    <div class="users-table__content">
        @for (user of users; track user) {
            <div class="users-table-row" (click)="userDetails(user);" [ngClass]="{'user-inactive': user.status === 'inactiv'}">
                <div class="table-cell username">{{ user.email }}</div>
                <div class="table-cell name">{{ user.full_name }}</div>
                <div class="table-cell partner">{{ user.partner_name || '-' }}</div>
                <div class="table-cell status">{{ user.status }}</div>
                <div class="table-cell role">{{ user.role }}</div>
            </div>
        } @empty {
            <div class="mobilize-card small-card text-center">
                Nu exista utilizatori
            </div>
        }
    </div>
</div>

@if (dataReady) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}

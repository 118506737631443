import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { DatePipe, NgClass } from '@angular/common';
import { PadZeroPipe } from '../../../pipes/pad-zero.pipe';
import { UserService } from '../../../services/user.service';
import { UsersService } from '../../../services/users.service';
import { LoadingService } from '../../../services/loading.service';
import { finalize } from 'rxjs';
import { RequestsFilterComponent } from '../../components/requests-filter/requests-filter.component';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { RequestService } from '../../../services/request.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { MiscService } from '../../../services/misc.service';
import { UsersFilterComponent } from '../../components/users-filter/users-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from '../../components/add-user/add-user.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditUserComponent } from '../../components/edit-user/edit-user.component';

@Component({
    selector: 'mobilize-user-management',
    standalone: true,
    providers: [
        DatePipe
    ],
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    imports: [
        PageHeaderComponent,
        DatePipe,
        PadZeroPipe,
        RequestsFilterComponent,
        RequestsSearchComponent,
        PaginationComponent,
        UsersFilterComponent,
        NgClass
    ],
    templateUrl: './user-management.component.html',
    styleUrl: './user-management.component.scss'
})
export class UserManagementComponent implements OnInit {

    private _users = inject(UsersService);
    private _loading = inject(LoadingService);
    private _misc = inject(MiscService);
    private _dialog = inject(MatDialog);
    private _route = inject(ActivatedRoute);
    private _snackBar = inject(MatSnackBar);
    private _request = inject(RequestService);
    private _router = inject(Router);
    private _datePipe = inject(DatePipe);

    users: any[] = [];
    dataReady = false;
    currentPage = 1;
    limit = 10;
    totalPages: any;
    pagesArray: any;
    isProcessing = false;
    query = '';
    filtersLoaded = false;
    showFilter = false;
    currentFilters: any;
    filterActive = false;

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;
            this.loadUsers(this.currentPage);
        });
    }

    search(event: any) {
        this.loadUsers({query: event})
    }

    toggleFilter() {
        if (!this.filtersLoaded) return;
        this.showFilter = !this.showFilter;
    }

    applyFilters(event: any) {
        this._loading.setLoadingState(true);
        this.currentFilters = event;
        this.isProcessing = true;
        this._router.navigate([], {
            relativeTo: this._route
        }).then(() => {
            this.loadUsers(event)
        });
    }

    loadUsers(payload: any) {
        this._loading.setLoadingState(true);
        this.dataReady = false;
        this._users.getAllUsers({
            ...payload,

            page: this.currentPage,
            limit: this.limit
        })
            .pipe(
                finalize(() => {
                    this._loading.setLoadingState(false);
                })
            )
            .subscribe((rsp: any) => {
                this.users = rsp.data;
                this.currentPage = parseInt(rsp.currentPage, 10);
                this.totalPages = rsp.totalPages;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
                this.dataReady = true;
                this.isProcessing = false;
                this._loading.setLoadingState(false);
            });
    }

    userDetails(user: any) {
        this._dialog.open(EditUserComponent, {
            width: '624px',
            height: 'auto',
            autoFocus: false,
            data: {
                user
            }
        }).afterClosed().subscribe(rsp => {
            if (rsp.success) {
                this._snackBar.open(rsp.message, 'Ok', {
                    duration: 5000
                });
                switch (rsp.action) {
                    case 'update':
                        this.users.forEach((user: any) => {
                            user.full_name = rsp.fullName;
                        });
                        break;

                    case 'delete':
                        this.users = this.users.filter(u => u.email !== rsp.user);
                        break;

                    case 'disable':
                        this.users.forEach((user: any) => {
                            user.status = 'inactiv';
                        });
                        break;

                    case 'enable':
                        this.users.forEach((user: any) => {
                            user.status = 'active';
                        });
                        break;
                }
            }
        });
    }

    downloadReport() {
        this._request.downloadReport({
            filters: this.currentFilters,
            query: this.query,
            section: '6eb0ed65-11ed-4423-90a1-47d3899a05cd'
        }).subscribe(rsp => {
            const date = this._datePipe.transform(new Date(), 'dd/MM/YYYY HH:mm')
            saveAs(rsp, `${date}-report.xlsx`);
        });
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    canShowFilters(event: any) {
        this.filtersLoaded = event;
    }

    addUser() {
        this._dialog.open(AddUserComponent, {
            width: '624px',
            height: 'auto',
        }).afterClosed().subscribe(rsp => {
            if (rsp && rsp.success) {
                this._snackBar.open('Contul a fost creat cu succes!', 'Ok', {
                    duration: 5000
                })
            } else {
                if (rsp && rsp.error) {
                    this._snackBar.open(rsp.error, 'Ok', {
                        duration: 5000,
                        panelClass: 'error-snack'
                    })
                }
            }
        })
    }
}

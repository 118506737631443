import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[mobilizeAutoUppercase]',
  standalone: true
})
export class AutoUppercaseDirective {

    constructor(private control: NgControl) { }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        const value = input.value.toUpperCase();

        this.control.control?.setValue(value, { emitEvent: false });
    }
}

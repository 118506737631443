<mobilize-page-header title="Bine ai venit," section="Andrei Florin Constantinescu"></mobilize-page-header>

@if (contracts.length) {
    <div class="dashboard-wrapper">
        <div class="top-row">
            <div class="payment mobilize-card">
                <div class="title">Ai de plata</div>
                <div class="payment-wrapper">
                    <div class="left-side">
                        <div class="amount" [innerHTML]="formatAmount(unpaidAmount, 'RON')"></div>
                        <button class="mobilize-btn btn-orange" routerLink="/pay">plateste</button>
                    </div>

                    <div class="right-side">
                        <div class="payment-delay">
                            @if (unpaidAmount !== 0) {
                                <span class="icon-mobilize-warning"></span>
                                ai restante
                            }
                        </div>

                        <div class="bills" routerLink="/bills-and-payments">
                            <span class="icon-mobilize-receipt"></span>
                            Facturi si plati
                        </div>
                    </div>
                </div>
            </div>

            <div class="request-wrapper mobilize-card mobilize-card-black">
                <div class="top-side">
                    <div class="title">Ai o solicitare pentru noi?</div>
                    <span class="icon-mobilize-smile"></span>
                </div>

                <div class="bottom-side">
                    <button class="mobilize-btn btn-white" routerLink="/my-requests/new-request">trimite solicitare
                    </button>
                </div>
            </div>
        </div>

        <div class="documents mobilize-card">
            <div class="title">Documente utile</div>

            <div class="mobilize-card-content">
                <div class="document">
                    <span class="icon-mobilize-bolt"></span>
                    Imputernicire deschidere dosar dauna
                </div>
                <div class="document">
                    <span class="icon-mobilize-bolt"></span>
                    Imputernicire iesire din tara
                </div>
                <div class="document simple-link" (click)="goToDocuments()">
                    <span class="icon-mobilize-add"></span>
                    Mai multe
                </div>
            </div>
        </div>

        <div class="active-contracts mobilize-card no-padding-card">
            <div class="title">
                Contractele mele active ({{ contracts.length }})
            </div>

            <div class="contracts-wrapper">
                @for (contract of contracts; track contract.id) {
                    <mobilize-contract section="dashboard" [contract]="contract"></mobilize-contract>
                }
            </div>
        </div>
    </div>
}

import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';

@Component({
    selector: 'mobilize-payment-response',
    standalone: true,
    imports: [
        PageHeaderComponent,
        RouterLink
    ],
    templateUrl: './payment-response.component.html',
    styleUrl: './payment-response.component.scss'
})
export class PaymentResponseComponent {

    private _route = inject(ActivatedRoute);

    params: any;
    pageTitle = 'Confirmare proces plata';

    constructor() {
        this._route.queryParams.subscribe(rsp => {
            this.params = rsp;
        })
    }
}

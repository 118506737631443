import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractOptionDetails } from '../../interfaces/contract';
import { BaseComponent } from '../base.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'mobilize-option-details',
    standalone: true,
    imports: [
        DatePipe
    ],
    templateUrl: './option-details.component.html',
    styleUrl: './option-details.component.scss'
})
export class OptionDetailsComponent extends BaseComponent implements OnInit, AfterViewInit {

    insuranceOption = false;
    loaded = false;
    insurance!: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private modalRef: MatDialogRef<OptionDetailsComponent>) {
        super();
    }

    ngOnInit() {
        console.log(this.data);
        this.insurance = this.data.option;
        this.insuranceOption = !this.insurance.service_name;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loaded = true;
        }, 2000);
    }

    closeModal() {
        this.modalRef.close();
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ng2-cookies';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AlertService } from '../../components/alert/alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'mobilize-account-activation',
    standalone: true,
    imports: [
        MatProgressBar
    ],
    templateUrl: './account-activation.component.html',
    styleUrl: './account-activation.component.scss'
})
export class AccountActivationComponent implements OnInit {

    private _register = inject(RegisterService);
    private _route = inject(ActivatedRoute);
    private _cookie = inject(CookieService);
    private _router = inject(Router);
    private _snackBar = inject(MatSnackBar);

    ngOnInit() {
        this._route.params.subscribe((params: any) => {
            if (params && params.enrollment_uid) {
                // check if enrollment exists and is not active
                this._register.validateEnrollment({enrollment_uid: params.enrollment_uid}).subscribe(rsp => {
                    if (rsp.responseCode === 205) {
                        this._register.sendRegisterData({
                            step: 'password',
                            enrollment: rsp
                        });
                        console.log('asdasd')
                        this._router.navigateByUrl('/register');
                    }
                }, (error: any) => {
                    if (error.status === 400) {
                        const snackBarRef = this._snackBar.open('Linkul de activare este invalid!', 'Ok', {
                            verticalPosition: 'top',
                            panelClass: 'error-snack'
                        });
                        snackBarRef.onAction().subscribe(() => {
                            this._router.navigateByUrl('/dashboard');
                        });
                    }
                });
            }
        })
    }
}

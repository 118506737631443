import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { InstallmentsSliderComponent } from '../installments-slider/installments-slider.component';
import { NgClass, NgIf } from '@angular/common';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { BaseComponent } from '../base.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EarlyRepaymentService } from '../../services/early-repayment.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { InfoModalComponent } from '../../pages/early-repayment/components/info-modal/info-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { generate } from 'rxjs';

@Component({
    selector: 'mobilize-early-repayment-contract',
    standalone: true,
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    imports: [
        InstallmentsSliderComponent,
        NgIf,
        ReactiveTypedFormsModule,
        ButtonLoaderDirective,
        NgClass,
        MatTooltip,
        MatProgressSpinner
    ],
    templateUrl: './early-repayment-contract.component.html',
    styleUrl: './early-repayment-contract.component.scss'
})
export class EarlyRepaymentContractComponent extends BaseComponent implements OnInit {
    private _snackbar = inject(MatSnackBar);
    private _dialog = inject(MatDialog);
    private _earlyRepayment = inject(EarlyRepaymentService);

    @Input() contract: any;
    @Input() firstContract: any;
    @Input() selectedContract: any;
    @Output() onPay = new EventEmitter();
    @Output() onFullReimbursement = new EventEmitter();
    @Output() notifyParent: EventEmitter<any> = new EventEmitter();

    extendContract = false;
    monthsRemaining: any;
    totalFullReimbursement = 0;
    calculatingRates = false;
    paymentPhase = false;
    fullReimbursementData: any;
    totalAmountLoaded = false;
    fullReimbursementActive = false;
    newMonthlyRate: any;
    currentStep = 1;
    isProcessing = false;
    snackErrorRef: any;
    choice = '';
    steps: { index: number; state: 'active' | 'inactive' | 'passed' }[] = [
        {
            index: 1,
            state: 'inactive'
        },
        {
            index: 2,
            state: 'inactive'
        },
        {
            index: 3,
            state: 'inactive'
        }
    ];

    ngOnInit() {
        if (this.selectedContract) {
            if (this.selectedContract.contract.contract_id === this.contract.contract_id) {
                this.toggleContractState();
            }
        } else {
            if (this.firstContract) {
                this.toggleContractState()
            }
        }
    }

    toggleContractState(autoClose?: boolean) {
        if (autoClose) {
            this.extendContract = false;
            this.contract.state = 'collapsed';
            return;
        }
        this.extendContract = !this.extendContract;
        if (this.extendContract) {
            this.contract.state = 'extended';
            this.notifyParent.emit({closeOtherContracts: true, selected: this.contract});
            this.totalAmountLoaded = false;
            this._earlyRepayment.fullReimbursement({contract_id: this.contract.contract_id}).subscribe(rsp => {
                this.fullReimbursementData = rsp;
                this.totalFullReimbursement = rsp.grand_total.faraTva;
                this.totalAmountLoaded = true;
            });
        } else {
            this.contract.state = 'collapsed';
        }
    }

    get bulletStateClass(): string[] {
        return this.steps.map(step => {
            if (step.index === this.currentStep) {
                return 'active';
            } else if (step.index < this.currentStep) {
                return 'passed';
            } else {
                return 'inactive';
            }
        });
    }

    finalizePayment(contract: any) {
        this.onPay.next(contract)
    }

    openInfo() {
        this._dialog.open(InfoModalComponent, {
            width: '470px'
        })
    }

    previousStep() {
        this.currentStep--;
    }

    nextStep(contract: any, option?: string) {
        this.contract = contract;

        if (option) {
            this.choice = option;
        }

        switch (this.currentStep) {
            case 1:
                if (!contract.amount) {
                    this.snackErrorRef = this._snackbar.open('Introdu suma pe care vrei sa o achiti', 'Ok', {
                        verticalPosition: 'top',
                        panelClass: 'snack-error',
                        duration: 5000
                    });
                    return;
                } else {
                    this.currentStep++;
                    if (this.snackErrorRef) {
                        this.snackErrorRef.dismiss();
                    }
                }
                break;

            case 2:
                this.isProcessing = true;
                const payload: any = {};
                if (option === 'installment') {
                    payload.paymentAdvance = contract.amount;
                    payload.contract_id = contract.contract_id;
                    payload.reducePeriod = false;
                    this._earlyRepayment.calculate(payload).subscribe(rsp => {
                        if (rsp.success) {
                            this.currentStep++;
                            this.newMonthlyRate = rsp.newMonthlyRateGross;
                            this.monthsRemaining = rsp.monthsRemaining;
                            this.isProcessing = false;
                            this.calculatingRates = false;
                        } else {
                            this._snackbar.open(rsp.error, 'Ok', {
                                duration: 5000,
                                panelClass: 'error-snack'
                            });
                            this.currentStep = 1;
                        }
                    })
                } else {
                    this._earlyRepayment.calculateInstallments({contract_id: contract.contract_id}).subscribe(rsp => {
                        this.calculateRemainingRates();
                    });
                }
                break;
        }
    }

    calculateRemainingRates() {
        const payload: any = {};
        payload.paymentAdvance = this.contract.amount;
        payload.contract_id = this.contract.contract_id;
        payload.reducePeriod = true;

        this._earlyRepayment.calculate(payload).subscribe(rsp => {
            if (rsp.success) {
                this.currentStep++
                this.newMonthlyRate = rsp.newMonthlyRateNet;
                this.monthsRemaining = rsp.monthsRemaining;

                this.isProcessing = false;
                this.calculatingRates = false;
            } else {
                this._snackbar.open(rsp.error, 'Ok', {
                    duration: 5000,
                    panelClass: 'error-snack'
                });
                this.currentStep = 1;
            }
        });
    }

    fullReimbursement() {
        this.fullReimbursementActive = true;
        this.onFullReimbursement.next({contract: this.contract, data: this.fullReimbursementData});
    }

    generateDocument() {
        const payload = {contract_id: this.contract.contract_id};
        this._earlyRepayment.generateDocument(payload).subscribe((blob: Blob) => {
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = `test.pdf`;
            link.click();

            // Clean up the URL object after download
            window.URL.revokeObjectURL(downloadURL);
        })
    }
}

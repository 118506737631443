import { Menu } from '../interfaces/menu';

const iconPrefix = 'icon-mobilize-';
export const MENU: Menu[] = [
	{
		title: 'Sumar',
		icon: iconPrefix + 'home',
		route: '/dashboard'
	},
	{
		title: 'Fa o plata',
		icon: iconPrefix + 'creditcard',
		route: '/pay'
	},
	{
		title: 'Rapoarte si facturi',
		icon: iconPrefix + 'reports',
		route: 'submenu',
        submenu: [
            {
                name: 'Facturi si plati',
                route: '/bills-and-payments'
            },
            {
                name: 'Scadentar',
                route: '/schedule'
            },
            {
                name: 'Scadente viitoare',
                route: '/upcoming-due-dates'
            },
        ]
	},
	{
		title: 'Contracte si servicii',
		icon: iconPrefix + 'folder',
		route: '/contracts'
	},
	{
		title: 'Asigurari',
		icon: iconPrefix + 'shield',
		route: '/insurance'
	},
	{
		title: 'Documente utile',
		icon: iconPrefix + 'attach',
		route: '/existing-documents'
	},
	{
		title: 'Solicitarile mele',
		icon: iconPrefix + 'bell',
		route: '/my-requests'
	},
	{
		title: 'Setari cont',
		icon: iconPrefix + 'user',
		route: 'account-settings'
	},
	{
		title: 'Logout',
		icon: iconPrefix + 'key',
		route: 'logout'
	},
]

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContractService {

    constructor(private _http: HttpClient) {
    }

    getContract(id: number): Observable<any> {
        return this._http.get(`contracts/details/${id}`);
    }

    getContracts(): Observable<any> {
        return this._http.get(`contracts/list`);
    }
}

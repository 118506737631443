import { AfterViewInit, Component, inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { AlertService } from '../../components/alert/alert.service';
import { Bill } from '../../interfaces/bill';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PadNumberPipe } from '../../pipes/pad-number.pipe';
import { BaseComponent } from '../../components/base.component';
import { retry } from 'rxjs';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'mobilize-pay',
	standalone: true,
    imports: [
        PageHeaderComponent,
        NgClass,
        FormsModule,
        PadNumberPipe,
        NgIf,
        RouterLink
    ],
	templateUrl: './pay.component.html',
	styleUrl: './pay.component.scss'
})
export class PayComponent extends BaseComponent implements OnInit, AfterViewInit {

	constructor() {
		super();
	}

    totalAmountForPayment = 0;

	@ViewChild('targetAlertContainer', { read: ViewContainerRef }) targetViewContainerRef!: ViewContainerRef;

	private _alert = inject(AlertService);

	bills: Bill[] = [
		{
			checked: false,
			np: '01 mar',
			title: 'Polita Casco rata 3 - ctr. CR RON20011',
			status: 'neachitat',
			value: 100,
			dueDate: 'scadenta la 30/07/2024',
			dateOfBilling: '30/07/2021',
			documentNumber: 'NRC34234',
			totalDue: 100,
			payed: 0,
			amountDue: 100,
			billUrl: 'asd',
            extended: false
		},
		{
			checked: false,
			np: '01 mar',
			title: 'Rata 3 - ctr. CR RON7567567',
			status: 'restant',
			value: 1000,
			dueDate: 'scadenta la 30/07/2024',
			dateOfBilling: '30/07/2021',
			documentNumber: 'NRC34234',
			totalDue: 100,
			payed: 0,
			amountDue: 100,
			billUrl: 'asd',
            extended: false
		}
	];

	ngAfterViewInit() {
		this.openAlert();
	}

	ngOnInit() {
	}

    extendBill(bill: Bill) {
        bill.extended = !bill.extended;
    }

    checkBill(e: any, bill: Bill) {
        if (bill.checked) {
            this.totalAmountForPayment += bill.value;
        } else {
            this.totalAmountForPayment -= bill.value
        }
    }

	private openAlert() {
		this._alert.showAlert('danger', 'Ai 1 facturi cu scadenta depasita. Nu poti deselecta restantele.', 'icon-mobilize-warning', this.targetViewContainerRef)
	}

	statusOptions(status: string) {
		const object = {
			class: ''
		};

		switch (status) {
			case 'neachitat':
				object.class = 'not-payed';
				break
			case 'restant':
				object.class = 'over-due';
				break
		}

		return object;
	}
}

<mobilize-page-header title="Bine ai venit, " section="Andrei Florin Constantinescu"></mobilize-page-header>

@if (contracts.length) {
    <div class="dashboard-wrapper">
        <div class="top-row">
            <div class="payment mobilize-card">
                <div class="dashboard-top-box">
                    <div class="title">Ai de plata</div>
                    <div class="amount" [innerHTML]="formatAmount(unpaidAmount, 'RON')"></div>
                    <button class="mobilize-btn btn-orange" (click)="pay()">plateste</button>
                </div>
                @if (extraPaidAmount > 0) {
                    <div class="dashboard-top-box">
                        <div class="title">Ai achitat in avans</div>
                        <div class="amount" [innerHTML]="formatAmount(extraPaidAmount, 'RON')"></div>
                    </div>
                }
                <div class="dashboard-top-box payments">
                    @if (overdue && unpaidAmount > 0) {
                        <div class="payment-delay" routerLink="/pay">
                            <span class="icon-mobilize-warning"></span>
                            ai restante
                        </div>
                    }
                    <div class="bills" routerLink="/bills-and-payments">
                        <span class="icon-mobilize-receipt"></span>
                        Facturi si plati
                    </div>
                </div>
            </div>

            <div class="request-wrapper mobilize-card mobilize-card-black">
                <div class="top-side">
                    <div class="title">Ai o solicitare pentru noi?</div>
                    <span class="icon-mobilize-smile"></span>
                </div>

                <div class="bottom-side">
                    <button class="mobilize-btn btn-white" routerLink="/my-requests/new-request">trimite solicitare
                    </button>
                </div>
            </div>
        </div>

        <div class="documents mobilize-card">
            <div class="title">Documente utile</div>

            <div class="mobilize-card-content">
                <div class="document simple-link" (click)="goToDocuments('Imputernicire deschidere dosar dauna')">
                    <span class="icon-mobilize-bolt"></span>
                    Imputernicire deschidere dosar dauna
                </div>
                <div class="document simple-link" (click)="goToDocuments('Imputernicire iesire din tara')">
                    <span class="icon-mobilize-bolt"></span>
                    Imputernicire iesire din tara
                </div>
                <div class="document simple-link" (click)="goToDocuments()">
                    <span class="icon-mobilize-add"></span>
                    Mai multe
                </div>
            </div>
        </div>

        <div class="active-contracts mobilize-card no-padding-card">
            <div class="title">
                Contractele mele active ({{ contracts.length }})
            </div>

            <div class="contracts-wrapper">
                @for (contract of contracts; track contract.id) {
                    <mobilize-contract section="dashboard" [contract]="contract"></mobilize-contract>
                }
            </div>
        </div>
    </div>
}

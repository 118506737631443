import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InsuranceService {

    private _http = inject(HttpClient);

    getInsurances(payload: any): Observable<any> {
        return this._http.post(`insurance/list`, payload);
    }
}

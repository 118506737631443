import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private _isLoading: WritableSignal<any> = signal(null);

    setLoadingState(value: any) {
        this._isLoading.set(value);
    }

    get getLoadingState() {
        return this._isLoading
    }
}

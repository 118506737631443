import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EarlyRepaymentService {

    private _http = inject(HttpClient);

    calculate(payload: any): Observable<any> {
        return this._http.post('early-repayment/process', payload);
    }

    calculateInstallments(payload: any): Observable<any> {
        return this._http.post('early-repayment/process-installments', payload);
    }

    fullReimbursement(payload: any): Observable<any> {
        return this._http.post('early-repayment/process-full-reimbursement', payload);
    }

    generateDocument(payload: any): Observable<any> {
        return this._http.post('pdf-generator/generate-payment-document', payload, {responseType: 'blob'});
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';

@Component({
    selector: 'mobilize-requests-search',
    standalone: true,
    imports: [
        ReactiveTypedFormsModule
    ],
    templateUrl: './requests-search.component.html',
    styleUrl: './requests-search.component.scss'
})
export class RequestsSearchComponent {

    @Input() query = '';
    @Output() onSearch = new EventEmitter<any>();

    search() {
        console.log(this.query);
        this.onSearch.emit(this.query);
    }
}

<div class="document">
    <div class="header">
        <div class="document-name">{{ document.document_type_name }}</div>
    </div>

    <div class="content">
        <div class="details">
            <div class="details__row">generat la: <span>{{ document.created_at | date: 'dd/MM/YYYY' }}</span>
            </div>
            @if (document.document_type_name === 'Imputernicire iesire din tara') {
                <div class="details__row">valabil pana la: <span>{{ expireAt }}</span>
                    <span class="bullet" [ngClass]="isValid && 'online' || 'offline'"></span>
                </div>
            }
            <div class="details__row">contract: <span>{{ document.metadata.contractNumber }}</span>
            </div>

            <button class="mobilize-btn btn-orange" (click)="documentDownload()" [mobilizeButtonLoader]="processing"
                    originalContent="descarca document">descarca document
            </button>
        </div>
    </div>
</div>

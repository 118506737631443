import { Component, inject, Input } from '@angular/core';
import { DatePipe, NgClass } from '@angular/common';
import { MyRequest } from '../../../../interfaces/request';
import { Router } from '@angular/router';
import { PadZeroPipe } from '../../../../pipes/pad-zero.pipe';

@Component({
    selector: 'mobilize-request-box',
    standalone: true,
    imports: [
        NgClass,
        PadZeroPipe,
        DatePipe
    ],
    templateUrl: './request-box.component.html',
    styleUrl: './request-box.component.scss'
})
export class RequestBoxComponent {

    @Input() request!: MyRequest;

    private router = inject(Router);

    requestDetails() {
        this.router.navigateByUrl(`/my-requests/inbox/${this.request.client_request_uid}`);
    }

    get statusClass(): string {
        switch (this.request.fe_status) {
            case 'Trimisa':
                return 'sent';

            case 'In lucru':
                return 'in-progress'

            case 'Finalizata':
                return 'resolved'
        }
        return '';
    }
}

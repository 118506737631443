import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { InsuranceBoxComponent } from '../../components/insurance-box/insurance-box.component';
import { InsuranceService } from '../../services/insurance.service';
import { LoadingService } from '../../services/loading.service';
import { finalize } from 'rxjs';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DataFilterComponent } from '../../components/data-filter/data-filter.component';

@Component({
    selector: 'mobilize-insurance',
    standalone: true,
    imports: [
        PageHeaderComponent,
        InsuranceBoxComponent,
        PaginationComponent,
        DataFilterComponent,
    ],
    providers: [],
    templateUrl: './insurance.component.html',
    styleUrl: './insurance.component.scss'
})
export class InsuranceComponent implements OnInit {

    private _insurance = inject(InsuranceService);
    private _loading = inject(LoadingService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);

    insuranceList: any[] = [];
    dataReady = false;
    currentPage = 1;
    totalPages!: number;
    limit = 10;
    pagesArray: any;
    filterProcessing = false;
    filterArgs: any;
    contractsArrayIds: any;
    filterOptions = ['search', 'state']

    ngOnInit() {
        this._loading.setLoadingState(true);
    }

    execFilter(e: any) {
        this.dataReady = false;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.getInsurances(e);
        });
    }

    getInsurances(payload: any) {
        delete payload.contracts;
        this._loading.setLoadingState(true);
        this.filterProcessing = true;
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;
            this.insuranceList = [];
            if (!payload.state) {
                payload.state = 'Valid';
            }

            this._insurance.getInsurances({...payload, page: this.currentPage, limit: this.limit})
                .pipe(
                    finalize(() => {
                        this._loading.setLoadingState(false);
                        this.dataReady = true;
                        this.filterProcessing = false;
                    })
                )
                .subscribe(rsp => {
                    this.insuranceList.push(...rsp.data);
                    this.insuranceList = this.getLatestByDocType(this.insuranceList);
                    this.currentPage = rsp.currentPage;
                    this.totalPages = rsp.totalPages;
                    this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
                });
        });
    }



    getLatestByDocType(insurances: any[]): any[] {
        const groupedInsurances = new Map<number, any>();

        insurances.forEach((insurance) => {
            const { insurance_doc_type_id, insurance_start_date } = insurance;

            const currentLatest = groupedInsurances.get(insurance_doc_type_id);

            if (
                !currentLatest ||
                new Date(insurance_start_date) > new Date(currentLatest.insurance_start_date)
            ) {
                groupedInsurances.set(insurance_doc_type_id, insurance);
            }
        });

        return Array.from(groupedInsurances.values());
    }

    contractIds(e: any) {
        this.getInsurances({contracts: e});
        this.contractsArrayIds = e;
    }

    onPageSelect(event: any) {
        this.insuranceList = [];
        this._loading.setLoadingState(true);
        if (event.page < 1 || event.page > this.totalPages) {
            return;
        }
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {page: event.page, limit: event.limit || this.limit}
        }).then(() => {
            // Load requests with the new filters
            this.getInsurances(this.currentPage);
        });
    }
}

<mobilize-page-header [title]="pageTitle" [reverse]="true"></mobilize-page-header>

<div class="search-box">
    <form>
        <mat-form-field class="example-full-width">
            <input matInput placeholder="Cauta partener" name="query" [(ngModel)]="query" (keydown.enter)="search()">
            <span matSuffix (click)="search()" class="icon-mobilize-search"></span>
        </mat-form-field>
    </form>
</div>

<div class="partners-table">
    <div class="partners-table__row">
        <div class="header">Partner ID</div>
        <div class="header">Fiscal Code</div>
        <div class="header">Partner Name</div>
        <div class="header">Actions</div>
    </div>

    @for (partner of partners; track partner) {
        <div class="partners-table__row">
            <div class="cell">{{ partner.partner_id }}</div>
            <div class="cell">{{ partner.fiscal_code }}</div>
            <div class="cell">{{ partner.partner_name }}</div>
            <div class="cell font-orange">
                @if (!processing) {
                    <div (click)="accessPartnerAccount(partner)"><span class="icon-mobilize-user"></span> Acceseaza
                    </div>
                } @else {
                    <mat-progress-spinner class="orange-spinner" diameter="15" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
                }
            </div>
        </div>
    }
</div>

@if (dataReady) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}

<p></p>

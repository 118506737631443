import { inject, Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { SocketEvent } from './socket-event';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private _kc = inject(KeycloakService);

    private socket!: any | undefined;
    private static SETTINGS = {
        reconnection: true,
        reconnectionDelay: 5000,
        path: '/ws/socket.io',
    };

    constructor() {

    }

    public initConnection() {
        this._kc.getToken().then(rsp => {

            if (this.socket) {
                return;
            }

            const payload: any = {...SocketService.SETTINGS};
            const queryParams: any[] = [];

            const _token = rsp;

            if (_token) {
                queryParams.push({
                    name: 'token',
                    value: _token
                });
            }

            if (queryParams.length !== 0) {
                const searchParams = new URLSearchParams();
                queryParams.forEach(obj => searchParams.append(obj.name, obj.value));
                payload.query = searchParams.toString();
            }

            payload.transports = ['websocket', 'polling'];
            this.socket = io(environment.socketUrl, payload);
            this.socket.on(SocketEvent.CONNECT, () => {
            });
        });
    }

    public emit(event: string, data: any): void {
        this.socket.emit(event, data);
    }

    public on(event: string) {
        return new Observable((observer) => {
            this.socket.on(event, (data: any) => {
                observer.next(data);
            });

            // Cleanup on unsubscribe
            return () => {
                this.socket.off('message');
            };
        });
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { AlertService } from '../../components/alert/alert.service';
import { Bill } from '../../interfaces/bill';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PadNumberPipe } from '../../pipes/pad-number.pipe';
import { BaseComponent } from '../../components/base.component';
import { finalize, retry } from 'rxjs';
import { RouterLink } from '@angular/router';
import { InvoiceService } from '../../services/invoice.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'mobilize-pay',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgClass,
        FormsModule,
        PadNumberPipe,
        NgIf,
        RouterLink,
        DatePipe,
        ButtonLoaderDirective
    ],
    templateUrl: './pay.component.html',
    styleUrl: './pay.component.scss'
})
export class PayComponent extends BaseComponent implements OnInit, AfterViewInit {

    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;
    @ViewChild('paymentForm', { static: false }) paymentForm!: ElementRef;
    @Input() modal: boolean = false;

    private _invoice = inject(InvoiceService);
    private _loading = inject(LoadingService);
    private _alert = inject(AlertService);

    constructor() {
        super();
    }

    totalAmountForPayment = 0;

    invoices: any[] = [];
    checkedInvoices: any[] = [];
    inputsArray: any[] = [];
    processing = false;
    overDues = 0;

    ngAfterViewInit() {
    }

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._invoice.unpaidInvoices()
            .pipe(
                finalize(() => {
                    this._loading.setLoadingState(false);
                })
            )
            .subscribe(rsp => {
            this.invoices = rsp.data;

            this.invoices.forEach(item => {
                if (this.invoiceStatusText(item) === 'restant') {
                    item.checked = true;
                    this.totalAmountForPayment += parseFloat(item.rest_amount);
                    this.checkedInvoices.push(item);
                    this.overDues++;
                }
            });

            if (this.overDues > 0) {
                this.openAlert(this.overDues);
            }
        });
    }

    extendBill(bill: Bill) {
        bill.extended = !bill.extended;
    }

    checkBill(e: any, invoice: any) {
        if (this.invoiceStatusText(invoice) === 'restant') {
            invoice.checked = true;
            return;
        }

        if (invoice.checked) {
            this.totalAmountForPayment += parseFloat(invoice.rest_amount);
            this.checkedInvoices.push(invoice);
        } else {
            this.totalAmountForPayment -= parseFloat(invoice.rest_amount);
            this.checkedInvoices = this.checkedInvoices.filter(item => item.invoice_id !== invoice.invoice_id);
        }
    }

    private openAlert(no: number) {
        this._alert.showAlert('danger', `Ai ${no} facturi cu scadenta depasita. Nu poti deselecta restantele.`, 'icon-mobilize-warning', this.targetViewContainerRef)
    }

    processPayment() {
        this.processing = true;
        const payload: any = {
            total: this.totalAmountForPayment,
            invoices: this.checkedInvoices
        };

        this._invoice.payInvoice(payload)
            .subscribe(rsp => {
            Object.keys(rsp).forEach(key => {
                this.inputsArray.push({name: key, value: rsp[key]})
            });
            setTimeout(() => {
                this.paymentForm.nativeElement.submit();
                this.processing = false;
            }, 1000)
        });
    }
}

<mobilize-page-header title="Plata anticipata" [reverse]="true"></mobilize-page-header>

<div class="mobilize-card no-bg">
    <div class="tabs-wrapper">
        <div class="tabs-header">
            <div class="tabs-header__tab" [ngClass]="{'selected': selectedTab === 'new-payment'}"
                 (click)="selectedTab = 'new-payment'"><span>Plata noua</span></div>
            <div class="tabs-header__tab" [ngClass]="{'selected': selectedTab === 'history'}"
                 (click)="selectedTab = 'history'"><span>Istoric</span></div>
        </div>

        <div class="tabs-content">
            <div class="tabs-content__tab new-payment-tab">
                <div class="progress-tracking">
                    <div class="bullet" [ngClass]="{'active': pageStep === 1, 'passed': pageStep === 2}">1</div>
                    <div class="tracking-bar" [ngClass]="{'passed': pageStep === 2}"
                         [style.height]="(contractsContainerHeight + 20) + 'px'"></div>
                    <div class="bullet" [ngClass]="{'active': pageStep === 2}">2</div>
                </div>

                <div class="payment-type">
                    <div class="title">{{ pageStep === 1 ? 'Alege tipul platii' : 'Ai ales tipul platii' }}</div>

                    <div class="contracts-list" #contractsContainer>
                        @for (contract of contracts; track contract) {
                            <div class="contracts-list__item contract-{{ contract.state }}">
                                <div class="short-info">
                                    <div class="collapsed-view">
                                        <span>{{ contract.name }}</span>
                                        <div class="item-extend" [ngClass]="contract.state"
                                             (click)="toggleContractState(contract)"><span
                                            class="icon-mobilize-chevron-down"></span></div>
                                    </div>

                                    <div class="extended-view">
                                        <div class="contract-object">
                                            <span class="label-name">bun finantat:</span>
                                            <span class="label-value">{{ contract.financedGood }}</span>
                                        </div>
                                        <div class="contract-object" *ngIf="contract.state !== 'ready'">
                                            <span class="label-name">ai achitat:</span>
                                            <span class="label-value">
                                            <span [innerHTML]="formatAmount(contract.payed, 'EUR')"></span>
                                            <mobilize-installments-slider
                                                [maxInstallments]="contract.maximumInstallments"
                                                [payedInstallments]="contract.payedInstallments"></mobilize-installments-slider>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="payment-process-steps" *ngIf="contract.state === 'extended'">
                                    <div class="payment-process-steps__left">
                                        <div class="title">Fac o plata partiala</div>
                                        <div class="steps">
                                            <div class="step step-1" *ngIf="currentStep === 1">
                                                <div class="step-title">Suma pe care view sa o achiti (RON):</div>
                                                <input type="text" [(ngModel)]="contract.amount"
                                                       placeholder="scrie valoarea">
                                                <button class="mobilize-btn btn-orange" (click)="nextStep(contract)">
                                                    continua
                                                </button>
                                            </div>
                                            <div class="step step-2" *ngIf="currentStep === 2">
                                                <div class="step-subtitle">Suma pe care vrei sa o achiti: <span
                                                    [innerHTML]="formatAmount(contract.amount, 'EUR')"></span></div>
                                                <button class="mobilize-btn btn-orange"
                                                        (click)="nextStep(contract, 'installment')">
                                                    vreau sa reduc rata
                                                </button>
                                                <button class="mobilize-btn btn-orange"
                                                        (click)="nextStep(contract, 'period')">vreau
                                                    sa reduc perioada
                                                </button>
                                                <button class="mobilize-btn btn-clear" (click)="previousStep()">inapoi
                                                </button>
                                            </div>
                                            <div class="step step-3" *ngIf="currentStep === 3">
                                                <div class="step-subtitle">Suma pe care view sa o achiti: <span
                                                    [innerHTML]="formatAmount(contract.amount, 'EUR')"></span></div>
                                                @if (choice === 'installment') {
                                                    <p>Ai ales sa reduci: <span>rata</span></p>
                                                    <p>Valoarea noii rate: <span class="bg-orange-light"
                                                                                 [innerHTML]="formatAmount(875.45, 'EUR')"></span>
                                                    </p>
                                                    <p>Numar rate ramase: <span>32</span></p>
                                                }

                                                @if (choice === 'period') {
                                                    <p>Vrei sa reduci: <span>perioada</span></p>

                                                    <div class="slider-title">Alege numarul de rate:</div>

                                                    <div class="slider-wrapper">
                                                        <div class="slider-min">min. 4</div>
                                                        <mat-slider discrete disableRipple
                                                                    class="installments-slider" min="4" max="56"
                                                                    step="1">
                                                            <input matSliderThumb value="1"
                                                                   (valueChange)="newInstallments($event)">
                                                        </mat-slider>

                                                        <div class="slider-max">max. 56</div>
                                                    </div>
                                                    <p>Valoarea noii rate: <span class="bg-orange-light"
                                                                                 [innerHTML]="formatAmount(875.45, 'EUR')"></span>
                                                        }
                                                <div class="buttons">
                                                    <button class="mobilize-btn btn-orange" (click)="finalizePayment()">
                                                        plateste partial
                                                    </button>
                                                    <button class="mobilize-btn btn-clear" (click)="previousStep()">
                                                        inapoi
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="steps-bullets">
                                            <div class="bullet" [ngClass]="bulletStateClass[0]"></div>
                                            <div class="bullet" [ngClass]="bulletStateClass[1]"></div>
                                            <div class="bullet" [ngClass]="bulletStateClass[2]"></div>
                                        </div>
                                    </div>

                                    <div class="choice-info">sau</div>

                                    <div class="payment-process-steps__right">
                                        <div class="title">Fac plata integrala</div>

                                        <div class="amount-to-pay">
                                            TOTAL DE PLATA: <span [innerHTML]="formatAmount(contract.totalDue, 'EUR')"></span>
                                            <i class="icon-mobilize-info" (click)="openInfo()"></i>
                                        </div>

                                        <button class="mobilize-btn btn-clear">plateste integral</button>
                                    </div>
                                </div>

                                <div class="payment-process-summary" *ngIf="contract.state === 'ready'">
                                    <div class="title">Faci o plata anticipata partiala</div>

                                    <p>Suma pe care vreo sa o achiti: <span class="font-orange"
                                                                            [innerHTML]="formatAmount(contract.amount, 'EUR')"></span>
                                    </p>
                                    <p>Noua rata lunara va fi: <span [innerHTML]="formatAmount(1490.5, 'EUR')"></span></p>
                                    <p>Numarul de rate de achitat: 40</p>
                                </div>
                            </div>
                        }
                    </div>

                    <div class="title">Plateste</div>

                    @if (!showFinalConfirmation) {
                        <div class="payment-options" *ngIf="pageStep === 2">
                            <div class="payment-options__item" [ngClass]="{'active': selectedPaymentMethod === 1}"
                                 (click)="selectPay(1);selectedPaymentMethod = 1">
                                <span><span></span></span> Platesc online, cu cardul
                            </div>
                            <div class="payment-options__item" [ngClass]="{'active': selectedPaymentMethod === 2}"
                                 (click)="selectPay(2);selectedPaymentMethod = 2">
                                <span><span></span></span> Platesc cu OP
                            </div>
                        </div>

                        <div class="payment-info" *ngIf="pageStep === 2 && selectedPaymentMethod === 1">
                            Vei fi transferat in pagina procesatorului de plati
                        </div>

                        <ng-template #targetAlertContainer></ng-template>

                        <div class="payment-actions" *ngIf="pageStep === 2">
                            <button class="mobilize-btn btn-orange"
                                    (click)="pay()">{{ selectedPaymentMethod === 1 ? 'plateste securizat' : 'genereaza proforma' }}
                            </button>
                            <button class="mobilize-btn btn-clear" (click)="back()">inapoi</button>
                        </div>
                    } @else {
                        <div class="final-confirmation">
                            <div class="title"><span class="icon-mobilize-check-mark"></span> Factura proforma a fost
                                generata
                            </div>
                            <p>Trebuie sa faci plata in contul de pe factura in urmatoarele 48 de ore.</p>
                            <button class="mobilize-btn btn-orange">descarca proforma</button>

                            <div class="payment-details">
                                <div class="title">Detalii pentru OP:</div>
                                <p>Suma: <span [innerHTML]="formatAmount(15000, 'RON')"></span></p>
                                <p>Beneficiar: <span>RCI Leasing SA</span></p>
                                <p>Detalii plata: <span>356DF578</span></p>
                            </div>
                        </div>

                        <button class="mobilize-btn btn-orange">Adauga o plata noua</button>
                    }
                </div>
            </div>
            <div class="tabs-content__tab history-tab" [hidden]="selectedTab !== 'history'">
                @for (item of history; track item) {
                    <div class="history-item" [ngClass]="item.uiState">
                        <div class="title">
                            {{ item.title }}
                            <div class="item-extend" (click)="extendItem(item)"><span class="icon-mobilize-chevron-down"></span></div>
                        </div>

                        <div class="history-item__row">
                            data initierii: <span>{{ item.doc }}</span>
                        </div>
                        <div class="history-item__row">
                            stare cerere: <span>{{ item.state }}</span> <span class="state-bullet"
                                                                              [ngClass]="historyStateClass(item.stateBullet)"></span>
                        </div>
                        <div class="history-item__row">
                            suma rambursata anticipat: <span>{{ item.earlyRepaymentAmount }}</span>
                        </div>
                        <div class="history-item__extended-view">
                            <div class="history-item__row">
                                noua rata lunara va fi: <span>{{ item.newInstallmentAmount }}</span>
                            </div>
                            <div class="history-item__row">
                                numarul de rate de achitat: <span>{{ item.newInstallmentsNumber }}</span>
                            </div>
                            <div class="history-item__row">
                                bun finantat: <span>{{ item.financedGood }}</span>
                            </div>
                        </div>

                        <div class="history-item__actions">
                            <button class="mobilize-btn btn-orange">descarca proforma</button>
                            <button class="mobilize-btn btn-clear">anuleaza cererea</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>

import { Component, inject, Input, OnInit } from '@angular/core';
import { DatePipe, NgClass } from '@angular/common';
import { PadZeroPipe } from '../../../pipes/pad-zero.pipe';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { RequestService } from '../../../services/request.service';
import { LoadingService } from '../../../services/loading.service';
import { SocketService } from '../../../services/socket.service';
import { MatDialog } from '@angular/material/dialog';
import { EscalateUsersModalComponent } from '../escalate-users-modal/escalate-users-modal.component';
import { DownloadService } from '../../../services/download.service';

@Component({
    selector: 'mobilize-bo-request',
    standalone: true,
    imports: [
        DatePipe,
        PadZeroPipe,
        NgClass,
        ButtonLoaderDirective,
        ReactiveFormsModule
    ],
    templateUrl: './bo-request.component.html',
    styleUrl: './bo-request.component.scss'
})
export class BoRequestComponent {

    private _fb = inject(FormBuilder);
    private _request = inject(RequestService);
    private _dialog = inject(MatDialog);
    private _download = inject(DownloadService);

    @Input() request: any;
    @Input() detailsPage: boolean = false;
    @Input() reportPage: boolean = false;

    loading = false;
    showReplyForm = false;
    replyForm!: FormGroup;

    constructor() {
        this.replyForm = this._fb.group({
            subject: ['', Validators.required],
            message: ['', Validators.required],
            //files: [null]
        });
    }

    get statusClass(): string {
        switch (this.request.bo_status) {
            case 'Primita':
                return 'received';

            case 'Citita':
                return 'read'

            case 'Escaladata':
                return 'escalated'

            case 'Rezolvata - Raspuns trimis':
                return 'resolved'

            case 'Rezolvata - Citita':
                return 'closed'
        }
        return '';
    }

    replyRequest() {
        this.showReplyForm = true;
        this.replyForm.controls['subject'].setValue(`Re: ${this.request.request_subject}`);
    }

    sendReply() {
        if (this.replyForm.invalid) {
            this.replyForm.markAllAsTouched();
            return;
        }
        this.loading = true;
        this._request.sendReply({
            message: this.replyForm.controls['message'].value,
            request_uid: this.request.client_request_uid
        })
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(rsp => {
                if (rsp.success) {
                    this.request.reply = rsp.reply;

                    this.request.bo_status = 'Rezolvata - Raspuns trimis';

                    this.showReplyForm = false;
                    this.replyForm.reset();
                }
            });
    }

    cancelReply() {
        this.showReplyForm = false;
        this.replyForm.reset();
    }

    escalateRequest() {
        this._dialog.open(EscalateUsersModalComponent, {
            width: '740px',
            panelClass: 'admin-list-modal'
        }).afterClosed().subscribe(rsp => {
            if (rsp.escalate) {
                this._request.escalate({request_uid: this.request.client_request_uid, escalate_to: rsp.uid, escalate_to_name: rsp.name}).subscribe(response => {
                    if (response.success) {
                        this.request.escalate_at = new Date();
                    }
                })
            }
        });
    }

    downloadFile(key: string) {
        const filename: any = key.split('/').pop();
        this._download.downloadFile(filename).subscribe(
            (rsp) => {
                const a = document.createElement('a');
                a.href = rsp.url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(rsp.url);
            }
        );
    }
}

import { ChangeDetectorRef, Component, ViewRef } from '@angular/core';

@Component({
	selector: 'mobilize-base',
	standalone: true,
	template: '',
    providers: []
})
export class BaseComponent {

    protected readonly changeDetectorRef!: ChangeDetectorRef;

	formatAmount(amount: any, currency: string, hideLabel?: boolean) {
		return Intl.NumberFormat('us-US', {
			style: 'currency',
			notation: 'standard',
			currency: currency || 'RON'
		}).format(parseFloat(amount)).replace('€', '').replace(currency, '') + ' <span>' + (!hideLabel ? currency : '') + '</span>'
	}

    parseDateToDisplay(dateString: string): string {
        const date = new Date(dateString);
        let day = date.getDate();
        const month = new Intl.DateTimeFormat('ro-RO', { month: 'short' }).format(date);
        const paddedDay = day < 10 ? `0${day}` : day.toString();

        return `${paddedDay} ${month.replace('.', '')}`;
    }

    public detectChanges(): void {
        if (!this.changeDetectorRef || (this.changeDetectorRef as ViewRef).destroyed) {
            return;
        }
        this.changeDetectorRef.detectChanges();
    }

    isValid(createdAt: string, expireAt: string) {
        const formatDate = (date: string) => {
            const [day, month, year] = date.split('/');
            return new Date(`${year}-${month}-${day}`);
        };

        const createdDate = formatDate(createdAt);
        const expireDate = formatDate(expireAt);
        const currentDate = new Date(); // Current date

        return currentDate >= createdDate && currentDate <= expireDate;
    }
}

import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'mobilize-contact-preferences',
    standalone: true,
    imports: [
        FormsModule,
        NgClass
    ],
    templateUrl: './contact-preferences.component.html',
    styleUrl: './contact-preferences.component.scss'
})
export class ContactPreferencesComponent implements OnChanges {

    email!: boolean;
    sms!: boolean;
    post!: boolean;
    phone!: boolean;

    contactMethodsDisabled = true;

    contactOptions: { name: string; checked: boolean; label: string }[] = [
        {
            name: 'Acord de Marketing RCI',
            checked: false,
            label: '(CMKRCI)'
        }, {
            name: 'Acord de profilare',
            checked: false,
            label: '(CMKADP)'
        }, {
            name: 'Acord de Marketing Renault Commercial Roumanie',
            checked: false,
            label: '(CMKRCR)'
        }
    ];

    ngOnChanges(changes: SimpleChanges) {
        if (changes['contactOptions']) {
            this.checkOptions();
        }
    }

    onCheckboxChange(): void {
        this.checkOptions();
    }

    private checkOptions(): void {
        const isAnyChecked = this.contactOptions.some(option => option.checked);
        this.contactMethodsDisabled = !isAnyChecked;

        if (this.contactMethodsDisabled) {
            this.email = false;
            this.sms = false;
            this.post = false;
            this.phone = false;
        }
    }

    protected readonly open = open;
}

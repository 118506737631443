<div class="mobilize-card small-card outstanding-pay">
    <div class="pay-info">
        <div class="label">Ai de plata</div>
        <div class="amount" [innerHTML]="formatAmount(unpaidAmount, 'RON')"></div>
    </div>

    <div class="pay-action">
        <button class="mobilize-btn btn-orange" [routerLink]="'/pay'">plateste</button>
    </div>
</div>

<mobilize-data-filter (onFilterExec)="execFilter($event)"
                      [processing]="filterProcessing"
                      (onExport)="export()"
                      [filterOptions]="filterOptions"
                      [page]="'current'"
                      (onContractsLoaded)="contractIds($event)"></mobilize-data-filter>

<div class="mobilize-card table-card no-padding-card">
    <div class="schedules-table">
        <div class="schedules-table__row header-row">
            <div class="schedules-table__row-content">
                <div class="cell header">Data</div>
                <div class="cell header">Detalii</div>
                <div class="cell header invoice-amount">Suma (RON)</div>
                <div class="cell header">Factura
                </div>
            </div>

            @if (dataReady) {
                @for (schedule of processedData; track schedule) {
                    @if (schedule.year) {
                        <div class="schedules-table__row year-row">
                            <div class="cell header-cell">{{ schedule.year }}</div>
                        </div>
                    } @else {
                        <div class="schedules-table__row" [ngClass]="{ 'opened': schedule.opened }">
                            <div class="schedules-table__row-content">
                                <div class="cell">
                                    <div class="invoice-date">{{ parseDateToDisplay(schedule.due_date) }}</div>
                                </div>
                                <div class="cell details-cell">
                                    <div class="invoice-details">{{ schedule.rate_name }} -
                                        Contract {{ schedule.contract.contract_number }}
                                    </div>
                                    <div class="invoice-status-wrapper">
                                        <div [innerHTML]="scheduleStatus(schedule)"></div>
                                        <div class="due-date">scadenta
                                            la {{ schedule.due_date | date: 'dd/MM/YYYY' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="cell invoice-amount">
                                    <span
                                        [innerHTML]="formatAmount(schedule.principal_amount, schedule.contract.currency_code)"></span>
                                    @if (shouldShowPaymentUrl(schedule)) {
                                        <span class="payment-url">Plateste</span>
                                    }
                                </div>
                                <div class="cell invoice-document">
                                    @if (documentLoading) {
                                        <mat-progress-spinner class="orange-spinner" diameter="16" strokeWidth="2"
                                                              mode="indeterminate"></mat-progress-spinner>
                                    } @else {
                                        <span class="icon-mobilize-receipt" (click)="downloadInvoice(schedule)"></span>
                                    }
                                    @if (!schedule.opened) {
                                        <span class="icon-mobilize-chevron-down" (click)="toggleRow(schedule)"></span>
                                    } @else {
                                        <span class="icon-mobilize-chevron-up" (click)="toggleRow(schedule)"></span>
                                    }
                                </div>
                            </div>
                            <div class="schedules-table__row-extension">
                                <div class="schedules-table__row-extension-row">
                                    <span>Scadenta la:</span>
                                    <span>{{ schedule.due_date | date: 'dd/MM/YYYY' }}</span>
                                </div>
                                @if (schedule.invoice) {
                                    <div class="schedules-table__row-extension-row">
                                        <span>Nr. document:</span>
                                        <span>{{ schedule.invoice?.invoice_number }}</span>
                                    </div>
                                }
                                <div class="schedules-table__row-extension-row">
                                    <span>Capital:</span>
                                    <span>{{ schedule.principal_amount }}</span>
                                </div>
                                <div class="schedules-table__row-extension-row">
                                    <span>Dobanda:</span>
                                    <span
                                        [innerHTML]="formatAmount(schedule.interest_amount, schedule.contract.currency_code)"></span>
                                </div>
                                <div class="schedules-table__row-extension-row">
                                    <span>Taxa de administrare:</span>
                                    <span
                                        [innerHTML]="formatAmount(schedule.commission_amount, schedule.contract.currency_code)"></span>
                                </div>
                                @if (schedule.invoice) {
                                    <div class="schedules-table__row-extension-row">
                                        <span>Total de plata:</span>
                                        <span
                                            [innerHTML]="formatAmount(schedule.invoice.total, schedule.contract.currency_code)"></span>
                                    </div>
                                    <div class="schedules-table__row-extension-row">
                                        <span>Platit:</span>
                                        <span
                                            [innerHTML]="formatAmount(schedule.invoice.paid_amount, schedule.contract.currency_code)"></span>
                                    </div>
                                }
                                @if (schedule.invoice && schedule.invoice.payment_allocation) {
                                    <div class="schedules-table__row-extension-row">
                                        <span>Rest de plata:</span>
                                        <span
                                            [innerHTML]="formatAmount(schedule.invoice.rest_amount, schedule.contract.currency_code)"></span>
                                    </div>
                                }
                                <div class="schedules-table__row-extension-row">
                                    <span>Stare:</span>
                                    <span [innerHTML]="scheduleStatusText(schedule)"></span>
                                </div>
                            </div>
                        </div>
                    }
                } @empty {
                    <div class="schedules-table__row year-row">
                        <div class="cell header-cell">nu exista scadentare inregistrate</div>
                    </div>
                }
            } @else {
                <div class="mock-invoice-row">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            }
        </div>
    </div>
</div>

@if (dataReady && totalPages > 0) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}

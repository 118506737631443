<ng-template #targetAlertContainer></ng-template>
@if (!users) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
@for (user of users; track user) {
    <div class="user-card" [ngClass]="{'opened': user.opened}">
        <div class="header" (click)="toggleUserOpen(user)">
            <div class="left">
                <span class="icon-mobilize-user"></span> {{ user.email }}
            </div>

            <div class="right">
                <span class="user-role" [ngClass]="user.status">User</span>
                <div class="toggle-user-details" [ngClass]="{'opened': user.opened}">
                    <span class="icon-mobilize-chevron-down"></span>
                </div>
            </div>
        </div>

        <div class="content" [@toggleOpen]="user.opened ? 'opened' : 'closed'">
            @if (user.status === 'active') {
                <div class="user-row">
                    <span>Nume: </span> {{ user.full_name }}
                </div>
            }
            <div class="user-row" [ngClass]="user.status">
                <span>Status: </span>
                @switch (user.status) {
                    @case ('active') {
                        activ
                    }
                    @case ('pending') {
                        invitatie trimisa
                    }
                }
            </div>

            <div class="user-actions">
                @switch (user.status) {
                    @case ('active') {
                        <button class="mobilize-btn btn-orange" (click)="removeUser(user)">sterge user</button>
                        <button class="mobilize-btn btn-clear" (click)="passAdminRights(user)">transfera rolul de Admin
                        </button>
                    }
                    @case ('pending') {
                        <button class="mobilize-btn btn-orange" (click)="resendInvite(user)">retrimite invitatia
                        </button>
                        <button class="mobilize-btn btn-clear" (click)="cancelInvite(user)">anuleaza invitatia</button>
                    }
                }
            </div>
        </div>
    </div>
}

<div class="add-user-wrapper mobilize-card">
    <div class="title">Adauga utilizator</div>

    <form [formGroup]="form">
        <div class="form-group">
            <div class="form-control">
                <input type="text" formControlName="email" placeholder="adresa de email" autocomplete="new-email">
            </div>
        </div>
    </form>

    <button class="mobilize-btn btn-orange" (click)="registerUser()" [mobilizeButtonLoader]="isProcessing" originalContent="Creeaza cont">Trimite link de activare</button>
</div>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    private _http = inject(HttpClient);

    getInvoices(payload: any): Observable<any> {
        return this._http.post(`invoices/list`, payload);
    }

    unpaidInvoices(): Observable<any> {
        return this._http.post(`invoices/unpaid-list`, {});
    }

    payInvoice(payload: any): Observable<any> {
        return this._http.post('payment/process', payload);
    }
}

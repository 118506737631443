import { Injectable } from '@angular/core';
import { CookieService } from 'ng2-cookies';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private sessionId!: string;

    constructor(private cookieService: CookieService) {
    }

    get impersonatedSessionToken(): string {
        if (!this.sessionId) {
            this.sessionId = this.cookieService.get('IMP_TOKEN');
        }
        return this.sessionId;
    }

    set impersonatedSessionToken(token: string) {
        if (token === null) {
            this.cookieService.set('IMP_TOKEN', token, -1, '/', window.location.hostname, true);
        } else {
            const date = new Date();
            date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
            this.cookieService.set('IMP_TOKEN', token, date, '/', window.location.hostname, true);
        }
        this.sessionId = token;
    }

    deleteToken() {
        this.cookieService.delete('IMP_TOKEN');
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { Insurance } from '../../interfaces/insurance';
import { InsuranceBoxComponent } from '../../components/insurance-box/insurance-box.component';
import { InsuranceService } from '../../services/insurance.service';
import { DocumentsService } from '../../services/documents.service';

@Component({
    selector: 'mobilize-insurance',
    standalone: true,
    imports: [
        PageHeaderComponent,
        InsuranceBoxComponent
    ],
    templateUrl: './insurance.component.html',
    styleUrl: './insurance.component.scss'
})
export class InsuranceComponent implements OnInit {

    private _insurance = inject(InsuranceService);

    insuranceList: any[] = [];

    ngOnInit() {
        this._insurance.getInsurances().subscribe(rsp => {
            this.insuranceList.push(...rsp.data);
        });
    }
}

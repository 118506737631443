<div class="contract" [ngClass]="contract.contractState">
    <div class="header">
        <div class="contract-name">
            Contract {{ contract.contract_number }} {{ contract.contract_state === 'Inchis' ? 'de intrebat daca se mai afiseaza' : '' }}
        </div>
        @if (contract.extendable) {
            <div class="chevron">
                <span (click)="toggleState()" class="icon-mobilize-chevron-up"></span>
            </div>
        }
    </div>

    <div class="content">
        <div class="details">
            @if (section === 'timetable') {
                <div class="details__row">bun finantat: <span>{{ contract.contract_objects[0].item_name }}</span>
                </div>
            }

            @if (contract.next_installment) {
                <div class="details__row">urmatoarea rata: <span
                    [innerHTML]="formatAmount(contract.next_installment, contract.currency_code)"></span>
                    ({{ contract.next_due_date | date: 'dd/MM/YYYY' }})
                </div>
            }

            @if (section !== 'timetable') {
                <div class="details__row">sold restant: <span
                    [innerHTML]="formatAmount(contract.overdue_invoices_ron, contract.currency_code)"></span> <span
                    class="installment-status online"></span>
                </div>
            }

            @if (section === 'dashboard') {
                <div class="details__row">documente: <span>0</span>
                </div>
            }

            @if (contract.documents) {
                <div class="details__row">documente: <span>{{ contract.documents.length }}</span></div>
            }

            <mobilize-installments-slider [showInstallments]="contract.contract_state !== 'Inchis'"
                                          [type]="installmentsDisplayType"
                                          [maxInstallments]="maxInstallments"
                                          [payedInstallments]="contract.previous_instalments + 1"></mobilize-installments-slider>

            @if (section === 'timetable') {
                <div class="payment-details">
                    <div>Ai achitat din capital: <span
                        [innerHTML]="formatAmount(payedAmount, contract.currency_code)"></span>
                    </div>
                    @if (contract.outstanding) {
                        <div>Rest de plata capital: <span
                            [innerHTML]="formatAmount(contract.outstanding + contract.rezidual_amount, contract.currency_code)"></span>
                        </div>
                    }
                </div>
            }

            @if (section !== 'timetable') {
                <button class="mobilize-btn btn-orange" (click)="contractDetails(contract.contract_id)">detalii</button>
            } @else {
                <div class="contract-actions">
                    <button class="mobilize-btn btn-orange">descarca scadentar</button>
                    <button class="mobilize-btn btn-clear">plateste anticipat</button>
                </div>
            }
        </div>

        @if (section !== 'timetable') {
            <div class="options">
                @for (option of services; track option) {
                    <mobilize-contract-option class="option-component" [contract]="contract" [contractState]="contract.contract_state" [option]="option"></mobilize-contract-option>
                }
                @for (option of contract.options; track option) {
                    <mobilize-contract-option class="option-component" [contract]="contract" [option]="option"></mobilize-contract-option>
                }
            </div>
        }
    </div>
</div>

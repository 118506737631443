import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import {
    MatDatepickerToggle,
    MatDateRangeInput,
    MatDateRangePicker,
    MatEndDate,
    MatStartDate
} from '@angular/material/datepicker';
import { MatHint, MatLabel, MatOption, MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractService } from '../../services/contract.service';
import { DatePipe, formatDate } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { filter } from 'rxjs';

@Component({
    selector: 'mobilize-data-filter',
    standalone: true,
    imports: [
        MatFormField,
        MatDateRangeInput,
        MatLabel,
        MatSuffix,
        MatHint,
        MatIcon,
        MatDatepickerToggle,
        MatDateRangePicker,
        MatStartDate,
        DatePipe,
        MatEndDate,
        FormsModule,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
        ButtonLoaderDirective
    ],
    templateUrl: './data-filter.component.html',
    animations: [
        trigger('slideDown', [
            state('false', style({ height: '0px', overflow: 'hidden', marginTop: '0px' })),
            state('true', style({ height: '*', overflow: 'hidden' })),
            transition('false <=> true', animate('300ms ease-in-out'))
        ]),
        trigger('rotateChevron', [
            state('false', style({ transform: 'rotate(0deg)' })),
            state('true', style({ transform: 'rotate(180deg)' })),
            transition('false <=> true', animate('300ms ease-in-out'))
        ]),
        trigger('showButton', [
            state('false', style({ display: 'none' })),
            state('true', style({ display: 'block' })),
            transition('false <=> true', animate('300ms ease-in-out'))
        ]),
        trigger('buttonAnimation', [
            state('visible', style({
                opacity: 1,
                height: 40
            })),
            state('hidden', style({
                opacity: 0,
                height: 0
            })),
            transition('hidden => visible', [
                animate('0.1s ease-in')
            ]),
            transition('visible => hidden', [
                animate('0.1s ease-out')
            ])
        ])
    ],
    styleUrl: './data-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFilterComponent implements OnInit {

    @Output() onFilterExec = new EventEmitter<any>();
    @Output() onContractsLoaded = new EventEmitter<any>();
    @Output() onExport = new EventEmitter<any>();
    @Input() processing = false;
    @Input() page: string = '';

    private _fb = inject(FormBuilder);
    private _contracts = inject(ContractService);
    private _datePipe = inject(DatePipe);

    filterForm: FormGroup;
    contracts: any[] = [];
    currentFilter = 'luna curenta';
    isFilterContentVisible = false;

    constructor() {
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        const startDate = formatDate(startOfMonth, 'yyyy-MM-dd', 'en');
        const endDate = formatDate(endOfMonth, 'yyyy-MM-dd', 'en');
        this.filterForm = this._fb.group({
            startDate: [this.page === 'current' ? startDate : currentDate],
            endDate: [endDate],
            contracts: [['all']]
        });
    }

    toggleFilterContent() {
        this.isFilterContentVisible = !this.isFilterContentVisible;
    }

    ngOnInit() {
        this._contracts.getContracts().subscribe(rsp => {
            this.contracts = rsp;
            this.onContractsLoaded.next(this.contracts.map(contract => contract.contract_id));
        });

        this.filterForm.get('startDate')?.valueChanges.subscribe(() => {
            this.checkDateRange();
        });

        this.filterForm.get('endDate')?.valueChanges.subscribe(() => {
            this.checkDateRange();
        });
    }

    export() {
        this.onExport.next(true)
    }

    resetToAllContracts(e: any) {
        if (e.source.value.some((item: any) => item === 'all')) {
            this.filterForm.controls['contracts'].setValue(['all']);
        }
    }

    execFilter() {
        const payload = this.filterForm.value;
        if (payload.contracts[0] === 'all') {
            payload.contracts = this.contracts.map(contract => contract.contract_id);
        }
        this.processing = true;
        this.onFilterExec.next(payload);
    }


    checkDateRange(): void {
        const startDate: string = this.filterForm.get('startDate')?.value;
        const endDate: string = this.filterForm.get('endDate')?.value;

        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

        if (new Date(startDate) + '' === startOfMonth + '' && new Date(endDate) + '' === endOfMonth + '') {
            this.currentFilter = 'luna curenta'
        } else {
            this.currentFilter = <string>this._datePipe.transform(new Date(startDate), 'dd/MM/YYYY') + ' - ' + <string>this._datePipe.transform(new Date(endDate), 'dd/MM/YYYY')
        }
    }
}

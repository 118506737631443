import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    OnInit, QueryList,
    ViewChild, ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { InstallmentsSliderComponent } from '../../components/installments-slider/installments-slider.component';
import { BaseComponent } from '../../components/base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatDialog } from '@angular/material/dialog';
import { AgreementModalComponent } from './components/agreement-modal/agreement-modal.component';
import { EarlyPaymentContract } from '../../interfaces/early-payment-contract';
import { AlertService } from '../../components/alert/alert.service';
import { EarlyPaymentHistory } from '../../interfaces/early-payment-history';
import { ContractService } from '../../services/contract.service';
import { LoadingService } from '../../services/loading.service';
import { InvoiceService } from '../../services/invoice.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
    EarlyRepaymentContractComponent
} from '../../components/early-repayment-contract/early-repayment-contract.component';
import { Router } from '@angular/router';
import { EarlyRepaymentService } from '../../services/early-repayment.service';

@Component({
    selector: 'mobilize-early-repayment',
    standalone: true,
    imports: [
        FormsModule,
        PageHeaderComponent,
        NgClass,
        InstallmentsSliderComponent,
        NgIf,
        MatSlider,
        MatSliderThumb,
        ReactiveFormsModule,
        NgStyle,
        ButtonLoaderDirective,
        MatProgressSpinner,
        EarlyRepaymentContractComponent
    ],
    templateUrl: './early-repayment.component.html',
    styleUrl: './early-repayment.component.scss'
})
export class EarlyRepaymentComponent extends BaseComponent implements OnInit, AfterViewInit {

    constructor() {
        super();

        const navigation: any = this._router.getCurrentNavigation();
        if (navigation?.extras.state) {
            this.data = navigation?.extras.state['data'];
        }
    }

    @ViewChildren(EarlyRepaymentContractComponent) contractChildComponents!: QueryList<EarlyRepaymentContractComponent>;
    @ViewChild('paymentForm', {static: false}) paymentForm!: ElementRef;
    @ViewChild('contractsContainer', {static: false}) contractsContainer!: ElementRef;
    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;

    private _dialog = inject(MatDialog);
    private _alert = inject(AlertService);
    private _router = inject(Router);
    private _invoice = inject(InvoiceService);
    private _contract = inject(ContractService);
    private _loading = inject(LoadingService);

    contracts: any[] = [];
    data: any;

    selectedTab = 'new-payment';
    isProcessing: boolean = false;

    history: EarlyPaymentHistory[] = [
        {
            title: 'Plata anticipata partiala - CR RON446267 ',
            doc: '20/05/2021',
            state: 'in asteptare plata',
            stateBullet: 'red',
            earlyRepaymentAmount: 10000,
            newInstallmentAmount: 1452.6,
            newInstallmentsNumber: 45,
            financedGood: 'Renault Megane 1.6',
            uiState: 'collapsed'
        },
        {
            title: 'Plata anticipata partiala - CR RON123123',
            doc: '20/05/2021',
            state: 'procesata',
            stateBullet: 'green',
            earlyRepaymentAmount: 10000,
            newInstallmentAmount: 1452.6,
            newInstallmentsNumber: 45,
            financedGood: 'Renault Megane 1.6',
            uiState: 'collapsed'
        },
    ];
    backUpContracts: EarlyPaymentContract[] = [];
    selectedPaymentMethod = 1;
    currentStep = 1;
    inputsArray: any[] = [];
    contract: any;
    processing = false;
    showFinalConfirmation = false;
    pageStep = 1;
    amount!: number;
    monthsRemaining = 0;
    fullReimbursementActive = false;
    fullReimbursementData: any;

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._contract.getContracts({}).subscribe(rsp => {
            this.contracts = rsp.data;
            let count = 0;
            this.contracts.forEach((item, idx) => {
                item.maximumInstallments = 0;
                item.totalPayed = 0;
                count = 0;
                item.contract_schedules.forEach((schedule: any) => {
                    if (count < item.previous_instalments && schedule.rate_name !== 'Avans' && schedule.rate_name !== 'Valoare reziduala') {
                        item.totalPayed += parseFloat(schedule.principal_amount);
                        count++;
                    }
                    if (schedule.rate_name !== 'Avans' && schedule.rate_name !== 'Valoare reziduala') {
                        item.maximumInstallments++;
                    }
                });
            });
            this._loading.setLoadingState(false);
        });
    }

    onChildEvent(data: any) {
        this.contractChildComponents.forEach((child) => {
            if (data.selected.contract_id !== child.contract.contract_id) {
                child.toggleContractState(true);
            }
        });
    }

    ngAfterViewInit() {
        this._alert.setDefaultViewContainerRef(this.targetViewContainerRef);
    }

    historyStateClass(state: string): string {
        return `state-${state}`;
    }

    back() {
        this.pageStep = 1;
        this.currentStep = 1;
        this.contracts = this.backUpContracts;

        this.contractChildComponents.forEach(child => {
            if (this.contract.contract_id === child.contract.contract_id) {
                child.paymentPhase = false;
                child.currentStep = 1;
                child.contract.state = 'extended';
            }
        })
    }

    selectPay(method: number) {
        if (method === 1) {
            this._alert.closeAlert();
        } else if (method === 2) {
            this._alert.showAlert('warning', 'Dupa generarea proformei, nu mai poti reveni la pasul 1', 'icon-mobilize-warning', this.targetViewContainerRef)
        }
    }

    finalizePayment(contract: any) {
        this.contract = contract;
        this._dialog.open(AgreementModalComponent, {
            width: '600px'
        }).afterClosed().subscribe(rsp => {
            if (rsp) {
                this.pageStep = 2;
                this.backUpContracts = this.contracts;
                this.contracts = this.contracts.filter(item => item.state === 'extended');
                this.contracts[0].state = 'ready';

                this.contractChildComponents.forEach(child => {
                    if (contract.contract_id === child.contract.contract_id) {
                        child.paymentPhase = true;
                    }
                })
            }
        });
    }

    pay() {
        if (this.selectedPaymentMethod === 1) {
            const payload: any = {
                total: this.fullReimbursementActive ? this.fullReimbursementData.grand_total.total : this.contract.amount,
                contract: this.fullReimbursementActive ? this.contracts[0].contract_id : this.contract.contract_id
            };
            this._invoice.payInvoice(payload)
                .subscribe(rsp => {
                    Object.keys(rsp).forEach(key => {
                        this.inputsArray.push({name: key, value: rsp[key]})
                    });
                    setTimeout(() => {
                        this.paymentForm.nativeElement.submit();
                        this.processing = false;
                    }, 1000)
                });
        } else {
            this.generateInvoice();
        }
    }

    generateInvoice() {
        this.showFinalConfirmation = true;
    }

    extendItem(item: EarlyPaymentHistory) {
        if (item.uiState === 'collapsed') {
            item.uiState = 'extended';
        } else {
            item.uiState = 'collapsed';
        }
    }

    fullReimbursement(e: any) {
        this.fullReimbursementActive = true;
        this.backUpContracts = this.contracts;
        this.contracts = this.contracts.filter(item => item.state === 'extended');
        this.contracts[0].state = 'ready';
        this.pageStep = 2;

        this.contractChildComponents.forEach(child => {
            if (e.contract.contract_id === child.contract.contract_id) {
                child.paymentPhase = true;
            }
        });
        this.fullReimbursementData = e.data;
    }
}

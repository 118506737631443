<div class="dialog-container">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
        <div class="icon" [ngClass]="data.icon"></div>

        <p class="text">{{ data.text }}</p>

        <p class="sub-text">{{ data.subtext }}</p>
    </mat-dialog-content>

    <mat-dialog-actions>
        @if (data.type !== 'success') {
            <button class="mobilize-btn btn-orange" (click)="close()">inapoi</button>
        } @else {
            <button class="mobilize-btn btn-orange" (click)="close('login')">Login</button>
        }
    </mat-dialog-actions>

    <p class="info">{{ data.infoText }}</p>
</div>

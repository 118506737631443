import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private _sharedData: WritableSignal<any> = signal(null);

    setData(data: any) {
        this._sharedData.set(data);
    }

    get getData() {
        return this._sharedData
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { USER_MENU } from '../../constants/user-menu';
import { NgClass } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { UserService } from '../../services/user.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'mobilize-account-functions',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgClass,
        RouterLink,
        RouterOutlet,
        RouterLinkActive
    ],
    templateUrl: './account-functions.component.html',
    styleUrl: './account-functions.component.scss'
})
export class AccountFunctionsComponent implements OnInit {

    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private _user = inject(UserService);
    private _kc = inject(KeycloakService);

    isChildRouteActive = false;
    currentChild: null | string | undefined = null;
    role = 'User';
    menu = USER_MENU;

    async ngOnInit() {
        if (this._kc.isLoggedIn()) {
            const token = await this._kc.getToken();
            if (this.parseToken(token) !== 'PF') {
                this.menu = this.menu.filter(item => !item.route.includes('contact-preferences'))
            }
        }
        if (this._kc.isUserInRole('eCare Admin')) {
            this.role = 'eCare Admin';
        }
        if (this._kc.isUserInRole('Admin')) {
            this.role = 'Admin';
        }
        if (this._kc.isUserInRole('User') && !this._kc.isUserInRole('Admin')) {
            this.role = 'User';
        }

        this.checkActiveChildRoute();

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                this.checkActiveChildRoute();
                // Check if there are any active child routes
                this.isChildRouteActive = this._route.children.length > 0;
                const childRoute = this._route.firstChild; // Get the first activated child route
                if (childRoute) {
                    // Get the full route URL or specific parameters
                    this.currentChild = childRoute.snapshot.url.map(segment => segment.path).join('/');
                } else {
                    this.currentChild = null;
                }
            });
    }

    private checkActiveChildRoute() {
        const childRoute = this._route.firstChild; // Access the first active child route
        this.currentChild = childRoute?.snapshot.url.map(segment => segment.path).join('/');
        this.isChildRouteActive = !!childRoute; // Check if it's not null
    }

    private parseToken(token: string) {
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));

        return tokenPayload['partner_type'];
    }

    get pageTitle(): string {
        let page = '';

        switch (this.currentChild) {
            case 'change-password':
                page = 'Schimba parola'
                break;
            case 'change-email':
                page = 'Schimba email (username)'
                break;
            case 'user-data':
                page = 'Date utilizator'
                break;
            case 'associated-users':
                page = 'Utilizatori asociati'
                break;
            case 'contact-preferences':
                page = 'Preferinte de comunicare'
                break;
        }

        return page;
    }
}

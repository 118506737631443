<div class="filter-wrapper__title">Filtreaza</div>

@if (filters) {
    <form [formGroup]="filterForm">
        <div class="form-group">
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Rol</mat-label>
                    <mat-select formControlName="role">
                        <mat-option value="eCare Admin">
                            eCare Admin
                        </mat-option>
                        <mat-option value="Admin">
                            Admin
                        </mat-option>
                        <mat-option value="User">
                            User
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option value="active">Activ</mat-option>
                        <mat-option value="inactive">Inactiv</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-control">
                <mat-form-field class="example-full-width">
                    <mat-label>Partener</mat-label>
                    <input type="text"
                           placeholder="Alege un partener"
                           aria-label="Partener"
                           matInput
                           formControlName="partner"
                           [matAutocomplete]="partners">
                    <mat-autocomplete #partners="matAutocomplete" [displayWith]="getPartnerName.bind(this)">
                        <mat-option
                            *ngFor="let partner of filteredPartners | async"
                            [value]="partner.partner_id">
                            {{ partner.partner_name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </form>
}

<div class="filter-wrapper__actions">
    <button class="mobilize-btn btn-orange" (click)="applyFilters()" [mobilizeButtonLoader]="processing"
            originalContent="Aplica">Aplica
    </button>
    <button class="mobilize-btn btn-clear" (click)="closeFilter()">Cancel
    </button>

    @if (filterActive) {
        <button class="mobilize-btn btn-clear right-stick" (click)="resetFilter()">Reseteaza
        </button>
    }
</div>

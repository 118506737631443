import { Component, inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from '@angular/material/dialog';
import { RequestService } from '../../../services/request.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'mobilize-escalate-users-modal',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatLabel,
        MatFormField,
        MatDialogActions,
        MatSelect,
        MatOption,
        FormsModule,
        MatProgressBar
    ],
    templateUrl: './escalate-users-modal.component.html',
    styleUrl: './escalate-users-modal.component.scss'
})
export class EscalateUsersModalComponent implements OnInit {

    private _dialogRef = inject(MatDialogRef<EscalateUsersModalComponent>);
    private _request = inject(RequestService);

    data = inject(MAT_DIALOG_DATA);
    users: any[] = []
    selectedUser!: any;
    usersLoading = true;

    ngOnInit() {
        this._request.getAdmins().subscribe(rsp => {
            this.users = rsp;
            this.usersLoading = false;
        });
    }

    closeModal() {
        this._dialogRef.close(false);
    }

    escalate() {
        const selectedUser = this.users.filter(user => user.id === this.selectedUser)
        const name = selectedUser[0].firstName + ' ' + selectedUser[0].lastName;
        this._dialogRef.close({escalate: true, uid: this.selectedUser, name});
    }
}

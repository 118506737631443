import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SocketEvent {

    static DISCONNECTED = SocketEvent.newEvent('disconnected');
    static CONNECT = SocketEvent.newEvent('connect');
    static SIGNATURE_COMPLETE = SocketEvent.newEvent('signature-done');

    private _value: string | undefined;

    static newEvent(value: string) {
        const obj: SocketEvent = new SocketEvent();
        obj.value = value;
        return obj;
    }

    constructor() {
    }

    toString = () => {
        return this._value;
    };

    set value(value: string) {
        this._value = value;
    }
}


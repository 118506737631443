export const registerMessages = [
    {
        type: 'email-error',
        title: 'Te rugam sa folosesti alta adresa de email',
        text: 'Aceasta adresa de email este deja asociata unui cont de utilizator asociat unui alt partener.',
        subtext: 'O adresa de email poate fi utilizata pentru un cont asociat unui singur partener.',
        infoText: 'Daca ai nevoie de ajutor contacteaza-ne la support@myrci.ro',
        icon: 'icon-mobilize-close error-modal',
        code: 703
    },
    {
        type: 'existing-contract',
        title: 'Nu poti crea un cont nou pentru acest contract',
        text: 'Acest contract este deja asociat unui cont de utilizator.',
        subtext: 'Administratorul acestui cont te poate autoriza in lista de utilizatori asociati ai partenerului care detine acest contract.',
        infoText: 'Daca ai nevoie de ajutor contacteaza-ne la support@myrci.ro',
        icon: 'icon-mobilize-close error-modal',
        code: 704
    },
    {
        type: 'existing-user',
        title: 'Cont inregistrat',
        text: 'Exista deja un cont cu aceasta adresa de email.',
        subtext: 'Administratorul acestui cont te poate autoriza in lista de utilizatori asociati ai partenerului care detine acest contract.',
        infoText: 'Daca ai nevoie de ajutor contacteaza-ne la support@myrci.ro',
        icon: 'icon-mobilize-close error-modal',
        code: 707
    },
    {
        type: 'wrong-data',
        title: 'Verifica numarul contractului si seria de sasiu',
        text: 'Contractul sau seria de sasiu nu sunt corecte',
        subtext: 'Asigura-te ca numarul contractului si seria de sasiu sunt corecte. Verifica documentele contractuale.',
        infoText: 'Daca ai nevoie de ajutor contacteaza-ne la support@myrci.ro',
        icon: 'icon-mobilize-close error-modal',
        code: 702
    },
    {
        type: 'success',
        title: 'Succes',
        text: 'Felicitari! Contul tau a fost creat cu success.',
        subtext: 'Poti sa accesezi contractele tale si sa efectuezi singur, din contul tau, orice operatiune.',
        icon: 'icon-mobilize-check-mark success-modal',
        infoText: '',
        code: 200
    }
]

<mobilize-page-header title="Documente utile" [reverse]="true"></mobilize-page-header>

<div class="tabs-wrapper">
    <div class="tabs-header">
        @if (!impersonatedAccount) {
            <div class="tabs-header__tab selected"><span>Documente generate</span></div>
        }
        <div class="tabs-header__tab" routerLink="/generate-document"><span>Genereaza document</span></div>
    </div>
</div>

<mobilize-data-filter (onFilterExec)="execFilter($event)"
                      [processing]="filterProcessing"
                      [canExport]="false"
                      [filterOptions]="filterOptions"
                      page="contracts"
></mobilize-data-filter>

<div class="documents-wrapper">
    @for (document of documents; track document) {
        <mobilize-document-box [document]="document"></mobilize-document-box>
    } @empty {
        <div class="mobilize-card small-card text-center">
            Nu exista documente generate
        </div>
    }
</div>

<mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit"
                     [pagesArray]="pagesArray" (onPageChange)="onPageSelect($event)"></mobilize-pagination>

import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, mergeMap, tap } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { tokenInterceptor } from './token.interceptor';

const env = environment;

export const apiInterceptor: HttpInterceptorFn = (req, next) => {

    const _kc = inject(KeycloakService);
    const _storage = inject(StorageService);
    const _router = inject(Router);

    if (req.url.indexOf('auth/realms') === -1 && req.url.indexOf(':19000/') === -1) {
        req = req.clone({
            url: env.apiUrl + req.url
        });
        if (_storage.impersonatedSessionToken) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${_storage.impersonatedSessionToken}`
                }
            });
            return next(req).pipe(
                tap({
                    next: () => null,
                    // error: (error: HttpErrorResponse) => {
                    //     if (error.status === 401) {
                    //         _router.navigateByUrl('/dashboard')
                    //     }
                    // }
                })
            );
        } else {
            return from(_kc.getToken()).pipe(
                mergeMap(token => {
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    return next(req).pipe(
                        tap({
                            next: () => null,
                            error: (error: HttpErrorResponse) => {
                                if (error.status === 401) {
                                    _router.navigateByUrl('/dashboard')
                                }
                                if (error.status === 403) {
                                    _router.navigateByUrl('/error')
                                }
                            }
                        })
                    );
                }),
                // catchError(err => {
                //     if (err.status === 401) {
                //         return from(keycloak.updateToken(30)).pipe(
                //             switchMap(() => tokenInterceptor(req, next))
                //         );
                //     }
                //     throw err;
                // })
            );
        }

    } else {
        return from(_kc.getToken()).pipe(
            mergeMap(token => {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });

                return next(req).pipe(
                    tap({
                        next: () => {},
                        error: (error: HttpErrorResponse) => {
                            if (error.status === 401) {
                                _router.navigateByUrl('/dashboard')
                            }
                        }
                    })
                );
            })
        );
    }
}

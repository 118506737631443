import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'mobilize-alert',
	standalone: true,
	imports: [
		NgClass
	],
	templateUrl: './alert.component.html',
	styleUrl: './alert.component.scss'
})
export class AlertComponent {

	@Input() type = '';
	@Input() message = '';
	@Input() icon = '';
    @Output() close = new EventEmitter<void>();

	onClose() {
        this.close.emit();
	}

	get alertClass() {
		let alertClass = '';

		switch (this.type) {
            case 'danger':
                alertClass = 'alert-danger';
                break;

            case 'warning':
                alertClass = 'alert-warning';
                break;

            case 'top-success':
                alertClass = 'top-success';
                break;

            case 'top-error':
                alertClass = 'top-error';
                break;
		}

		return alertClass;
	}
}

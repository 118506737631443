import { Component, inject, OnInit } from '@angular/core';
import { ScheduleService } from '../../../services/schedule.service';
import { DatePipe, NgClass } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { BaseComponent } from '../../../components/base.component';
import { Schedule } from '../../../interfaces/schedule';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { DataFilterComponent } from '../../../components/data-filter/data-filter.component';
import { DocumentsService } from '../../../services/documents.service';
import { catchError } from 'rxjs/operators';
import { finalize, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { saveAs } from 'file-saver';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../../../services/request.service';
import { GenerateDocumentService } from '../../../services/generate-document.service';
import { MiscService } from '../../../services/misc.service';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-current-due-dates',
    standalone: true,
    imports: [
        DatePipe,
        MatProgressBar,
        NgClass,
        DataFilterComponent,
        MatProgressSpinner,
        PaginationComponent
    ],
    providers: [
        provideMomentDateAdapter(MY_FORMATS),
        DatePipe
    ],
    templateUrl: './current-due-dates.component.html',
    styleUrl: './current-due-dates.component.scss'
})
export class CurrentDueDatesComponent extends BaseComponent implements OnInit {

    private _schedule = inject(ScheduleService);
    private _documents = inject(DocumentsService);
    private _datePipe = inject(DatePipe);
    private _snackbar = inject(MatSnackBar);
    private _misc = inject(MiscService);
    private _router = inject(Router)
    private _route = inject(ActivatedRoute);
    private _generateDocument = inject(GenerateDocumentService);

    dataReady = false;
    processedData: Schedule[] = [];
    startOfMonth!: any;
    endOfMonth!: any;
    filterProcessing = false;
    documentLoading = false;
    pagesArray!: any;
    unpaidAmount = 0;
    currentPage!: any;
    totalPages!: any;
    limit = 10;
    filterArgs!: any;
    contractsArrayIds: any[] = [];

    ngOnInit() {
        const currentDate = new Date();
        this.startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        this.endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    export() {
        const preparedJson: any[] = []
        this.processedData.forEach((report: any) => {
            preparedJson.push({})
        });

        if (!this.filterArgs) {
            this.filterArgs = {
                startDate: this.startOfMonth,
                endDate: this.endOfMonth,
                contracts: this.contractsArrayIds
            }
        }
        this._generateDocument.excel({
            body: preparedJson,
            filter: this.filterArgs,
            section: '478a542f-75e7-42e9-9c4e-b91d023154cd'
        })
            .subscribe(rsp => {
                const date = this._datePipe.transform(new Date(), 'dd/MM/YYYY HH:mm')
                saveAs(rsp, `${date}-scadente-la-zi.xlsx`);
            }, (err: any) => {
                console.log(err.status);
                if (err.status === 404) {
                    this._snackbar.open('Nu s-a putut genera documentul', 'Ok', {
                        panelClass: 'error-snack'
                    })
                }

            });
    }

    getSchedules(payload?: any) {
        this.dataReady = false;
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;

            this._schedule.getCurrentSchedules({
                ...payload,
                page: this.currentPage,
                limit: this.limit
            }).subscribe((schedules) => {
                this.processedData = this._misc.processData(schedules.data);
                this.dataReady = true;
                this.filterProcessing = false;
                this.totalPages = schedules.totalPages;
                this.currentPage = schedules.currentPage;
                this.unpaidAmount = schedules.unpaidAmount;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            });
        });
    }

    contractIds(e: any) {
        this.getSchedules({startDate: this.startOfMonth, endDate: this.endOfMonth, contracts: e});
        this.contractsArrayIds = e;
    }

    execFilter(e: any) {
        this.dataReady = false;
        this.filterProcessing = true;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.getSchedules(e);
        });
    }

    scheduleStatus(schedule: Schedule): string {
        if (new Date(schedule.invoice?.data_scadenta) < new Date() && parseFloat(schedule.invoice?.rest_amount) > 0) {
            return '<span class="invoice-status status-outstanding">restant</span>'
        }

        if (parseFloat(schedule.invoice?.rest_amount) === 0) {
            return '<span class="invoice-status status-cashed">incasat</span>'
        } else {
            return '<span class="invoice-status status-not-cashed">neachitat</span>'
        }
    }

    shouldShowPaymentUrl(schedule: Schedule): boolean {
        const status = this.scheduleStatusText(schedule);
        return status === 'restant' || status === 'neachitat, neincasat';
    }

    scheduleStatusText(schedule: Schedule) {
        if (new Date(schedule.invoice?.due_date) < new Date() && parseFloat(schedule.invoice?.rest_amount) > 0) {
            return 'restant'
        }

        if (parseFloat(schedule.invoice?.rest_amount) === 0) {
            return 'incasat'
        } else {
            return 'neachitat, neincasat'
        }
    }

    toggleRow(row: any) {
        row.opened = !row.opened;
    }

    downloadInvoice(schedule: Schedule) {
        this.documentLoading = true;
        this._documents.getDocuments({
            contract: schedule.contract.contract_number,
            invoiceNumber: schedule.invoice.prefix + schedule.invoice.invoice_number
        })
            .pipe(
                finalize(() => {
                    this.documentLoading = false;
                }),
                catchError(((err: any) => {
                    console.log(err)
                    this._snackbar.open('Documentul nu a fost gasit', 'Ok', {
                        panelClass: 'error-snack'
                    })
                    return of(false);
                }))
            )
            .subscribe(response => {
                const base64String = response.fileData;
                if (base64String) {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'application/pdf'});

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = response.fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            })
    }
}

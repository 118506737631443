import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { AlertComponent } from './alert.component';

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	private defaultViewContainerRef!: ViewContainerRef;

	setDefaultViewContainerRef(vcr: ViewContainerRef) {
		this.defaultViewContainerRef = vcr;
	}

	showAlert(type: string, message: string, icon?: string, target?: ViewContainerRef, autoCloseAfter?: number) {
		const viewContainerRef = target || this.defaultViewContainerRef
		//viewContainerRef.clear();

		const componentRef: ComponentRef<AlertComponent> = viewContainerRef.createComponent(AlertComponent);

		componentRef.instance.type = type;
		componentRef.instance.message = message;
        if (icon) {
            componentRef.instance.icon = icon;
        }

        componentRef.instance.close.subscribe(() => {
            viewContainerRef.clear();
        });

        if (autoCloseAfter && autoCloseAfter > 0) {
            setTimeout(() => {
                viewContainerRef.clear();
            }, autoCloseAfter * 1000); // Convert seconds to milliseconds
        }
	}

    closeAlert(): void {
        if (this.defaultViewContainerRef) {
            this.defaultViewContainerRef.clear();
        }
    }
}

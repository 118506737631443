<ng-template #targetAlertContainer></ng-template>
@for (user of users; track user) {
    <div class="user-card" [ngClass]="{'opened': user.opened}">
        <div class="header" (click)="toggleUserOpen(user)">
            <div class="left">
                <span class="icon-mobilize-user"></span> {{ user.email }}
            </div>

            <div class="right">
                <span class="user-role" [ngClass]="user.status">Utilizator asociat</span>
                <div class="toggle-user-details" [ngClass]="{'opened': user.opened}">
                    <span class="icon-mobilize-chevron-down"></span>
                </div>
            </div>
        </div>

        <div class="content" [@toggleOpen]="user.opened ? 'opened' : 'closed'">
            @if (user.status === 'active') {
                <div class="user-row">
                    <span>Nume: </span> {{ user.full_name }}
                </div>
            }
            <div class="user-row" [ngClass]="user.status">
                <span>Status: </span>
                @switch (user.status) {
                    @case ('active') {
                        activ
                    }
                    @case ('pending') {
                        invitatie trimisa
                    }
                }
            </div>

            <div class="user-actions">
                @switch (user.status) {
                    @case ('active') {
                        <button class="mobilize-btn btn-orange" (click)="removeUser(user)">sterge user</button>
                        <button class="mobilize-btn btn-clear" (click)="passAdminRights(user)">transfera rolul de Admin
                        </button>
                    }
                    @case ('pending') {
                        <button class="mobilize-btn btn-orange" [mobilizeButtonLoader]="isProcessing"
                                originalContent="retrimite invitatia" (click)="resendInvite(user)">retrimite invitatia
                        </button>
                        <button class="mobilize-btn btn-clear" (click)="cancelInvite(user)">anuleaza invitatia</button>
                    }
                }
            </div>
        </div>
    </div>
}

<div class="add-user-wrapper mobilize-card">
    <div class="title">Adauga utilizator</div>

    <form [formGroup]="form">
        <div class="form-group">
            <div class="form-control">
                <mat-form-field>
                    <input matInput formControlName="email" type="text" [placeholder]="'adresa de email'"
                           autocomplete="new-password">
                </mat-form-field>
            </div>
        </div>

        <re-captcha
            #captchaRef="reCaptcha"
            (resolved)="resolved($event)"
        ></re-captcha>
    </form>

    <button class="mobilize-btn btn-orange" (click)="sendInvitation()" [mobilizeButtonLoader]="isProcessing"
            originalContent="Trimite link de activare">Trimite link de activare
    </button>
</div>

import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'mobilize-confirm-modal',
    standalone: true,
    imports: [
        FormsModule
    ],
    templateUrl: './confirm-modal.component.html',
    styleUrl: './confirm-modal.component.scss'
})
export class ConfirmModalComponent {

    private dialogRef = inject(MatDialogRef<ConfirmModalComponent>);
    public data = inject(MAT_DIALOG_DATA)


    close(action: any) {
        this.dialogRef.close(action);
    }
}

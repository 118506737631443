import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'mobilize-installments-slider',
    standalone: true,
    imports: [
        NgStyle,
        NgClass
    ],
    templateUrl: './installments-slider.component.html',
    styleUrl: './installments-slider.component.scss'
})
export class InstallmentsSliderComponent {

    @Input() payedInstallments!: number;
    @Input() showInstallments!: boolean;
    @Input() maxInstallments!: number;
    @Input() type!: string;

    distance!: number;

    get progress() {
        const percentage = (this.payedInstallments / this.maxInstallments) * 100;
        this.distance = Math.min(Math.max(percentage, 0), 100);
        return `${Math.min(Math.max(percentage, 0), 100)}%`;
    }

    getLeftPosition(distance: number): string {
        if (distance <= 7) {
            return '-20px';
        } else {
            return `calc(${distance}% - 90px)`;
        }
    }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {

    private _http = inject(HttpClient);

    getCurrentSchedules(payload: any): Observable<any> {
        return this._http.post('schedule/current-list', payload)
    }

    getFutureSchedules(payload: any): Observable<any> {
        return this._http.post('schedule/future-list', payload)
    }
}

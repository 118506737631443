<div class="mobilize-card small-card filter-card">
    <h5 (click)="toggleFilterContent()"><span class="icon-mobilize-filter"></span> Filtreaza <span [@rotateChevron]="isFilterContentVisible" class="icon-mobilize-chevron-down"></span></h5>

    <div class="filter-content" [@slideDown]="isFilterContentVisible">
        <form [formGroup]="filterForm">
            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Alege un interval</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input formControlName="startDate" matStartDate placeholder="Data start">
                            <input formControlName="endDate" matEndDate placeholder="Data sfarsit">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="form-control">

                    <div class="form-control">
                        <mat-form-field>
                            <mat-label>Contracte</mat-label>
                            <mat-select formControlName="contracts" multiple (selectionChange)="resetToAllContracts($event)">
                                <mat-option [value]="'all'">Toate contractele</mat-option>
                                @for (contract of contracts; track contract) {
                                    <mat-option [value]="contract.contract_id">
                                        <div>Contract {{contract.contract_number}}</div>
                                        <div>{{ contract.contract_objects[0].vehicle_brand }}
                                            {{contract.contract_objects[0].vehicle_model}},
                                            {{contract.contract_objects[0].colour}},
                                            serie sasiu: {{contract.contract_objects[0].chassis_number}}
                                        </div>
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="filter-bottom">
        <span class="selected-filter">interval: {{ currentFilter }}</span>
        <span class="export-results" (click)="export()"><i class="icon-mobilize-export"></i> Exporta </span>
    </div>

    <div class="filter-actions" [@buttonAnimation]="isFilterContentVisible ? 'visible' : 'hidden'">
        <button class="mobilize-btn btn-orange" (click)="execFilter()" [mobilizeButtonLoader]="processing"
                originalContent="filtreaza">filtreaza</button>
    </div>
</div>

<mobilize-page-header [title]="pageTitle || 'Setari cont'" [reverse]="true"></mobilize-page-header>

@if (!isChildRouteActive) {
    <div class="account-functions-wrapper">
        @for (menuItem of menu; track menuItem) {
            @if (menuItem.canView.includes(role)) {
                <div class="account-functions-wrapper__row card-row" [routerLink]="menuItem.route">
                    <div class="menu-item"><span [ngClass]="menuItem.icon"></span> {{ menuItem.title }}</div>
                    <span class="icon-mobilize-chevron-right"></span>
                </div>
            }
        }
    </div>
}

<router-outlet></router-outlet>

<mobilize-page-header title="Raport dealer {{partnerName}}" [reverse]="true"></mobilize-page-header>

<div class="tabs-wrapper">
    <div class="tabs-header">
        <div class="tabs-header__tab" routerLinkActive="selected" routerLink="/upcoming-due-dates/current"><span>Situatia la zi</span></div>
        <div class="tabs-header__tab" routerLinkActive="selected" routerLink="/upcoming-due-dates/future"><span>Scadente viitoare</span></div>
    </div>
</div>

<router-outlet></router-outlet>

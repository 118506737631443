<div class="page-header">
    <div [ngClass]="{'reverse': reverse}">
        <div class="title" [ngClass]="{'reverse': reverse}">{{ title }}</div>
        @if (userLoaded) {
            <div class="subtitle" [ngClass]="{'reverse': reverse}">{{ user.full_name }}</div>
        }
    </div>

    @if (button) {
        <button class="mobilize-btn" [ngClass]="button.className" (click)="buttonAction()">{{ button.label }}</button>
    }
</div>

import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { BaseComponent } from '../../components/base.component';
import { InvoiceService } from '../../services/invoice.service';
import { DatePipe, DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Invoice } from '../../interfaces/invoice';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatProgressBar } from '@angular/material/progress-bar';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { finalize, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentsService } from '../../services/documents.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataFilterComponent } from '../../components/data-filter/data-filter.component';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MiscService } from '../../services/misc.service';
import { GenerateDocumentService } from '../../services/generate-document.service';
import { saveAs } from 'file-saver';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-bills',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        DatePipe,
        NgForOf,
        NgClass,
        MatProgressBar,
        PaginationComponent,
        MatProgressSpinner,
        DataFilterComponent,
        RouterLink
    ],
    providers: [DecimalPipe, DatePipe,
        provideMomentDateAdapter(MY_FORMATS),],
    templateUrl: './invoices.component.html',
    styleUrl: './invoices.component.scss'
})
export class InvoicesComponent extends BaseComponent implements OnInit {

    private _invoice = inject(InvoiceService);
    private _generateDocument = inject(GenerateDocumentService);
    private decimalPipe = inject(DecimalPipe);
    private _documents = inject(DocumentsService);
    private _route = inject(ActivatedRoute);
    private _router = inject(Router);
    private _snackbar = inject(MatSnackBar);
    private _misc = inject(MiscService);
    private _datePipe = inject(DatePipe);

    processedData: any[] = [];
    dataReady = false;
    currentPage = 1;
    totalPages!: number;
    limit = 10;
    pagesArray: any;
    totalAmount!: number;
    totalAmountReady = false;
    filterProcessing = false;
    documentLoading = false;
    filterOptions = ['range'];
    startOfMonth: any;
    endOfMonth: any;
    filterArgs: any;
    noInvoices = false;
    currentDate: any;

    ngOnInit() {
        this.currentDate = new Date();
        this.startOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.endOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    }

    downloadInvoice(invoice: any) {
        this.documentLoading = true;
        this._documents.getDocuments({
            contract: invoice.contract.contract_number,
            invoiceNumber: invoice.prefix + invoice.invoice_number
        })
            .pipe(
                finalize(() => {
                    this.documentLoading = false;
                }),
                catchError(((err: any) => {
                    this._snackbar.open('Documentul nu a fost gasit', 'Ok', {
                        panelClass: 'error-snack'
                    })
                    return of(false);
                }))
            )
            .subscribe(response => {
                const base64String = response.fileData;
                if (base64String) {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'application/pdf'});

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = response.fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            })
    }

    loadInvoices(payload?: any) {
        this.dataReady = false;
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? + params['page'] : 1;
            this.limit = params['limit'] ? + params['limit'] : this.limit;

            this._invoice.getInvoices({
                ...payload,
                page: this.currentPage,
                limit: this.limit,
                startDate: payload?.startDate || this.startOfMonth,
                endDate: payload?.endDate || this.endOfMonth
            }).subscribe((rsp: {
                data: any[];
                currentPage: any;
                totalPages: number,
                totalAmount: any;
            }) => {
                this.processData(rsp.data);
                this.filterProcessing = false;
                this.currentPage = parseInt(rsp.currentPage, 10);
                this.totalPages = rsp.totalPages;
                this.totalAmount = rsp.totalAmount;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
                this.totalAmountReady = true;
            });
        });
    }

    shouldShowPaymentUrl(invoice: any): boolean {
        const status = this.invoiceStatusText(invoice);
        return status === 'restant' || status === 'neachitat, neincasat';
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    formatNumber(value: number): string {
        return <string>this.decimalPipe.transform(value, '1.2-2'); // Format the number
    }

    processData(rsp: any[]) {
        const dataGroupedByYear = new Map<number, any[]>();

        rsp.forEach((row: any) => {
            const year = new Date(row.invoice_date).getFullYear();
            if (!dataGroupedByYear.has(year)) {
                dataGroupedByYear.set(year, []);
            }
            dataGroupedByYear.get(year)?.push(row);
        });

        // Flatten the grouped data with a year "header"
        this.processedData = [];
        dataGroupedByYear.forEach((rows, year) => {
            this.processedData.push({year} as unknown as any);
            this.processedData.push(...rows);
        });
        if (this.processedData.length === 0) {
            this.noInvoices = true;
        }

        this.dataReady = true;
    }

    invoiceStatusInText(invoice: Invoice) {
        if (new Date(invoice.data_scadenta) < new Date() && parseFloat(invoice.rest_amount) > 0) {
            return 'restant'
        }

        if (parseFloat(invoice.rest_amount) === 0) {
            return 'incasat'
        } else {
            return 'neachitat, neincasat'
        }
    }

    toggleRow(row: any) {
        row.opened = !row.opened;
    }

    execFilter(e: any) {
        delete e.contracts;
        delete e.query;
        delete e.state;

        this.dataReady = false;
        this.filterProcessing = true;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.loadInvoices(e);
        });
    }

    export() {
        const preparedJson: any[] = []
        this.processedData.forEach(() => {
            preparedJson.push({})
        });

        if (!this.filterArgs) {
            this.filterArgs = {
                startDate: this.startOfMonth,
                endDate: this.endOfMonth
            }
        }
        this._generateDocument.excel({
            body: preparedJson,
            filter: this.filterArgs,
            section: '45e9b68b-cec0-4ee0-bacd-066cb861064d'
        })
            .pipe(
                catchError((err: any) => {
                    if (err.status === 404) {
                        this._snackbar.open('Nu s-a putut genera documentul', 'Ok', {
                            panelClass: 'error-snack'
                        })
                    }
                    return of();
                })
            )
            .subscribe(rsp => {
                const date = this._datePipe.transform(new Date(), 'dd/MM/YYYY HH:mm')
                saveAs(rsp, `${date}-facturi.xlsx`);
            });
    }

    contractIds(e: any) {
        if (Object.keys(e).length === 0) {
            this.loadInvoices();
        }
    }
}

<mobilize-page-header title="Asigurari" [reverse]="true"></mobilize-page-header>

<mobilize-data-filter (onFilterExec)="execFilter($event)"
                      [processing]="filterProcessing"
                      [canExport]="false"
                      [filterOptions]="filterOptions"
                      page="insurance"
                      (onContractsLoaded)="contractIds($event)"></mobilize-data-filter>

<div class="insurance-wrapper">
    @for (ins of insuranceList; track ins) {
        <mobilize-insurance-box [insurance]="ins"></mobilize-insurance-box>
    }

    @if (dataReady && insuranceList.length) {
        <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit"
                             [pagesArray]="pagesArray" (onPageChange)="onPageSelect($event)"></mobilize-pagination>
    } @else {
        <div class="mobilize-card small-card text-center">
            Nu exista asigurari incarcate
        </div>
    }
</div>

import { Component } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
    selector: 'mobilize-requests',
    standalone: true,
    imports: [
        PageHeaderComponent,
        RouterLink,
        RouterOutlet,
        RouterLinkActive
    ],
    templateUrl: './requests.component.html',
    styleUrl: './requests.component.scss'
})
export class RequestsComponent {

}

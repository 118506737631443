<mobilize-page-header title="Plata anticipata" [reverse]="true"></mobilize-page-header>

<div class="mobilize-card no-bg">
    <div class="tabs-wrapper">
        <div class="tabs-header">
            <div class="tabs-header__tab" [ngClass]="{'selected': selectedTab === 'new-payment'}"
                 (click)="selectedTab = 'new-payment'"><span>Plata noua</span></div>
            <!--            <div class="tabs-header__tab" [ngClass]="{'selected': selectedTab === 'history'}"-->
            <!--                 (click)="selectedTab = 'history'"><span>Istoric</span></div>-->
        </div>

        <div class="tabs-content">
            <div class="tabs-content__tab new-payment-tab">
                <div class="payment-type">
                    <div class="title">{{ pageStep === 1 ? 'Alege tipul platii' : 'Ai ales tipul platii' }}</div>

                    <div class="contracts-list">
                        @for (contract of contracts; track contract; let first = $first) {
                            <mobilize-early-repayment-contract [contract]="contract" (onPay)="finalizePayment($event)"
                                                               [firstContract]="first"
                                                               [selectedContract]="data"
                                                               (onFullReimbursement)="fullReimbursement($event)"
                                                               (notifyParent)="onChildEvent($event)"></mobilize-early-repayment-contract>
                        }
                    </div>

                    @if (!showFinalConfirmation) {
                        <div class="title" *ngIf="pageStep === 2">Plateste</div>
                        <div class="payment-options" *ngIf="pageStep === 2">
                            <div class="payment-options__item" [ngClass]="{'active': selectedPaymentMethod === 1}"
                                 (click)="selectPay(1);selectedPaymentMethod = 1">
                                <span><span></span></span> Platesc online, cu cardul
                            </div>
                            <div class="payment-options__item" [ngClass]="{'active': selectedPaymentMethod === 2}"
                                 (click)="selectPay(2);selectedPaymentMethod = 2">
                                <span><span></span></span> Platesc cu OP
                            </div>
                        </div>

                        <form #paymentForm action="https://www.activare3dsecure.ro/iteste3d/cgi-bin/" method="POST">
                            @for (inp of inputsArray; track inp) {
                                <input type="hidden" name="{{ inp.name }}" value="{{ inp.value }}"/>
                            }
                        </form>

                        <div class="payment-info" *ngIf="pageStep === 2 && selectedPaymentMethod === 1">
                            Vei fi transferat in pagina procesatorului de plati
                        </div>

                        <ng-template #targetAlertContainer></ng-template>

                        <div class="payment-actions" *ngIf="pageStep === 2">
                            <button class="mobilize-btn btn-orange"
                                    (click)="pay()">{{ selectedPaymentMethod === 1 ? 'plateste securizat' : 'genereaza proforma' }}
                            </button>
                            <button class="mobilize-btn btn-clear" (click)="back()">inapoi</button>
                        </div>
                    } @else {
                        <div class="final-confirmation">
                            <div class="title"><span class="icon-mobilize-check-mark"></span> Factura proforma a fost
                                generata
                            </div>
                            <p>Trebuie sa faci plata in contul de pe factura in urmatoarele 48 de ore.</p>
                            <button class="mobilize-btn btn-orange">descarca proforma</button>

                            <div class="payment-details">
                                <div class="title">Detalii pentru OP:</div>
                                <p>Suma: <span [innerHTML]="formatAmount(15000, contract.currency_code)"></span></p>
                                <p>Beneficiar: <span>RCI Leasing SA</span></p>
                                <p>Detalii plata: <span>356DF578</span></p>
                            </div>
                        </div>

                        <button class="mobilize-btn btn-orange">Adauga o plata noua</button>
                    }
                </div>
            </div>
            <div class="tabs-content__tab history-tab" [hidden]="selectedTab !== 'history'">
                @for (item of history; track item) {
                    <div class="history-item" [ngClass]="item.uiState">
                        <div class="title">
                            {{ item.title }}
                            <div class="item-extend" (click)="extendItem(item)"><span
                                class="icon-mobilize-chevron-down"></span></div>
                        </div>

                        <div class="history-item__row">
                            data initierii: <span>{{ item.doc }}</span>
                        </div>
                        <div class="history-item__row">
                            stare cerere: <span>{{ item.state }}</span> <span class="state-bullet"
                                                                              [ngClass]="historyStateClass(item.stateBullet)"></span>
                        </div>
                        <div class="history-item__row">
                            suma rambursata anticipat: <span>{{ item.earlyRepaymentAmount }}</span>
                        </div>
                        <div class="history-item__extended-view">
                            <div class="history-item__row">
                                noua rata lunara va fi: <span>{{ item.newInstallmentAmount }}</span>
                            </div>
                            <div class="history-item__row">
                                numarul de rate de achitat: <span>{{ item.newInstallmentsNumber }}</span>
                            </div>
                            <div class="history-item__row">
                                bun finantat: <span>{{ item.financedGood }}</span>
                            </div>
                        </div>

                        <div class="history-item__actions">
                            <button class="mobilize-btn btn-orange">descarca proforma</button>
                            <button class="mobilize-btn btn-clear">anuleaza cererea</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>

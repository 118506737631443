<div class="main-menu-wrapper">
    @for (menuItem of menu; track menuItem; ) {
        @if (menuItem.route === 'logout') {
            <div class="main-menu-item" (click)="logout()">
                <span class="{{ menuItem.icon}}"></span>
                {{ menuItem.title }}
            </div>
        } @else if (menuItem.route === 'submenu') {
            <div class="main-menu-item main-menu-item__submenu" (click)="toggleMenu()">
                <div class="main-menu-item__content">
                    <span class="{{ menuItem.icon}}"></span>
                    {{ menuItem.title }}
                    <span class="icon-mobilize-chevron-down" [@rotateChevron]="isOpen ? 'rotated' : 'default'"></span>
                </div>

                <div [@slideToggle]="isOpen ? 'open' : 'closed'" class="submenu">
                    @for (sub of menuItem.submenu; track sub) {
                        <div [routerLink]="sub.route">{{ sub.name }}</div>
                    }
                </div>
            </div>
        } @else {
            <div class="main-menu-item" [routerLink]="menuItem.route">
                <span class="{{ menuItem.icon}}"></span>
                {{ menuItem.title }}
            </div>
        }
    }

    <div class="logo">
        <img src="assets/images/logo.png" alt="" height="132" width="175">
    </div>
</div>

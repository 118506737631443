import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConsentsService {

    constructor(private _http: HttpClient) {
    }

    getConsents(): Observable<any> {
        return this._http.get('consents/get-consents');
    }
}

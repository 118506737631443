<div class="filter-wrapper__title">Filtreaza</div>

@if (filters) {
    <form [formGroup]="filterForm">
        <div class="form-group">
            <div class="form-control">
                <mat-form-field class="example-full-width">
                    <mat-label>Partener</mat-label>
                    <input type="text"
                           placeholder="Alege un partener"
                           aria-label="Partener"
                           matInput
                           formControlName="partner"
                           [matAutocomplete]="partners">
                    <mat-autocomplete #partners="matAutocomplete" [displayWith]="getPartnerName.bind(this)">
                        <mat-option
                            *ngFor="let partner of filteredPartners | async"
                            [value]="partner.partner_id">
                            {{ partner.partner_name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Contract</mat-label>
                    <input type="text"
                           placeholder="Alege un contract"
                           aria-label="Contract"
                           matInput
                           formControlName="contract"
                           [matAutocomplete]="contracts">
                    <mat-autocomplete #contracts="matAutocomplete" [displayWith]="getContractNumber.bind(this)">
                        <mat-option
                            *ngFor="let contract of filteredContracts | async"
                            [value]="contract.contract_number">
                            {{ contract.contract_number }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        @if (section === '') {
            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Categorie</mat-label>
                        <input type="text"
                               placeholder="Alege o categorie"
                               aria-label="Categorie"
                               matInput
                               formControlName="category"
                               [matAutocomplete]="categories">
                        <mat-autocomplete class="category-selector" #categories="matAutocomplete"
                                          [displayWith]="getCategoryName.bind(this)">
                            <mat-option
                                *ngFor="let category of filteredCategories | async"
                                [value]="category.request_type_uid">
                                {{ category.request_type_name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Alege un interval</mat-label>
                        <mat-date-range-input [rangePicker]="picker" [max]="maxDate" [min]="minDate">
                            <input formControlName="startDate" matStartDate placeholder="Data start">
                            <input formControlName="endDate" matEndDate placeholder="Data sfarsit">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Status</mat-label>
                        <input type="text"
                               placeholder="Alege un status"
                               aria-label="Status"
                               matInput
                               formControlName="status"
                               [matAutocomplete]="statuses">
                        <mat-autocomplete #statuses="matAutocomplete" [displayWith]="getStatusName.bind(this)">
                            <mat-option
                                *ngFor="let status of filteredStatuses | async"
                                [value]="status.bo_status">
                                {{ status.bo_status }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        }
    </form>
}

<div class="filter-wrapper__actions">
    <button class="mobilize-btn btn-orange" (click)="applyFilters()" [mobilizeButtonLoader]="processing"
            originalContent="Aplica">Aplica
    </button>
    <button class="mobilize-btn btn-clear" (click)="closeFilter()">Cancel
    </button>

    @if (filterActive) {
        <button class="mobilize-btn btn-clear right-stick" (click)="resetFilter()">Reseteaza
        </button>
    }
</div>

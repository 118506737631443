import { Component, inject, OnInit } from '@angular/core';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { RequestService } from '../../../services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PadZeroPipe } from '../../../pipes/pad-zero.pipe';
import { DatePipe, NgClass } from '@angular/common';
import { LoadingService } from '../../../services/loading.service';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { RequestsFilterComponent } from '../../components/requests-filter/requests-filter.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { saveAs } from 'file-saver';
import { MiscService } from '../../../services/misc.service';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { BaseComponent } from '../../../components/base.component';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-requests-reports',
    standalone: true,
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    providers: [
        provideMomentDateAdapter(MY_FORMATS),
        DatePipe,
        PadZeroPipe
    ],
    imports: [
        ReactiveTypedFormsModule,
        PageHeaderComponent,
        PadZeroPipe,
        DatePipe,
        NgClass,
        RequestsSearchComponent,
        RequestsFilterComponent,
        PaginationComponent
    ],
    templateUrl: './requests-reports.component.html',
    styleUrl: './requests-reports.component.scss'
})
export class RequestsReportsComponent extends BaseComponent implements OnInit {

    private _request = inject(RequestService);
    private _route = inject(ActivatedRoute);
    private _loader = inject(LoadingService)
    private _router = inject(Router);
    private _misc = inject(MiscService);
    private _datePipe = inject(DatePipe);

    query: string = '';
    dataReady = false;
    currentPage = 1;
    limit = 15;
    reports: any = [];
    totalPages!: number
    pagesArray: any = [];
    counts: any;
    showFilter = false;
    isProcessing = false;
    filterActive = false;
    filtersLoaded = false;
    currentFilters!: any;

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;
            this.loadReports({...this.currentFilters, page: this.currentPage});
        });
    }

    loadReports(payload: any) {
        this._loader.setLoadingState(true);
        this.dataReady = false;

        this._request.getRequestReport({
            ...payload,

            page: this.currentPage,
            limit: this.limit
        }).subscribe((rsp: any) => {
            this.counts = rsp.counts;
            this.reports = rsp.data;
            this.currentPage = parseInt(rsp.currentPage, 10);
            this.totalPages = rsp.totalPages;
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            this.dataReady = true;
            this.isProcessing = false;
            this._loader.setLoadingState(false);
        });
    }

    search(event: any) {
        this.loadReports({query: event})
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this.scrollToTop();
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    reportDetails(report: any) {
        this._router.navigateByUrl(`/backoffice/report-details/${report.client_request_uid}`);
    }

    downloadReport() {
        this._request.downloadReport({filters: this.currentFilters, query: this.query, section: 'c3e5e681-f589-414e-b9b8-b5c47eda78f5'}).subscribe(rsp => {
            const date = this._datePipe.transform(new Date(), 'dd/MM/YYYY HH:mm')
            saveAs(rsp, `${date}-report.xlsx`);
        });
    }

    toggleFilter() {
        if (!this.filtersLoaded) return;
        this.showFilter = !this.showFilter;
    }

    applyFilters(event: any) {
        this._loader.setLoadingState(true);
        this.currentFilters = event;
        this.isProcessing = true;
        this._router.navigate([], {
            relativeTo: this._route
        }).then(() => {
            this.loadReports(event)
        });
    }

    canShowFilters(event: any) {
        this.filtersLoaded = event;
    }
}

<mobilize-page-header title="Scadentar" [reverse]="true"></mobilize-page-header>

<div class="timetable-wrapper">
    @for (contract of contracts; track contract.id) {
        <mobilize-contract
            class="contract-row"
            [section]="'timetable'"
            [contract]="contract">
        </mobilize-contract>
    }
</div>

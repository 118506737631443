import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { map } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

export const roleGuard: CanActivateFn = (route) => {

    const _user = inject(UserService);
    const _kc  = inject(KeycloakService);
    const _router = inject(Router);

    const expectedRoles = route.data['expectedRoles'];

    return _user.userData().pipe(
        map((rsp) => {
            _user.user = rsp;
            if (_kc.isUserInRole('eCare Admin')) {
                _user.role = 'eCare Admin';
                _user.sendSuperAccount(true);
            }
            if (_kc.isUserInRole('Admin')) {
                _user.role = 'Admin';
                _user.sendSuperAccount(false);
            }
            if (_kc.isUserInRole('User')) {
                _user.role = 'User';
                _user.sendSuperAccount(false);
            }

            if (expectedRoles.includes(_user.role)) {
                return true;
            } else {
                switch (_user.role) {
                    case 'eCare Admin':
                        _router.navigateByUrl('backoffice/dashboard');
                        break;

                    case 'Admin':
                    case 'User':
                        _router.navigateByUrl('dashboard');
                        break;

                    default:
                        _router.navigateByUrl('not-allowed');
                        break;

                }

                return false;
            }
        })
    );
};

<mobilize-data-filter (onFilterExec)="execFilter($event)"
                      [processing]="filterProcessing"
                      [canExport]="false"
                      [filterOptions]="filterOptions"
                      page="requests"
                      (onContractsLoaded)="contractIds($event)"></mobilize-data-filter>

<div class="requests-wrapper">
    @for (req of requests; track req) {
        <mobilize-request-box [request]="req"></mobilize-request-box>
    } @empty {
        <div class="mobilize-card small-card text-center">Nu exista solicitari inregistrate</div>
    }
</div>

import { Component, inject, ViewChild, ViewContainerRef } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../../../../components/alert/alert.service';

@Component({
    selector: 'mobilize-agreement-modal',
    standalone: true,
    imports: [
        NgClass,
        FormsModule
    ],
    templateUrl: './agreement-modal.component.html',
    styleUrl: './agreement-modal.component.scss'
})
export class AgreementModalComponent {

    private modalRef = inject(MatDialogRef<AgreementModalComponent>);
    private _alert = inject(AlertService);

    @ViewChild('targetAlertContainer', { read: ViewContainerRef }) targetViewContainerRef!: ViewContainerRef;

    error = false;

    agreements: {name: string; checked: boolean}[] = [
        {
            name: 'sunt de acord...',
            checked: false
        }
    ]
    checkAgreements(e: any, test: number) {

    }

    agreementsChecked(): boolean {
        return this.agreements.every(agreement => agreement.checked);
    }

    close(accept: boolean) {
        if (accept) {
            if (!this.agreementsChecked()) {
                this._alert.showAlert('danger', 'nu se trece daca nu se bifeste!!', 'icon-mobilize-warning', this.targetViewContainerRef)
                return;
            }
        }
        this.modalRef.close(accept);
    }
}

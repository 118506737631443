import {
    Component,
    ComponentRef,
    ElementRef,
    inject,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoRequestComponent } from '../../components/bo-request/bo-request.component';
import { SocketService } from '../../../services/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatLabel, MatOption, MatSelect } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, DatePipe, NgForOf } from '@angular/common';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RequestsFilterComponent } from '../../components/requests-filter/requests-filter.component';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { DataFilterComponent } from '../../../components/data-filter/data-filter.component';
import { MiscService } from '../../../services/misc.service';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-client-requests',
    standalone: true,
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    providers: [
        provideMomentDateAdapter(MY_FORMATS),
        DatePipe
    ],
    imports: [
        PageHeaderComponent,
        FormsModule,
        BoRequestComponent,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatDatepickerInput,
        MatInput,
        MatDatepickerToggle,
        MatDatepicker,
        MatIconModule,
        MatSuffix,
        MatAutocompleteTrigger,
        MatAutocomplete,
        AsyncPipe,
        NgForOf,
        ButtonLoaderDirective,
        RequestsFilterComponent,
        RequestsSearchComponent,
        PaginationComponent,
        DataFilterComponent
    ],
    templateUrl: './client-requests.component.html',
    styleUrl: './client-requests.component.scss'
})
export class ClientRequestsComponent implements OnInit {

    private _requests = inject(RequestService);
    private _loader = inject(LoadingService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private _misc = inject(MiscService);

    query = '';
    requests: any[] = [];
    showFilter = false;
    isProcessing = false;
    filtersLoaded = false;
    filterActive = false;
    escalated = false;
    pageTitle = 'Cereri clienti';
    currentPage = 1;
    limit = 10;
    dataReady = false;
    totalPages!: number;
    pagesArray: any = [];
    currentFilters!: any;

    constructor(private viewContainerRef: ViewContainerRef, private elementRef: ElementRef) {
        this._route.data.subscribe((rsp: any) => {
            this.escalated = rsp.escalated;
        });
    }

    ngOnInit() {
        this._loader.setLoadingState(true);
        this._route.queryParams.subscribe(params => {
            if (this.escalated) {
                this.pageTitle += ' escaladate';
                this.currentPage = params['page'] ? +params['page'] : 1;
                this.limit = params['limit'] ? +params['limit'] : this.limit;
                this.loadEscalatedRequests({...this.currentFilters, page: this.currentPage});
            } else {

                this.currentPage = params['page'] ? +params['page'] : 1;
                this.limit = params['limit'] ? +params['limit'] : this.limit;
                this.loadRequests({...this.currentFilters, page: this.currentPage});
            }
        })
    }

    // this._socket.on('request-read').subscribe((rsp: any) => {
    //     this.requests.forEach(request => {
    //         if (request.client_request_uid === rsp.request_uid) {
    //             request.bo_status = rsp.bo_status;
    //         }
    //     })
    // });

    // this._socket.on('reply-read').subscribe((rsp: any) => {
    //     this.requests.forEach(request => {
    //         if (request.client_request_uid === rsp.request_uid) {
    //             request.bo_status = rsp.bo_status;
    //         }
    //     });
    // });

    loadEscalatedRequests(payload: any) {
        this.dataReady = false;
        this._requests.getEscalatedRequests({
            ...payload,

            page: this.currentPage,
            limit: this.limit
        }).subscribe(rsp => {
            this.isProcessing = false;
            this.requests = rsp.data;
            this.currentPage = parseInt(rsp.currentPage, 10);
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            this.totalPages = rsp.totalPages;
            this.dataReady = true;
            this._loader.setLoadingState(false);
        });
    }

    loadRequests(payload: any) {
        this.dataReady = false;
        this._requests.getAdminRequests({
            ...payload,

            page: this.currentPage,
            limit: this.limit
        }).subscribe(rsp => {
            this.isProcessing = false;
            this.requests = rsp.data;
            this.currentPage = parseInt(rsp.currentPage, 10);
            this.totalPages = rsp.totalPages;
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            this.dataReady = true;
            this._loader.setLoadingState(false);
        });
    }

    search(event: any) {
        this._loader.setLoadingState(true);
        if (this.escalated) {
            this.loadEscalatedRequests({query: event});
        } else {
            this.loadRequests({query: event})
        }
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    markAsRead(request: any) {
        this._router.navigateByUrl(`/backoffice/client-request/${request.client_request_uid}`)
    }

    toggleFilter() {
        if (!this.filtersLoaded) return;

        this.showFilter = !this.showFilter;
    }

    applyFilters(event: any) {
        this._loader.setLoadingState(true);
        this.currentFilters = event;
        this.isProcessing = true;
        this._router.navigate([], {
            relativeTo: this._route
        }).then(() => {
            // Load requests with the new filters
            if (this.escalated) {
                this.loadEscalatedRequests(event);
            } else {
                this.loadRequests(event);
            }
        });
    }

    canShowFilters(event: any) {
        this.filtersLoaded = event;
    }
}

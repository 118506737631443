import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { ContractComponent } from '../../components/contract/contract.component';
import { Contract } from '../../interfaces/contract';
import { DashboardService } from '../dashboard/dashboard.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoadingService } from '../../services/loading.service';
import { UserService } from '../../services/user.service';
import { ContractService } from '../../services/contract.service';

@Component({
    selector: 'mobilize-contracts',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ContractComponent,
        MatProgressBar
    ],
    templateUrl: './contracts.component.html',
    styleUrl: './contracts.component.scss'
})
export class ContractsComponent implements OnInit {

    private _loading = inject(LoadingService);
    private _user = inject(UserService);
    private _contract = inject(ContractService)

    contracts: Contract[] = [];

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._user.userData().subscribe(rsp => {
            this._contract.getContracts().subscribe(contracts => {
                this.contracts = contracts
                this.contracts.forEach(item => {
                    item.contractState = 'opened';
                })
                this._loading.setLoadingState(false);
            });
        });
    }
}

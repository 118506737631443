import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { DocumentBoxComponent } from '../../components/document-box/document-box.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { BaseComponent } from '../../components/base.component';
import { DocumentTypes } from '../../constants/document-types';
import { DocumentType, DocumentTypeFormControl } from '../../interfaces/document-type';
import { Contract, ContractDocument } from '../../interfaces/contract';
import { JsonFormComponent } from '../../components/json-form/json-form.component';
import { CONTRACTS } from '../../mocks/contracts';

@Component({
    selector: 'mobilize-generate-document',
    standalone: true,
    imports: [
        DocumentBoxComponent,
        PageHeaderComponent,
        RouterLink,
        NgClass,
        JsonFormComponent
    ],
    templateUrl: './generate-document.component.html',
    styleUrl: './generate-document.component.scss'
})
export class GenerateDocumentComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor() {
        super();
    }

    @ViewChild('documentsContainer', { static: false }) documentsContainer!: ElementRef;
    @ViewChild('trackingBar', { static: false }) trackingBar!: ElementRef;

    private resizeObserver!: ResizeObserver;
    private cdr = inject(ChangeDetectorRef);

    pageStep = 1;
    documentsContainerHeight: any;
    documents!: any;
    showSelectContractSection = false;
    showForm = false;
    currentStep = 1;
    formData!: any;

    contracts: Contract[] = CONTRACTS;

    ngOnInit(): void {
        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.target === this.documentsContainer.nativeElement) {
                    this.documentsContainerHeight = entry.contentRect.height;
                    this.cdr.detectChanges();
                }
            }
        });
        this.documents = DocumentTypes;
    }

    ngAfterViewInit() {
        if (this.documentsContainer && this.documentsContainer.nativeElement) {
            this.documentsContainerHeight = this.documentsContainer.nativeElement.offsetHeight;
            this.resizeObserver.observe(this.documentsContainer.nativeElement);
        }
    }

    selectDocument(document: any) {
        this.documents.forEach((item: any) => {
            if (item.name !== document.name) {
                item.visible = false;
            }
        });
        this.showSelectContractSection = true;
        this.pageStep = 2;

        if (document.controls.length) {
            this.generateForm(document);
        }
    }

    generateForm(doc: any) {
        this.formData = this.documents.filter((item: any) => item.name === doc.name)[0];
    }

    selectContract(contract: Contract) {
        this.contracts.forEach((item: any) => {
            if (item.title !== contract.title) {
                item.visible = false;
            }
        });
        this.showForm = true;
    }

    changeContractSelection() {
        this.showForm = false;
        this.contracts.forEach((item: any) => item.visible = true);
    }

    changeDocumentSelection() {
        this.showSelectContractSection = false
        this.documents.forEach((item: any) => item.visible = true);
        this.pageStep = 1;
    }

    ngOnDestroy(): void {
        this.pageStep = 1;
        this.showForm = false;
        this.showSelectContractSection = false;
        this.documents.forEach((item: any) => item.visible = true);
        this.contracts.forEach((item: any) => item.visible = true);
    }
}

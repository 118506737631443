import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { Contract } from '../../interfaces/contract';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { BaseComponent } from '../../components/base.component';
import { InstallmentsSliderComponent } from '../../components/installments-slider/installments-slider.component';
import { ContractOptionComponent } from '../../components/contract-option/contract-option.component';
import { HeaderButton } from '../../interfaces/header-button';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { CONTRACTS } from '../../mocks/contracts';
import { ContractService } from '../../services/contract.service';
import { LoadingService } from '../../services/loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'mobilize-contract-details',
    standalone: true,
    animations: [
        trigger('toggleHeight', [
            state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
            state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
            transition('void => *', [
                animate('200ms ease-out')
            ]),
            transition('* => void', [
                animate('300ms ease-in')
            ])
        ])
    ],
    imports: [
        PageHeaderComponent,
        NgClass,
        InstallmentsSliderComponent,
        NgIf,
        ContractOptionComponent,
        DatePipe
    ],
    templateUrl: './contract-details.component.html',
    styleUrl: './contract-details.component.scss'
})
export class ContractDetailsComponent extends BaseComponent implements OnInit {

    private _route = inject(ActivatedRoute);
    private _router = inject(Router);
    private _data = inject(DataService);
    private _loading = inject(LoadingService);

    @ViewChild('documentsSection') documentsSection!: ElementRef;
    @ViewChild('paymentScheduleSection') paymentScheduleSection!: ElementRef;
    @ViewChild('optionsSection') optionsSection!: ElementRef;

    constructor(private _contracts: ContractService) {
        super();

        this._route.params.subscribe((rsp: any) => {
            this.contractId = rsp.id;
        });
    }

    contractId!: number;
    showMoreSections: any = {
        general: false,
        financial: false,
        good: false
    }
    payedAmount = 0;
    headerButton: HeaderButton = {
        label: 'Inchide',
        action: 'route',
        actionData: {
            route: '/contracts',
            method: ''
        },
        className: 'btn-clear'
    }
    services: any[] = [];

    contract!: Contract;
    dataLoaded = false;
    maxInstallments = 0;

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._contracts.getContract(this.contractId).subscribe(rsp => {
            this.contract = rsp;
            this.dataLoaded = true;
            this.payedAmount = 0;
            let count = 0;
            this.contract.contract_schedules.forEach((schedule: any) => {
                if (count < this.contract.previous_instalments && schedule.rate_name !== 'Avans' && schedule.rate_name !== 'Valoare reziduala') {
                    this.payedAmount += parseFloat(schedule.principal_amount);
                    count++;
                }
                if (schedule.rate_name !== 'Avans' && schedule.rate_name !== 'Valoare reziduala') {
                    this.maxInstallments++;
                }
            });

            this._loading.setLoadingState(false);
            const services = this.contract.contract_services_list.filter(item => item.service_code !== 'LS_COM_GL');
            services.forEach((service: any) => {
                if (this.isServiceActive(service.service_start_date, service.service_end_date)) {
                    service.active = true;
                }
            });
            this.services.push(...services);
            if (this.contract.casco_insurance) {
                this.services.push(this.contract.casco_insurance)
            }
            if (this.contract.rca_insurance) {
                this.services.push(this.contract.rca_insurance)
            }
            if (this.contract.adi_insurance) {
                this.services.push(this.contract.adi_insurance)
            }
        });
    }

    showMore(section: string) {
        this.showMoreSections[section] = !this.showMoreSections[section];
    }

    private isServiceActive(serviceStartDate: string, serviceEndDate: string): boolean {
        const currentDate = new Date();
        const startDate = new Date(serviceStartDate);
        const endDate = new Date(serviceEndDate);

        return currentDate >= startDate && currentDate <= endDate;
    }

    focusSection(section: string) {
        switch (section) {
            case 'documents':
                this.documentsSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;

            case 'payment-schedule':
                this.paymentScheduleSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;

            case 'options':
                this.optionsSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;

            case 'history':
                this._data.setData({ contractName: this.contract.title });
                this._router.navigateByUrl(`contract-details/${this.contractId}/history`);
                break;

        }
    }

    outstandingAmount() {
        return parseFloat(String(this.contract.outstanding)) + parseFloat(String(this.contract.rezidual_amount));
    }
}

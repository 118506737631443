import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    constructor(private _http: HttpClient) {
    }

    sendRequest(payload: {
        message: string,
        contract_id: number,
        request: string,
        contract_number: string
    }): Observable<any> {
        return this._http.post('client-request/create', payload);
    }

    getAdminRequests(payload: any): Observable<any> {
        return this._http.post(`client-request/get-requests`, payload);
    }

    getRequests(payload: any): Observable<any> {
        return this._http.post(`client-request/get-client-requests`, payload);
    }

    getRequest(request_uid: string): Observable<any> {
        return this._http.get(`client-request/get-request/${request_uid}`);
    }

    sendReply(payload: any): Observable<any> {
        return this._http.post('client-request/create-reply', payload);
    }

    markAsRead(payload: any): Observable<any> {
        return this._http.post('client-request/mark', payload);
    }

    getAdmins(): Observable<any> {
        return this._http.get('user/escalate-users-list');
    }

    escalate(payload: any): Observable<any> {
        return this._http.post('client-request/escalate', payload);
    }

    getRequestReport(payload: any): Observable<any> {
        return this._http.post(`client-request/list-requests-report`, payload);
    }

    getTypes(): Observable<any> {
        return this._http.get('client-request/type-list');
    }

    getFilters(): Observable<any> {
        return this._http.get('client-request/filters');
    }

    applyFilters(payload: any): Observable<any> {
        let query = '';
        if (payload.page) {
            query += `?page=${payload.page}`;
        } else {
            query += `?page=1`;
        }
        if (payload.limit) {
            query += `&limit=${payload.limit}`;
        } else {
            query += `&limit=15`;
        }
        if (payload.query) {
            query += `&query=${payload.query}`
        }
        return this._http.post(`client-request/apply-filters${query}`, payload);
    }

    getEscalatedRequests(payload: any): Observable<any> {
        return this._http.post('client-request/escalated', payload);
    }

    downloadReport(payload: any): Observable<any> {
        return this._http.post('excel/export', payload, {responseType: 'blob'});
    }
}

<mobilize-page-header [title]="contractName" [reverse]="true"></mobilize-page-header>

<button class="mobilize-btn btn-orange history-back-btn" (click)="goBack()">inapoi</button>

<div class="history-wrapper mobilize-card no-padding-card">
    <div class="title">Actiuni pe contractul curent ({{ contractHistory.length }})</div>

    <div class="year-label">2021</div>

    <div class="history-items">
        @for (item of contractHistory; track item) {
            <div class="history-items__row">
                <div class="date">{{ item.date }}</div>
                <div class="details">
                    <div class="name">{{ item.name }}</div>
                    @if (item.action) {
                        <div class="action">{{ item.action }}</div>
                    }
                </div>
            </div>
        }
    </div>
</div>

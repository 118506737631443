import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../../../services/request.service';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { BoRequestComponent } from '../../components/bo-request/bo-request.component';
import { LoadingService } from '../../../services/loading.service';

@Component({
    selector: 'mobilize-client-request',
    standalone: true,
    imports: [
        PageHeaderComponent,
        BoRequestComponent
    ],
    templateUrl: './client-request.component.html',
    styleUrl: './client-request.component.scss'
})
export class ClientRequestComponent implements OnInit {

    private _route = inject(ActivatedRoute);
    private _request = inject(RequestService)
    private _router = inject(Router);
    private _loading = inject(LoadingService);

    params: any;
    request: any;
    dataLoading = true;
    data: any;

    constructor() {
        this._route.params.subscribe(rsp => {
            this.params = rsp;
        });
        this._route.data.subscribe(rsp => {
            this.data = rsp;
        });
    }
    ngOnInit() {
        this._loading.setLoadingState(true);
        this._request.getRequest(this.params.request_uid).subscribe(rsp => {
            this.request = rsp;
            this.dataLoading = false;

            this._loading.setLoadingState(false);
        }, () => {
            this._router.navigateByUrl('/backoffice/client-requests');
        });
    }

    goToInbox() {
        this._router.navigateByUrl('/backoffice/client-requests');
    }

    goToReports() {
        this._router.navigateByUrl('/backoffice/requests-reports');
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'mobilize-upcoming-due-dates',
    standalone: true,
    imports: [
        PageHeaderComponent,
        RouterLink,
        RouterOutlet,
        RouterLinkActive
    ],
    templateUrl: './upcoming-due-dates.component.html',
    styleUrl: './upcoming-due-dates.component.scss'
})
export class UpcomingDueDatesComponent implements OnInit {

    private _user = inject(UserService);

    partnerName = '';

    async ngOnInit() {
        this._user.userData().subscribe(rsp => {
            this.partnerName = rsp.partnerName;
        });
    }
}

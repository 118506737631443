<mobilize-data-filter (onFilterExec)="execFilter($event)"
                      [processing]="filterProcessing"
                      [filterOptions]="filterOptions"
                      (onExport)="export()"
                      page="future"
                      (onContractsLoaded)="contractIds($event)"></mobilize-data-filter>

<div class="future-amounts">
    <div class="mobilize-card outstanding-pay">
        <div class="pay-info">
            <div class="label">Total de plata RON</div>
            @if (!dataReady) {
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            } @else {
                <div class="amount" [innerHTML]="formatAmount(amountRon, 'RON', true)"></div>
            }
        </div>
    </div>
    <div class="plus-sign">
        <span class="material-symbols-outlined">add</span>
    </div>
    <div class="mobilize-card outstanding-pay">
        <div class="pay-info">
            <div class="label">Total de plata EUR</div>
            @if (!dataReady) {
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            } @else {
                <div class="amount" [innerHTML]="formatAmount(amountEur, 'EUR', true)"></div>
            }
        </div>
    </div>
</div>

<p class="disclaimer"><em>Valoarea de plata afisata este conform scadentarului.</em></p>

<div class="mobilize-card table-card no-padding-card">
    <div class="schedules-table">
        <div class="schedules-table__row header-row">
            <div class="schedules-table__row-content">
                <div class="cell header">Data</div>
                <div class="cell header">Detalii</div>
                <div class="cell header invoice-amount">Suma</div>
                <div class="cell header">
                </div>
            </div>

            @if (dataReady) {
                @for (schedule of processedData; track schedule) {
                    @if (schedule.year) {
                        <div class="schedules-table__row year-row">
                            <div class="cell header-cell">{{ schedule.year }}</div>
                        </div>
                    } @else {
                        <div class="schedules-table__row" [ngClass]="{ 'opened': schedule.opened }">
                            <div class="schedules-table__row-content">
                                <div class="cell">
                                    <div class="invoice-date">{{ parseDateToDisplay(schedule.due_date) }}</div>
                                </div>
                                <div class="cell details-cell">
                                    <div class="invoice-details">{{ schedule.rate_name }} -
                                        Contract {{ schedule.contract.contract_number }}
                                    </div>
                                </div>
                                <div class="cell invoice-amount">
                                    <span
                                        [innerHTML]="formatAmount(schedule.total_amount_with_vat, schedule.contract.currency_code)"></span>
                                </div>
                                <div class="cell invoice-document">
                                    <span></span>
                                    @if (!schedule.opened) {
                                        <span class="icon-mobilize-chevron-down" (click)="toggleRow(schedule)"></span>
                                    } @else {
                                        <span class="icon-mobilize-chevron-up" (click)="toggleRow(schedule)"></span>
                                    }
                                </div>
                            </div>
                            <div class="schedules-table__row-extension">
                                <div class="schedules-table__row-extension-row">
                                    <span>Scadenta la:</span>
                                    <span>{{ schedule.due_date | date: 'dd/MM/YYYY' }}</span>
                                </div>
                                @if (schedule.invoice) {
                                    <div class="schedules-table__row-extension-row">
                                        <span>Nr. document:</span>
                                        <span>{{ schedule.invoice?.invoice_number }}</span>
                                    </div>
                                }
                                <div class="schedules-table__row-extension-row">
                                    <span>Capital:</span>
                                    <span
                                        [innerHTML]="formatAmount(schedule.principal_amount, schedule.contract.currency_code)"></span>
                                </div>
                                <div class="schedules-table__row-extension-row">
                                    <span>Dobanda:</span>
                                    <span
                                        [innerHTML]="formatAmount(schedule.interest_amount, schedule.contract.currency_code)"></span>
                                </div>
                                <div class="schedules-table__row-extension-row">
                                    <span>Servicii:</span>
                                    <span
                                        [innerHTML]="formatAmount(schedule.commission_amount, schedule.contract.currency_code)"></span>
                                </div>
                                @if (schedule.invoice) {
                                    <div class="schedules-table__row-extension-row">
                                        <span>Total de plata:</span>
                                        <span
                                            [innerHTML]="formatAmount(schedule.invoice.total, schedule.contract.currency_code)"></span>
                                    </div>
                                    <div class="schedules-table__row-extension-row">
                                        <span>Platit:</span>
                                        <span
                                            [innerHTML]="formatAmount(schedule.invoice.paid_amount, schedule.contract.currency_code)"></span>
                                    </div>
                                }
                                @if (schedule.invoice && schedule.invoice.payment_allocation) {
                                    <div class="schedules-table__row-extension-row">
                                        <span>Rest de plata:</span>
                                        <span
                                            [innerHTML]="formatAmount(schedule.invoice.rest_amount, schedule.contract.currency_code)"></span>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                } @empty {
                    <div class="schedules-table__row year-row">
                        <div class="cell header-cell">nu exista scadentare inregistrate</div>
                    </div>
                }
            } @else {
                <div class="mock-invoice-row">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            }
        </div>
    </div>
</div>

@if (dataReady && totalPages > 0) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}

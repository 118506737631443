<mobilize-page-header title="Solicitarile mele" [reverse]="true"></mobilize-page-header>

<div class="tabs-wrapper">
    <div class="tabs-header">
        <div class="tabs-header__tab" routerLinkActive="selected" routerLink="/my-requests/inbox"><span>Inbox</span></div>
        <div class="tabs-header__tab" routerLinkActive="selected" routerLink="/my-requests/new-request"><span>Trimite solicitare</span></div>
    </div>
</div>

<router-outlet></router-outlet>

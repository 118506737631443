<mobilize-page-header title="Fa o plata" [reverse]="true"></mobilize-page-header>

<div class="mobilize-card">
    <div class="inner-page-title">Alege ce vrei sa achiti</div>
    <ng-template #targetAlertContainer></ng-template>

    <div class="bills-wrapper">
        @for (bill of bills; track bill) {
            <div class="bill" [ngClass]="{'bill-checked': bill.checked, 'bill-extended': bill.extended}">
                <div class="bill-visible-info">
                    <div class="bill__check">
                        <div class="custom-checkbox">
                            <div class="fake-checkbox"
                                 [ngClass]="{'checked': bill.checked}">
                                <span class="icon-mobilize-check-mark"></span>
                            </div>
                            <label>
                                <input type="checkbox" (change)="checkBill($event, bill)" [(ngModel)]="bill.checked">
                            </label>
                        </div>
                    </div>
                    <div class="bill__date">{{ bill.np }}</div>
                    <div class="bill__details">
                        <div class="bill__details-title">{{ bill.title }}</div>
                        <div class="bill__details-due-status">
                            <span class="bill-status"
                                  [ngClass]="statusOptions(bill.status).class">{{ bill.status }}</span>
                            <span class="bill-due">{{ bill.dueDate }}</span>
                        </div>
                    </div>
                    <div class="bill__amount" [innerHTML]="formatAmount(bill.value, 'RON')"></div>
                    <div class="bill__download">
                        <span class="icon-mobilize-receipt"></span>
                    </div>
                    <div class="bill__extend" (click)="extendBill(bill)"><span class="icon-mobilize-chevron-down"></span>
                    </div>
                </div>

                <div class="bill__extension">
                    <div class="bill__extension-row">
                        <span>Facturat la:</span>
                        <span>30/07/2021</span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Nr. document:</span>
                        <span>{{ bill.documentNumber }}</span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Total de plata:</span>
                        <span [innerHTML]="formatAmount(bill.totalDue, 'RON')"></span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Platit:</span>
                        <span [innerHTML]="formatAmount(bill.payed, 'RON')"></span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Rest de plata:</span>
                        <span [innerHTML]="formatAmount(bill.amountDue, 'RON')"></span>
                    </div>
                    <div class="bill__extension-row">
                        <span>Stare:</span>
                        <span>{{ bill.status }}</span>
                    </div>
                </div>
            </div>
        }
    </div>

    <div class="total-payment" *ngIf="totalAmountForPayment > 0">
        <div class="title">total de plata</div>
        <div class="amount" [innerHTML]="formatAmount(totalAmountForPayment, 'RON')"></div>
        <div class="info">Vei fi transferat in pagina procesatorului de plati</div>
        <button class="mobilize-btn btn-orange">plateste securizat</button>
    </div>
</div>

<div class="outer-card-wrapper">
    <div class="title">
        Poti plati anticipat, integral sau partial
    </div>
    <button class="mobilize-btn btn-clear" [routerLink]="'/early-repayment'">plateste anticipat</button>
</div>

import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PayComponent } from './pages/pay/pay.component';
import { EarlyRepaymentComponent } from './pages/early-repayment/early-repayment.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { ContractDetailsComponent } from './pages/contract-details/contract-details.component';
import { ContractActionsHistoryComponent } from './pages/contract-actions-history/contract-actions-history.component';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { ExistingDocumentsComponent } from './pages/existing-documents/existing-documents.component';
import { GenerateDocumentComponent } from './pages/generate-document/generate-document.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { InboxComponent } from './pages/requests/inbox/inbox.component';
import { NewComponent } from './pages/requests/new/new.component';
import { DetailsComponent } from './pages/requests/details/details.component';
import { AccountFunctionsComponent } from './pages/account-functions/account-functions.component';
import { ChangePasswordComponent } from './pages/account-functions/pages/change-password/change-password.component';
import { ChangeEmailComponent } from './pages/account-functions/pages/change-email/change-email.component';
import { UserDataComponent } from './pages/account-functions/pages/user-data/user-data.component';
import { AssociatedUsersComponent } from './pages/account-functions/pages/associated-users/associated-users.component';
import {
    ContactPreferencesComponent
} from './pages/account-functions/pages/contact-preferences/contact-preferences.component';
import { authGuard } from './guards/auth.guard';
import { BO_DashboardComponent } from './back-office/pages/dashboard/dashboard.component';
import { roleGuard } from './guards/role.guard';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { RegisterComponent } from './pages/register/register.component';
import { AccountActivationComponent } from './pages/account-activation/account-activation.component';
import { ErrorComponent } from './pages/error/error.component';
import { ClientRequestsComponent } from './back-office/pages/client-requests/client-requests.component';
import { ClientRequestComponent } from './back-office/pages/client-request/client-request.component';
import { RequestsReportsComponent } from './back-office/pages/requests-reports/requests-reports.component';
import { UpcomingDueDatesComponent } from './pages/upcoming-due-dates/upcoming-due-dates.component';
import { CurrentDueDatesComponent } from './pages/upcoming-due-dates/current-due-dates/current-due-dates.component';
import { FutureDueDatesComponent } from './pages/upcoming-due-dates/future-due-dates/future-due-dates.component';
import { ImpersonationComponent } from './back-office/pages/impersonation/impersonation.component';
import { NotAllowedComponent } from './pages/not-allowed/not-allowed.component';
import { PaymentResponseComponent } from './pages/payment-response/payment-response.component';
import { UserManagementComponent } from './back-office/pages/user-management/user-management.component';
import { ConfirmAdminAccountComponent } from './pages/confirm-admin-account/confirm-admin-account.component';
import { SchedulesComponent } from './pages/schedules/schedules.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: { registerPage: true },
    },
    {
        path: 'payment-response',
        component: PaymentResponseComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'pay',
        component: PayComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'early-repayment',
        component: EarlyRepaymentComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'bills-and-payments',
        component: InvoicesComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'schedule',
        component: SchedulesComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'contracts',
        component: ContractsComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'contract-details/:id',
        component: ContractDetailsComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'contract-details/:id/history',
        component: ContractActionsHistoryComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'insurance',
        component: InsuranceComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'existing-documents',
        component: ExistingDocumentsComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'generate-document',
        component: GenerateDocumentComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
    },
    {
        path: 'activate-account/:enrollment_uid',
        component: AccountActivationComponent,
        data: { registerPage: true },
    },
    {
        path: 'confirm-admin-account/:uid',
        component: ConfirmAdminAccountComponent,
        data: { registerPage: true },
    },
    {
        path: 'error',
        canActivate: [authGuard],
        component: ErrorComponent,
        data: { registerPage: true },
    },
    {
        path: 'my-requests',
        component: RequestsComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
        children: [
            {
                path: 'inbox',
                component: InboxComponent
            },
            {
                path: 'inbox/:id',
                component: DetailsComponent
            },
            {
                path: 'new-request',
                component: NewComponent
            },
            {
                path: '',
                redirectTo: 'inbox',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'upcoming-due-dates',
        component: UpcomingDueDatesComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
        children: [
            {
                path: 'current',
                component: CurrentDueDatesComponent
            },
            {
                path: 'future',
                component: FutureDueDatesComponent
            },
            {
                path: '',
                redirectTo: 'current',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'account-settings',
        component: AccountFunctionsComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['Admin', 'User'] },
        children: [
            {
                path: 'change-password',
                component: ChangePasswordComponent
            },
            {
                path: 'change-email',
                component: ChangeEmailComponent
            },
            {
                path: 'user-data',
                component: UserDataComponent
            },
            {
                path: 'associated-users',
                component: AssociatedUsersComponent
            },
            {
                path: 'contact-preferences',
                component: ContactPreferencesComponent
            }
        ]
    },
    {
        path: 'not-allowed',
        component: NotAllowedComponent,
        canActivate: [authGuard]
    }
];

export const backofficeRoutes: Routes = [
    {
        path: 'backoffice',
        canActivate: [authGuard, roleGuard],
        data: { expectedRoles: ['eCare Admin'], backoffice: true },
        children: [
            {
                path: 'dashboard',
                component: BO_DashboardComponent
            },
            {
                path: 'client-requests',
                component: ClientRequestsComponent
            },
            {
                path: 'client-escalated-requests',
                component: ClientRequestsComponent,
                data: {
                    escalated: true
                }
            },
            {
                path: 'client-request/:request_uid',
                component: ClientRequestComponent
            },
            {
                path: 'report-details/:request_uid',
                component: ClientRequestComponent,
                data: {
                    reportPage: true
                }
            },
            {
                path: 'user-management',
                component: UserManagementComponent
            },
            {
                path: 'requests-reports',
                component: RequestsReportsComponent
            },
            {
                path: 'client-impersonation',
                component: ImpersonationComponent
            },
        ]
    }
]

<ng-template #targetAlertContainer></ng-template>

<div class="mobilize-card change-email-page">
    @if (!mailChangeInProgress) {
        <form [formGroup]="form" class="custom-form">
            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Introdu parola contului:</mat-label>
                        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password"
                               autocomplete="new-password"
                               placeholder="scrie parola">
                    </mat-form-field>
                    @if (!showPassword) {
                        <span class="input-icon icon-mobilize-eye-off"
                              (click)="showPassword = !showPassword"></span>
                    } @else {
                        <span class="input-icon icon-mobilize-eye"
                              (click)="showPassword = !showPassword"></span>
                    }
                    <div class="form-error"
                         *ngIf="form.controls.password.touched && form.controls.password.invalid">
                        Parola actuala este obligatorie
                    </div>
                </div>
            </div>

            <mobilize-alert [type]="'danger'"
                            message="Noua adresa de email va reprezenta noul tau username, cu care te vei autentifica in Mobilize."
                            icon="icon-mobilize-warning"
            ></mobilize-alert>

            <div class="form-group">
                <div class="form-control single-control">
                    <mat-form-field>
                        <mat-label>Noul e-mail:</mat-label>
                        <input matInput type="text" formControlName="newEmail"
                               placeholder="tasteaza cu atentie noul email"
                               autocomplete="new-password">
                    </mat-form-field>
                    @if (form.controls['newEmail'].touched && form.controls['newEmail'].invalid) {
                        <div class="form-error">Adresa de email este obligatorie</div>
                    }
                </div>
            </div>

            <div class="form-actions">
                <button class="mobilize-btn btn-orange" type="submit" (click)="changeEmail()"
                        [mobilizeButtonLoader]="changing" originalContent="valideaza email">valideaza email
                </button>
            </div>
        </form>
    } @else {
        <form class="custom-form">
            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Introdu codul de 6 cifre trimis pe noul email:</mat-label>
                        <input matInput type="text" placeholder="" [(ngModel)]="otp" name="otp" autocomplete="new-otp">
                    </mat-form-field>
                </div>
            </div>

            <div class="form-actions">
                <button class="mobilize-btn btn-orange" type="submit" (click)="confirmChangeEmail()"
                        [mobilizeButtonLoader]="changing" originalContent="modifica email">modifica email
                </button>
            </div>
        </form>
    }
</div>

import { Component, inject, Input, OnInit } from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { OptionDetailsComponent } from '../option-details/option-details.component';

@Component({
    selector: 'mobilize-contract-option',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        DatePipe
    ],
    templateUrl: './contract-option.component.html',
    styleUrl: './contract-option.component.scss'
})
export class ContractOptionComponent implements OnInit {

    private _dialog = inject(MatDialog);

    @Input() option!: any;
    @Input() contractState!: any;
    @Input() contract!: any;

    optionName = '';

    optionDetails() {
            this._dialog.open(OptionDetailsComponent, {
                width: '1000px',
                data: {
                    option: this.option,
                    contract: this.contract,
                    name: this.optionName
                },
                autoFocus: false
            });
    }

    ngOnInit() {
        this.optionName = this.option.service_name ? this.option.service_type.servicecategory : this.option.insurance_type;

        if (this.option.insurance_type) {
            this.option.active = this.option.insurance_status === 'Valid';
            this.option.service_end_date = this.option.insurance_end_date;
        }
    }
}

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { InsuranceBoxComponent } from '../../components/insurance-box/insurance-box.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { DocumentBoxComponent } from '../../components/document-box/document-box.component';
import { Document } from '../../interfaces/document';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DocumentsService } from '../../services/documents.service';
import { LoadingService } from '../../services/loading.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { DataFilterComponent } from '../../components/data-filter/data-filter.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';

@Component({
    selector: 'mobilize-documents',
    standalone: true,
    imports: [
        InsuranceBoxComponent,
        PageHeaderComponent,
        DocumentBoxComponent,
        RouterLink,
        DataFilterComponent,
        PaginationComponent
    ],
    templateUrl: './existing-documents.component.html',
    styleUrl: './existing-documents.component.scss'
})
export class ExistingDocumentsComponent implements OnInit, OnDestroy {

    private _documents = inject(DocumentsService);
    private _loading = inject(LoadingService);
    private _user = inject(UserService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);

    documents: Document[] = [];
    backOffice = false;
    impersonatedAccount = false;
    filterOptions: string[] = ['search'];
    dataReady = false;
    filterProcessing = false;
    filterArgs: any;
    limit = 10;
    currentPage = 1;
    pagesArray: any[] = [];
    totalPages: any;

    private subscription = new Subscription();

    ngOnInit() {
        this.subscription = this._user.impersonatedStatus$.subscribe(rsp => {
            if (rsp && rsp.active) {
                this.backOffice = false;
                this.impersonatedAccount = rsp.active;
            }
        });
        this.getDocuments({page: this.currentPage, limit: this.limit});
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getDocuments(payload?: any) {
        this.dataReady = false;
        this._loading.setLoadingState(true);
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;
            this._documents.generatedDocuments({
                ...payload,
                page: this.currentPage,
                limit: this.limit
            }).subscribe(rsp => {
                this.documents = rsp.data;
                this.dataReady = true;
                this.filterProcessing = false;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
                this._loading.setLoadingState(false);
            });
        });
    }

    execFilter(e: any) {
        delete e.contracts;
        delete e.endDate;
        delete e.startDate;
        delete e.categories;
        delete e.state;

        this.dataReady = false;
        this.filterProcessing = true;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.getDocuments(e);
        });
    }

    onPageSelect(event: any) {
        this.documents = [];
        this._loading.setLoadingState(true);
        if (event.page < 1 || event.page > this.totalPages) {
            return;
        }
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {page: event.page, limit: event.limit || this.limit}
        }).then(() => {
            // Load requests with the new filters
            this.getDocuments(this.currentPage);
        });
    }
}

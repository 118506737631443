import { Component, inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'mobilize-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class BO_DashboardComponent {

    private _kc = inject(KeycloakService);

    logout() {
        this._kc.logout();
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { ContractComponent } from '../../components/contract/contract.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'mobilize-timetable',
    standalone: true,
    imports: [
        ContractComponent,
        PageHeaderComponent
    ],
    templateUrl: './timetable.component.html',
    styleUrl: './timetable.component.scss'
})
export class TimetableComponent implements OnInit {

    private _dashboard = inject(DashboardService);
    private _loading = inject(LoadingService);

    contracts!: any[];

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._dashboard.getDashboardContracts().subscribe(rsp => {
            this.contracts = rsp;
            this.contracts.forEach(contract => {
                contract.contractState = 'opened';
            });
            this._loading.setLoadingState(false);
        });
    }
}

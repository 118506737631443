import { Component, inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { ButtonLoaderDirective } from '../../../../directives/button-loader.directive';
import { ConsentsService } from '../../../../services/consents.service';
import { LoadingService } from '../../../../services/loading.service';

@Component({
    selector: 'mobilize-contact-preferences',
    standalone: true,
    imports: [
        FormsModule,
        NgClass,
        ButtonLoaderDirective
    ],
    templateUrl: './contact-preferences.component.html',
    styleUrl: './contact-preferences.component.scss'
})
export class ContactPreferencesComponent implements OnChanges, OnInit {
    private _consents = inject(ConsentsService);
    private _loading = inject(LoadingService);

    email!: boolean;
    sms!: boolean;
    post!: boolean;
    phone!: boolean;
    isProcessing = false;

    contactMethodsDisabled = true;

    agreementsOptions: { id: null | number; name: string; checked: boolean; label: string; type: string }[] = [
        {
            name: 'Acord de Marketing RCI',
            id: null,
            type: 'CMKRCI',
            checked: false,
            label: '(CMKRCI)'
        }, {
            name: 'Acord de profilare',
            id: null,
            type: 'CMKADP',
            checked: false,
            label: '(CMKADP)'
        }, {
            name: 'Acord de Marketing Renault Commercial Roumanie',
            id: null,
            type: 'CMKRCR',
            checked: false,
            label: '(CMKRCR)'
        }
    ];

    contactOptions: { id: null | number; name: string; checked: boolean; label: string; type: string }[] = [
        {
            name: 'Email',
            id: null,
            type: 'CMKEML',
            checked: false,
            label: '(CMKEML)'
        }, {
            name: 'Sms',
            id: null,
            type: 'CMKSMS',
            checked: false,
            label: '(CMKSMS)'
        }, {
            name: 'Posta',
            id: null,
            type: 'CMKMIL',
            checked: false,
            label: '(CMKMIL)'
        }, {
            name: 'Telefon',
            id: null,
            type: 'CMKTEL',
            checked: false,
            label: '(CMKTEL)'
        }
    ];

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._consents.getConsents().subscribe(rsp => {
            this.agreementsOptions = this.agreementsOptions.map((item) => {
                const matchingConsent = rsp.find(
                    (consent: any) =>
                        consent.consent_type === item.type && consent.accepted === true
                );
                if (matchingConsent) {
                    this.contactMethodsDisabled = false;
                }
                return {
                    ...item,
                    checked: !!matchingConsent,
                };
            });
            this.contactOptions = this.contactOptions.map((item) => {
                const matchingConsent = rsp.find(
                    (consent: any) =>
                        consent.consent_type === item.type && consent.accepted === true
                );
                return {
                    ...item,
                    checked: !!matchingConsent,
                };
            });
            this._loading.setLoadingState(false);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['contactOptions']) {
            this.checkOptions();
        }
    }

    onCheckboxChange(): void {
        this.checkOptions();
    }

    private checkOptions(): void {
        const isAnyChecked = this.agreementsOptions.some(option => option.checked);
        this.contactMethodsDisabled = !isAnyChecked;

        if (this.contactMethodsDisabled) {
            this.email = false;
            this.sms = false;
            this.post = false;
            this.phone = false;
        }
    }

    sendAgreements() {
        console.log(this.agreementsOptions)
        console.log(this.contactOptions);
        const consentData = {
            PartnerId: '84099655',
            Consents: [
                {
                    PartnerConsentId: '7654321',
                    ConsentType: 'Consimtamant marketing RCI',
                    Accepted: '1',
                    DataAcordarii: '2024-02-29',
                    DataRetragerii: '2024-03-01',
                },
                {
                    PartnerConsentId: '',
                    ConsentType: 'Consimtamant marketing RCR',
                    Accepted: '1',
                    DataAcordarii: '2024-02-29',
                    DataRetragerii: '2024-03-01',
                },
            ],
        };

    }
}

import { Component, Inject, inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogActions, MatDialogConfig, MatDialogContainer,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from '@angular/material/dialog';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'mobilize-response-modal',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        NgClass,
        MatDialogActions,
        MatDialogContainer
    ],
    providers: [],
    templateUrl: './response-modal.component.html',
    styleUrl: './response-modal.component.scss'
})
export class ResponseModalComponent implements OnInit {

    private dialogRef = inject(MatDialogRef<ResponseModalComponent>);
    private _router = inject(Router);

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

    }

    close(action?: string) {
        this.dialogRef.close(action);
    }

}

import { Component, inject, Input } from '@angular/core';
import { InstallmentsSliderComponent } from '../installments-slider/installments-slider.component';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Insurance } from '../../interfaces/insurance';
import { BaseComponent } from '../base.component';
import { MatDialog } from '@angular/material/dialog';
import { OptionDetailsComponent } from '../option-details/option-details.component';

@Component({
    selector: 'mobilize-insurance-box',
    standalone: true,
    imports: [
        InstallmentsSliderComponent,
        NgIf,
        NgClass,
        DatePipe
    ],
    templateUrl: './insurance-box.component.html',
    styleUrl: './insurance-box.component.scss'
})
export class InsuranceBoxComponent extends BaseComponent {

    @Input() insurance!: any;

    private _dialog = inject(MatDialog);

    insuranceDetails() {
        this._dialog.open(OptionDetailsComponent, {
            width: '1000px',
            data: {
                details: this.insurance
            }
        })
    }

    get insuranceCurrency() {
        if (this.insurance.rcaContracts.length) {
            return this.insurance.rcaContracts[0].currency_code
        }
        if (this.insurance.cascoContracts.length) {
            return this.insurance.cascoContracts[0].currency_code
        }
        if (this.insurance.adiContracts.length) {
            return this.insurance.adiContracts[0].currency_code
        }

        return '';
    }
}

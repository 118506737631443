<div class="logo">
    <img src="assets/images/mobilize-logo-small.png" width="170">
</div>

@if (showOverlay) {
    <div class="data-protection-overlay">
        <div class="data-protection">
            <div class="mobilize-card">
                <div class="title">
                    Protejarea datelor dumneavoastra este prioritate pentru Mobilize Financial Services
                    <span class="icon-mobilize-close" (click)="closeDataProtection()"></span>
                </div>

                <div class="content">
                    Site-ul nostru si <span class="font-orange">partenerii sai</span> doreste sa utilizeze cookie-uri
                    pentru
                    a
                    masura performanta publicului si a
                    site-ului web, pentru a va afisa publicitate si continut personalizate si/sau bazate pe locatie si
                    pentru a
                    va
                    permite sa interactionati cu continutul nostru prin intermediul retelelor sociale. Va puteti
                    reconsidera
                    alegerile in orice moment accesand <span class="font-orange">sectiunea “Cookie-uri”</span> a
                    site-ului
                    nostru.
                </div>

                <button class="mobilize-btn btn-orange" (click)="closeDataProtection()">am inteles</button>
            </div>
        </div>
    </div>
}

<div class="form-wrapper">
    <div class="form-wrapper-content">

        @switch (registerStatus) {
            @case ('initial') {
                <a href="/" class="orange-link back-to-auth" (click)="login()">Inapoi la autentificare</a>

                <div class="form-title">Creeaza cont</div>
                <div class="form-subtitle">Bucura-te de o experienta placuta, destinata exclusiv clientilor RCI. Poti sa
                    faci
                    singur, din contul tau, orice operatiune
                </div>

                <form class="register-form" [formGroup]="registerForm">
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input type="text" formControlName="contract_number" autocomplete="off"
                                   placeholder="Introdu numarul de contract">
                            @if (registerForm.controls['contract_number'].touched && registerForm.controls['contract_number'].invalid) {
                                <div class="form-error">Numarul de contract este obligatoriu</div>
                            }
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input type="text" formControlName="partial_vin" autocomplete="off"
                                   placeholder="Introdu ultimele 6 cifre din seria de sasiu">
                            @if (registerForm.controls['partial_vin'].touched && registerForm.controls['partial_vin'].invalid) {
                                <div class="form-error">Seria de sasiu este obligatorie (ultimele 6 cifre)</div>
                            }
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input type="text" formControlName="email" autocomplete="off" placeholder="Introdu adresa de email">
                            @if (registerForm.controls['email'].touched && registerForm.controls['email'].invalid) {
                                <div class="form-error">Adresa de email este obligatorie</div>
                            }
                        </div>
                    </div>
                </form>

                <div class="agreement-row">
                    <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover">
                        <div class="fake-checkbox"
                             [ngClass]="{'checked': termsAgreement}">
                            <span class="icon-mobilize-check-mark"></span>
                        </div>
                        <label>
                            <input type="checkbox" [(ngModel)]="termsAgreement">
                        </label>
                    </div>
                    <div class="text">
                        Accept <span class="font-orange">termenii si conditiile de utilizare</span> ale platformei RCI
                    </div>
                </div>

                <div class="agreement-row">
                    <div class="custom-checkbox custom-checkbox__white-interior custom-checkbox__with-hover">
                        <div class="fake-checkbox"
                             [ngClass]="{'checked': privacyAgreement}">
                            <span class="icon-mobilize-check-mark"></span>
                        </div>
                        <label>
                            <input type="checkbox" [(ngModel)]="privacyAgreement">
                        </label>
                    </div>
                    <div class="text">
                        Accept <span class="font-orange">politica de confidentialitate</span>
                    </div>
                </div>

                @if (agreementsError) {
                    <div class="agreements-error">
                        Pentru a continua inregistrarea trebuie sa accepti termenii si politica de confidentialitate
                    </div>
                }

                <re-captcha
                    #captchaRef="reCaptcha"
                    (resolved)="resolved($event)"
                ></re-captcha>

                <button class="mobilize-btn btn-orange btn-large" [mobilizeButtonLoader]="isProcessing"
                        originalContent="continua" (click)="validateData()"></button>
            }
            @case ('confirm-email') {
                <a href="/" class="orange-link back-to-auth" (click)="login()">Inapoi la autentificare</a>

                <div class="form-title">Verifica email</div>
                <div class="form-subtitle">Este important sa validam ca ai access la aceasta adresa de email.</div>

                <form class="register-form" [formGroup]="otpForm">
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input type="text" formControlName="code" autocomplete="off"
                                   placeholder="Codul de 6 cifre trimis pe adresa de email">
                            <div class="sub-input-info">
                                Codul a fost transmis la adresa:
                                <strong>{{ registerForm.value.email || registeredEmail }}</strong> Verifica si in
                                folderul
                                Junk.
                            </div>
                            <div class="form-error" *ngIf="otpError">Codul introdus nu este corect.</div>
                        </div>
                    </div>
                </form>

                <button class="mobilize-btn btn-orange btn-large" [mobilizeButtonLoader]="isProcessing"
                        originalContent="Verifica" (click)="step2()">Verifica
                </button>
            }
            @case ('password') {
                <a href="/" class="orange-link back-to-auth" (click)="login()">Inapoi la autentificare</a>

                <div class="form-title">Creeaza cont</div>
                <div class="form-subtitle">Bucura-te de o experienta placuta, destinata exclusiv clientilor RCI. Poti sa
                    faci singur, din contul tau, orice operatiune
                </div>

                <form class="register-form" [formGroup]="personalDataForm">
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input type="text" formControlName="fullName" autocomplete="new-fullname"
                                   placeholder="Nume complet">
                            <div class="form-error"
                                 *ngIf="personalDataForm.controls.fullName.touched && personalDataForm.controls.fullName.invalid">
                                Numele este obligatoriu
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input [type]="showPassword ? 'text' : 'password'" formControlName="password" autocomplete="new-password"
                                   placeholder="Seteaza o parola">
                            @if (!showPassword) {
                                <span class="input-icon icon-mobilize-eye-off"
                                      (click)="showPassword = !showPassword"></span>
                            } @else {
                                <span class="input-icon icon-mobilize-eye"
                                      (click)="showPassword = !showPassword"></span>
                            }

                            <span class="input-icon icon-mobilize-info"
                                  matTooltip="Minimum 8 caractere, o litera mica, o litera mare, un caracter special: !@#$%^&*()"></span>
                            <div class="form-error" *ngIf="personalDataForm.controls.password.errors?.['pattern']">
                                Min. 8 caractere<br>
                                Min. o litera mica<br>
                                Min. o litera mare<br>
                                Min. un caracter special: !&#64;#$%^&*()
                            </div>
                            <div class="form-error"
                                 *ngIf="personalDataForm.controls.password.touched && personalDataForm.controls.password.invalid">
                                Parola este obligatorie
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-control single-control">
                            <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" autocomplete="new-confirmPassword"
                                   placeholder="Reintrodu parola">
                            @if (!showConfirmPassword) {
                                <span class="input-icon icon-mobilize-eye-off"
                                      (click)="showConfirmPassword = !showConfirmPassword"></span>
                            } @else {
                                <span class="input-icon icon-mobilize-eye"
                                      (click)="showConfirmPassword = !showConfirmPassword"></span>
                            }
                            <div class="form-error"
                                 *ngIf="personalDataForm.controls.confirmPassword.errors?.['compare']">
                                Parolele nu se potrivesc
                            </div>
                        </div>
                    </div>
                </form>

                <button class="mobilize-btn btn-orange btn-large" [mobilizeButtonLoader]="isProcessing"
                        originalContent="Creeaza cont" (click)="createAccount()">Creeaza cont
                </button>
            }
        }
    </div>
</div>

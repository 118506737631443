import {
    AfterViewInit,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Renderer2, SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
    selector: '[mobilizeButtonLoader]',
    standalone: true
})
export class ButtonLoaderDirective implements AfterViewInit, OnDestroy, OnChanges {

    @Input('mobilizeButtonLoader') processing!: boolean;
    @Input() spinnerDiameter!: number;
    @Input() buttonText!: string;
    @Input() originalContent!: string;
    private readonly button: HTMLButtonElement;
    private spinnerRef!: ComponentRef<MatProgressSpinner>;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef
    ) {
        this.button = this.el.nativeElement;
    }

    ngAfterViewInit() {
        // Ensure originalContent is captured after view has been initialized
        this.originalContent = this.button.innerHTML;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['processing']) {
            if (this.processing) {
                this.showLoader();
            } else {
                this.hideLoader();
            }
        }
    }

    private showLoader(): void {
        if (!this.originalContent) {
            this.originalContent = this.button.innerHTML;
        }
        const buttonWidth = `${this.button.offsetWidth}px`;
        this.renderer.setStyle(this.button, 'width', buttonWidth);

        this.originalContent = this.button.innerHTML;
        this.renderer.setProperty(this.button, 'disabled', true);
        this.renderer.setProperty(this.button, 'innerHTML', '');

        // Dynamically create the spinner component
        this.spinnerRef = this.viewContainerRef.createComponent(MatProgressSpinner);
        this.spinnerRef.instance.diameter = this.spinnerDiameter || 20; // Default diameter to 20 if not provided
        this.spinnerRef.instance.mode = 'indeterminate' // Default diameter to 20 if not provided

        // Insert the spinner into the button
        this.renderer.appendChild(this.button, this.spinnerRef.location.nativeElement);
        this.renderer.setStyle(this.button.firstChild, 'display', 'flex');
        this.renderer.setStyle(this.button.firstChild, 'justify-content', 'center');
        this.renderer.setStyle(this.button.firstChild, 'align-items', 'center');
    }

    private hideLoader(): void {
        if (this.spinnerRef) {
            this.spinnerRef.destroy();
        }

        this.renderer.setProperty(this.button, 'innerHTML', this.originalContent);
        this.renderer.setProperty(this.button, 'disabled', false);
        this.renderer.removeStyle(this.button, 'width');
    }

    ngOnDestroy() {
        if (this.spinnerRef) {
            this.spinnerRef.destroy();
        }
    }
}

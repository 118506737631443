<mobilize-page-header title="Detalii cerere" [reverse]="true"></mobilize-page-header>

<div class="request-details-wrapper mobilize-card">
    @if (!data?.reportPage) {
        <button class="mobilize-btn btn-clear" (click)="goToInbox()">Inapoi la inbox</button>
    } @else {
        <button class="mobilize-btn btn-clear" (click)="goToReports()">Inapoi</button>
    }

    @if (!dataLoading) {
        <mobilize-bo-request [request]="request" [reportPage]="data?.reportPage"
                             [detailsPage]="true"></mobilize-bo-request>
    }
</div>

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { InstallmentsSliderComponent } from '../../components/installments-slider/installments-slider.component';
import { BaseComponent } from '../../components/base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { AgreementModalComponent } from './components/agreement-modal/agreement-modal.component';
import { EarlyPaymentContract } from '../../interfaces/early-payment-contract';
import { AlertService } from '../../components/alert/alert.service';
import { EarlyPaymentHistory } from '../../interfaces/early-payment-history';

@Component({
    selector: 'mobilize-early-repayment',
    standalone: true,
    imports: [
        FormsModule,
        PageHeaderComponent,
        NgClass,
        InstallmentsSliderComponent,
        NgIf,
        MatSlider,
        MatSliderThumb,
        ReactiveFormsModule,
        NgStyle
    ],
    templateUrl: './early-repayment.component.html',
    styleUrl: './early-repayment.component.scss'
})
export class EarlyRepaymentComponent extends BaseComponent implements OnInit, AfterViewInit {

    constructor() {
        super();
    }

    @ViewChild('contractsContainer', { static: false }) contractsContainer!: ElementRef;
    @ViewChild('targetAlertContainer', { read: ViewContainerRef }) targetViewContainerRef!: ViewContainerRef;

    private resizeObserver!: ResizeObserver;
    private _snackbar = inject(MatSnackBar);
    private _dialog = inject(MatDialog);
    private cdr = inject(ChangeDetectorRef);
    private _alert = inject(AlertService);

    contracts: EarlyPaymentContract[] = [
        {
            id: '78gf63f4-38b356fd34-27f8g',
            name: 'Contract credit n* CR RON202986 pentru Dacia Spring',
            financedGood: 'Renault Megane',
            payed: 34000,
            payedInstallments: 10,
            maximumInstallments: 60,
            totalDue: 75000,
            state: 'extended'
        },
        {
            id: '2434c3c34c-25c6v3456v-456c3456v',
            name: 'Contract credit n* CR RON202986 pentru Bicicleta Pegas',
            financedGood: 'Trotineta roz',
            payed: 300,
            payedInstallments: 9,
            maximumInstallments: 24,
            totalDue: 1500,
            state: 'collapsed'
        },
        {
            id: '2434c3c34c-25c6v3456v-456c3456v',
            name: 'Contract credit n* CR RON202986 pentru Bicicleta Pegas',
            financedGood: 'Trotineta roz',
            payed: 300,
            payedInstallments: 9,
            maximumInstallments: 24,
            totalDue: 1500,
            state: 'collapsed'
        },
        {
            id: '2434c3c34c-25c6v3456v-456c3456v',
            name: 'Contract credit n* CR RON202986 pentru Bicicleta Pegas',
            financedGood: 'Trotineta roz',
            payed: 300,
            payedInstallments: 9,
            maximumInstallments: 24,
            totalDue: 1500,
            state: 'collapsed'
        }
    ];

    history: EarlyPaymentHistory[] = [
        {
            title: 'Plata anticipata partiala - CR RON446267 ',
            doc: '20/05/2021',
            state: 'in asteptare plata',
            stateBullet: 'red',
            earlyRepaymentAmount: 10000,
            newInstallmentAmount: 1452.6,
            newInstallmentsNumber: 45,
            financedGood: 'Renault Megane 1.6',
            uiState: 'collapsed'
        },
        {
            title: 'Plata anticipata partiala - CR RON123123',
            doc: '20/05/2021',
            state: 'procesata',
            stateBullet: 'green',
            earlyRepaymentAmount: 10000,
            newInstallmentAmount: 1452.6,
            newInstallmentsNumber: 45,
            financedGood: 'Renault Megane 1.6',
            uiState: 'collapsed'
        },

    ]

    snackErrorRef!: MatSnackBarRef<any>;
    contractsContainerHeight: any;
    backUpContracts: EarlyPaymentContract[] = [];
    selectedPaymentMethod = 1;
    currentStep = 1;
    showFinalConfirmation = false;
    pageStep = 1;
    steps: { index: number; state: 'active' | 'inactive' | 'passed' }[] = [
        {
            index: 1,
            state: 'inactive'
        },
        {
            index: 2,
            state: 'inactive'
        },
        {
            index: 3,
            state: 'inactive'
        }
    ];

    amount!: number;
    choice = '';

    ngOnInit() {
        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.target === this.contractsContainer.nativeElement) {
                    this.contractsContainerHeight = entry.contentRect.height;
                    this.cdr.detectChanges();
                }
            }
        });
    }

    ngAfterViewInit() {
        this._alert.setDefaultViewContainerRef(this.targetViewContainerRef);

        if (this.contractsContainer && this.contractsContainer.nativeElement) {
            this.contractsContainerHeight = this.contractsContainer.nativeElement.offsetHeight;
            this.resizeObserver.observe(this.contractsContainer.nativeElement);
        }

        const sliderHandle = document.querySelector('.installments-slider .mdc-slider__thumb-knob');
        if (sliderHandle) {
            sliderHandle.innerHTML = '<i class="icon-mobilize-chevron-left"></i><i class="icon-mobilize-chevron-right"></i>';
        }
    }

    toggleContractState(contract: EarlyPaymentContract) {
        this.contracts.forEach(item => {
            if (item.id !== contract.id) {
                item.state = 'collapsed';
                item.amount = null;
                this.currentStep = 1
            } else {
                contract.state = 'extended';
            }
        });
    }

    get bulletStateClass(): string[] {
        return this.steps.map(step => {
            if (step.index === this.currentStep) {
                return 'active';
            } else if (step.index < this.currentStep) {
                return 'passed';
            } else {
                return 'inactive';
            }
        });
    }

    historyStateClass(state: string): string {
        return `state-${state}`;
    }

    newInstallments(e: any) {
        console.log(e)
    }

    back() {
        this.pageStep = 1;
        this.contracts[0].state = 'extended';
        this.contracts[0].amount = null;
        this.currentStep = 1;
        this.contracts = this.backUpContracts;
    }

    selectPay(method: number) {
        if (method === 1) {
            this._alert.closeAlert();
        } else if (method === 2) {
            this._alert.showAlert('warning', 'Dupa generarea proformei, nu mai poti reveni la pasul 1', 'icon-mobilize-warning', this.targetViewContainerRef)
        }
    }

    openInfo() {
        this._dialog.open(InfoModalComponent, {
            width: '470px'
        })
    }

    selectedTab = 'new-payment';

    nextStep(contract: EarlyPaymentContract, option?: string) {
        if (option) {
            this.choice = option;
        }

        switch (this.currentStep) {
            case 1:
                if (!contract.amount) {
                    this.snackErrorRef = this._snackbar.open('Introdu suma pe care vrei sa o achiti', 'Ok', {
                        verticalPosition: 'top',
                        panelClass: 'snack-error',
                        duration: 5000
                    });
                    return;
                } else {
                    if (this.snackErrorRef) {
                        this.snackErrorRef.dismiss();
                    }
                }
                break;
        }

        this.currentStep++;
    }

    finalizePayment() {
        this._dialog.open(AgreementModalComponent, {
            width: '600px'
        }).afterClosed().subscribe(rsp => {
            if (rsp) {
                this.pageStep = 2;
                this.backUpContracts = this.contracts;
                this.contracts = this.contracts.filter(item =>  item.state === 'extended');
                this.contracts[0].state = 'ready';
            }
        });
    }

    pay() {
        if (this.selectedPaymentMethod === 1) {
            // go to payment page
        } else {
            this.generateInvoice();
        }
    }

    generateInvoice() {
        this.showFinalConfirmation = true;
    }

    previousStep() {
        this.currentStep--;
    }

    extendItem(item: EarlyPaymentHistory) {
        if (item.uiState === 'collapsed') {
            item.uiState = 'extended';
        } else {
            item.uiState = 'collapsed';
        }
    }
}

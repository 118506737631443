import { AfterViewInit, Component, inject, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from '../../../../components/alert/alert.service';
import { NgIf } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { KeycloakService } from 'keycloak-angular';
import { ButtonLoaderDirective } from '../../../../directives/button-loader.directive';
import { finalize } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../../../../components/alert/alert.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'mobilize-change-email',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        ButtonLoaderDirective,
        AlertComponent,
        MatFormField,
        MatInput,
        MatLabel
    ],
    templateUrl: './change-email.component.html',
    styleUrl: './change-email.component.scss'
})
export class ChangeEmailComponent implements AfterViewInit {
    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;
    @ViewChild('inFormAlertContainer', {read: ViewContainerRef}) inFormAlertContainer!: ViewContainerRef;

    private _fb = inject(FormBuilder);
    private _alert = inject(AlertService);
    private _user = inject(UserService);
    private _snackBar = inject(MatSnackBar);
    private _kc = inject(KeycloakService);

    form;
    showPassword = false;
    user!: any;
    mailChangeInProgress = false;
    otp: any = ''
    changing = false;

    constructor() {
        this.form = this._fb.group({
            password: ['', Validators.required],
            newEmail: ['', [Validators.required, Validators.email]],
        });
    }

    ngAfterViewInit() {
        this._user.userData().subscribe(rsp => {
            this.user = rsp;
        })
    }

    changeEmail() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const payload = {
            password: this.form.controls['password'].value,
            newEmail: this.form.controls['newEmail'].value,
            process: 'validate'
        }
        this.changing = true;
        this._user.changeEmail(payload)
            .pipe(
                finalize(() => {
                    this.changing = false;
                })
            )
            .subscribe({
            next: (rsp) => {
                this.mailChangeInProgress = true;
            },
            error: () => {
                this._alert.showAlert('top-error', 'Parola este gresita sau emailul exista deja!', '', this.targetViewContainerRef, 3);
            }
        })
    }

    confirmChangeEmail() {
        if (this.otp === '') {
            this._alert.showAlert('top-error', 'Introdu codul OTP!', '', this.targetViewContainerRef, 3);
            return
        }

        const payload = {
            newEmail: this.form.controls['newEmail'].value,
            otp: this.otp,
            process: 'change_email'
        }

        this.changing = true;
        this._user.changeEmail(payload)
            .pipe(
                finalize(() => {
                    this.changing = false;
                })
            )
            .subscribe({
            next: (rsp) => {
                if (rsp.success) {
                    this._kc.logout(window.location.origin + '/dashboard');
                }
            },
            error: (error: any) => {
                switch (error.error.status) {
                    case 429:
                        this.otp = '';
                        this.mailChangeInProgress = false;
                        this.form.reset();
                        this._snackBar.open('Ai atins numarul maxim de incercari. Te rugam sa reincepi procesul!', 'Ok');
                        break;

                    default:
                        this._alert.showAlert('top-error', 'OTP gresit!', '', this.targetViewContainerRef, 3);
                        break;

                }
            }
        })
    }
}

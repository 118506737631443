<div class="insurance">
    <div class="header">
        <div class="insurer-name">{{ insurance.insurance_type }} {{ insurance.insurance_number }}</div>
    </div>

    <div class="content">
        <div class="details">
            @if (insurance.next_instalment_amount) {
                <div class="details__row">urmatoarea rata: <span
                    [innerHTML]="formatAmount(insurance.next_instalment_amount, insuranceCurrency)"></span>
                </div>
            }
            @if (insurance.next_instalment_date) {
                <div class="details__row">scadenta:
                    <span>{{ insurance.next_instalment_date | date: 'dd/MM/YYYY' }}</span>
                </div>
            }
            <div class="details__row">expira la: <span>{{ insurance.insurance_end_date | date: 'dd/MM/YYYY' }}</span>
            </div>
            <div class="details__row">asigurator:
                <span>{{ insurance.insurer.partner_name === 'RCI INSURANCE LIMITED_old' ? 'RCI INSURANCE LIMITED' : insurance.insurer.partner_name }}</span>
            </div>

            <button class="mobilize-btn btn-orange" (click)="insuranceDetails()">detalii</button>
        </div>

        <div class="documents">
            <div class="documents-row active-state">
                <span class="bullet"
                      [ngClass]="insurance.insurance_status === 'Valid' && 'online' || 'offline'"></span> {{ insurance.insurance_status === 'Valid' && 'activ' || 'inactiv' }}
            </div>

            <div class="documents-row">
                <span class="icon-mobilize-export"></span> Descarca polita
            </div>

            <div class="documents-row">
                <span class="icon-mobilize-bolt"></span> Imputernicire deschidere dosar dauna
            </div>
        </div>
    </div>
</div>

<div class="request">
    <div class="header">
        <div class="request-title">{{ request.request_subject }}</div>
        <div class="request-status" [ngClass]="statusClass">
            <span class="bullet"></span> {{ request.fe_status }}
        </div>
    </div>

    <div class="content">
        <div class="details">
            <div class="details__row">contract: <span>{{ request.contract_number }}</span>
            </div>
            <div class="details__row">data solicitarii: <span>{{ request.created_at | date: 'dd/MM/YYYY' }}</span>
            </div>
            <div class="details__row">nr. solicitare: <span>{{ request.request_number | padZero: 7 }}</span>
            </div>

            <button class="mobilize-btn btn-orange" (click)="requestDetails()">detalii</button>
        </div>
    </div>
</div>

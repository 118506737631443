<mobilize-page-header title="Contracte si servicii" [reverse]="true"></mobilize-page-header>

<div class="contracts-page">
    @for (contract of contracts; track contract) {
        <mobilize-contract
            class="contract-row"
            [contract]="contract">
        </mobilize-contract>
    }
</div>

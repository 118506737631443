<mobilize-page-header title="Facturi si plati" [reverse]="true"></mobilize-page-header>

<div class="bills-page-wrapper">
    <div class="to-be-payed mobilize-card small-card">
        <h5>Ai de plata</h5>
        @if (totalAmountReady) {
            <span class="amount" [innerHTML]="formatAmount(totalAmount, 'RON')"></span>
        } @else {
            <span class="amount loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </span>
        }

        <button class="mobilize-btn btn-orange pay-bills-btn" routerLink="/pay">plateste</button>
    </div>

    <mobilize-data-filter (onFilterExec)="execFilter($event)"
                          [processing]="filterProcessing"
                          (onExport)="export()"
                          [filterOptions]="filterOptions"
                          page="invoice"
                          (onContractsLoaded)="contractIds($event)"></mobilize-data-filter>

    <div class="mobilize-card table-card no-padding-card">
        <div class="invoices-table">
            <div class="invoices-table__row header-row">
                <div class="invoices-table__row-content">
                    <div class="cell header">Data</div>
                    <div class="cell header">Detalii</div>
                    <div class="cell header invoice-amount">Suma (RON)</div>
                    <div class="cell header">Factura</div>
                </div>
            </div>

            @if (dataReady) {
                @for (invoice of processedData; track invoice) {
                    @if (invoice.year) {
                        <div class="invoices-table__row year-row">
                            <div class="cell header-cell">{{ invoice.year }}</div>
                        </div>
                    } @else {
                        <div class="invoices-table__row" [ngClass]="{ 'opened': invoice.opened }">
                            <div class="invoices-table__row-content">
                                <div class="cell">
                                    <div class="invoice-date">{{ parseDateToDisplay(invoice.invoice_date) }}</div>
                                </div>
                                <div class="cell details-cell">
                                    <div class="invoice-details">{{ invoice.detaliu_factura }}</div>
                                    <div class="invoice-status-wrapper">
                                        <div [innerHTML]="invoiceStatus(invoice)"></div>
                                        <div class="due-date">scadenta
                                            la {{ invoice.data_scadenta | date: 'dd/MM/YYYY' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="cell invoice-amount">
                                    <span [innerHTML]="formatNumber(invoice.total)"></span>
                                </div>
                                <div class="cell invoice-document">
                                    @if (documentLoading) {
                                        <mat-progress-spinner class="orange-spinner" diameter="16" strokeWidth="2"
                                                              mode="indeterminate"></mat-progress-spinner>
                                    } @else {
                                        @if (invoice.contract.contract_type !== 'Credit') {
                                            <span class="icon-mobilize-receipt"
                                                  (click)="downloadInvoice(invoice)"></span>
                                        } @else {
                                            <span></span>
                                        }
                                    }
                                    @if (!invoice.opened) {
                                        <span class="icon-mobilize-chevron-down" (click)="toggleRow(invoice)"></span>
                                    } @else {
                                        <span class="icon-mobilize-chevron-up" (click)="toggleRow(invoice)"></span>
                                    }
                                </div>
                            </div>
                            <div class="invoices-table__row-extension">
                                <div class="invoices-table__row-extension-row">
                                    <span>Scadenta la:</span>
                                    <span>{{ invoice.data_scadenta | date: 'dd/MM/YYYY' }}</span>
                                </div>
                                <div class="invoices-table__row-extension-row">
                                    <span>Nr. document:</span>
                                    <span>{{ invoice.invoice_number }}</span>
                                </div>
                                <div class="invoices-table__row-extension-row">
                                    <span>Total de plata:</span>
                                    <span [innerHTML]="formatAmount(invoice.total, 'RON')"></span>
                                </div>
                                <div class="invoices-table__row-extension-row">
                                    <span>Platit:</span>
                                    <span [innerHTML]="formatAmount(invoice.paid_amount, 'RON')"></span>
                                </div>
                                <div class="invoices-table__row-extension-row">
                                    <span>Rest de plata:</span>
                                    <span [innerHTML]="formatAmount(invoice.rest_amount, 'RON')"></span>
                                </div>
                                <div class="invoices-table__row-extension-row">
                                    <span>Stare:</span>
                                    <span [innerHTML]="invoiceStatusInText(invoice)"></span>
                                </div>
                            </div>
                        </div>

                        @if (invoice.payment_allocation.length) {
                            @for (pa of invoice.payment_allocation; track pa) {
                                <div class="invoices-table__row allocation-row" [ngClass]="{ 'opened': pa.opened }">
                                    <div class="invoices-table__row-content">
                                        <div class="cell">
                                            <div class="invoice-date">{{ parseDateToDisplay(pa.allocation_date) }}</div>
                                        </div>
                                        <div class="cell details-cell">
                                            Ai platit
                                        </div>
                                        <div class="cell invoice-amount font-green">
                                            <span [innerHTML]="'-' + formatNumber(pa.payment_currency)"></span>
                                        </div>
                                        <div class="cell invoice-document">
                                            @if (!pa.opened) {
                                                <span class="icon-mobilize-chevron-down" (click)="toggleRow(pa)"></span>
                                            } @else {
                                                <span class="icon-mobilize-chevron-up" (click)="toggleRow(pa)"></span>
                                            }
                                        </div>
                                    </div>
                                    <div class="invoices-table__row-extension">
                                        <div class="invoices-table__row-extension-row">
                                            <span>Detalii tranzactie:</span>
                                            <span>{{ invoice.detaliu_factura }}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        }
                    }
                } @empty {
                    <div class="invoices-table__row year-row">
                        <div class="cell header-cell">nu exista facturi sau plati inregistrate</div>
                    </div>
                }
            } @else {
                <div class="mock-invoice-row">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            }
        </div>
    </div>

    @if (dataReady && processedData.length) {
        <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit"
                             [pagesArray]="pagesArray" (onPageChange)="onPageChange($event)"></mobilize-pagination>
    }
</div>

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { Router, RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { InstallmentsSliderComponent } from '../../components/installments-slider/installments-slider.component';
import { ContractComponent } from '../../components/contract/contract.component';
import { Contract } from '../../interfaces/contract';
import { DashboardService } from './dashboard.service';
import { BaseComponent } from '../../components/base.component';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'mobilize-dashboard',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgOptimizedImage,
        InstallmentsSliderComponent,
        ContractComponent,
        RouterLink
    ],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent extends BaseComponent implements OnInit {

    private _router = inject(Router);
    private _dashboard = inject(DashboardService);
    private _loading = inject(LoadingService);

    contracts: Contract[] = [];
    unpaidAmount = 0;
    extraPaidAmount = 0;
    overdue = false;

    constructor() {
        super();
        this._loading.setLoadingState(true);
    }

    goToDocuments(doc?: string) {
        if (doc) {
            this._router.navigateByUrl('generate-document', {state: {data: {doc}}});
        } else {
            this._router.navigateByUrl('generate-document');
        }
    }

    ngOnInit() {
        this._dashboard.getDashboardContracts().subscribe(rsp => {
            this.contracts = rsp.data;
            this.contracts.forEach((item, idx) => {
                if (item.overdue_invoices_ron > 0) {
                    this.overdue = true;
                }
                if (idx === 0) {
                    item.contractState = 'opened';
                } else {
                    item.contractState = 'closed';
                }
                item.extendable = true

                this.unpaidAmount += parseFloat(item.unpaid_invoices_ron);
            });

            if (rsp.cardPayments.total_paid) {
                // this.unpaidAmount = this.unpaidAmount - parseFloat(rsp.cardPayments.total_paid)
            }

            this.extraPaidAmount = rsp.total_advance;

            this._loading.setLoadingState(false);
        });
    }

    pay() {
        this._router.navigateByUrl('/pay');
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private _http: HttpClient) {
    }

    getAssociatedUsers(payload: any): Observable<any> {
        return this._http.post('register/associated-users', payload);
    }

    resendInvite(payload: { enrollment_uid: string; }): Observable<any> {
        return this._http.post('register/resend-invite', payload);
    }

    passAdminRights(payload: any): Observable<any> {
        return this._http.post('register/pass-admin-rights', payload);
    }

    remove(payload: {email: string}): Observable<any> {
        return this._http.post('register/remove', payload);
    }

    cancelInvite(payload: {enrollment_uid: string}): Observable<any> {
        return this._http.post('register/cancel-invite', payload);
    }

    getAllUsers(payload: any): Observable<any> {
        return this._http.post('user/full-list', payload);
    }

    deleteUser(payload: any): Observable<any> {
        return this._http.post('user/delete-user', payload);
    }

    disableUser(payload: any): Observable<any> {
        return this._http.post('user/disable-user', payload);
    }

    enableUser(payload: any): Observable<any> {
        return this._http.post('user/enable-user', payload);
    }

    updateUser(payload: any): Observable<any> {
        return this._http.post('user/update-user', payload);
    }
}

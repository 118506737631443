import { Component, inject, Input, OnInit } from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MENU } from '../../constants/menu';
import { of } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BACKOFFICE_MENU } from '../../constants/backoffice-menu';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'mobilize-main-menu',
    standalone: true,
    imports: [
        NgOptimizedImage,
        RouterLink,
        NgIf
    ],
    animations: [
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('300ms ease-in-out')
            ])
        ]),
        trigger('rotateChevron', [
            state('default', style({ transform: 'rotate(0deg)' })),
            state('rotated', style({ transform: 'rotate(180deg)' })),
            transition('default <=> rotated', animate('300ms ease-in-out')),
        ])
    ],
    templateUrl: './main-menu.component.html',
    styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent implements OnInit{

    @Input() backOffice!: boolean;

    private _kc = inject(KeycloakService);
    private _router = inject(Router);

    menu!: any;
    isOpen = false;
    showLogoutSubMenu = false;

    ngOnInit() {
        console.log(this.backOffice)
        this.menu = this.backOffice ? BACKOFFICE_MENU : MENU
    }

    logout() {
        this._kc.logout();
    }

    toggleMenu() {
        this.isOpen = !this.isOpen;
    }

    toggleLogoutSubMenu() {
        this.showLogoutSubMenu = !this.showLogoutSubMenu;
    }
}

import { Component, inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { AlertService } from '../../../../components/alert/alert.service';
import { KeycloakService } from 'keycloak-angular';
import { ButtonLoaderDirective } from '../../../../directives/button-loader.directive';
import { finalize } from 'rxjs';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'mobilize-change-password',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatTooltip,
        NgIf,
        ButtonLoaderDirective,
        MatFormField,
        MatInput,
        MatLabel
    ],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {
    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;

    private _fb = inject(FormBuilder);
    private _user = inject(UserService);
    private _alert = inject(AlertService);
    private _kc = inject(KeycloakService);

    form;
    user!: any;
    showPassword = false;
    showConfirmPassword = false;
    showOldPassword = false;
    processing = false;

    constructor() {
        this.form = this._fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/)]],
            newPasswordConfirmation: ['', [Validators.required, RxwebValidators.compare({fieldName: 'newPassword'})]]
        });
    }

    ngOnInit() {
        this._user.userData().subscribe(rsp => {
            this.user = rsp;
        })
    }

    changePassword() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (this.form.controls['oldPassword'].value === this.form.controls['newPassword'].value) {
            this._alert.showAlert('top-error', 'Parola noua nu poate sa fie la fel ca parola actuala!', '', this.targetViewContainerRef, 3);
            return;
        }

        const payload = {
            oldPassword: this.form.controls['oldPassword'].value,
            newPassword: this.form.controls['newPassword'].value
        }
        this.processing = true;
        this._user.changePassword(payload)
            .pipe(
                finalize(() => {
                    this.processing = false;
                })
            )
            .subscribe({
            next: (rsp) => {
                this._kc.logout(window.location.origin + '/dashboard');
            },
            error: () => {
                this._alert.showAlert('top-error', 'Parola actuala este gresita!', '', this.targetViewContainerRef, 3);
            }
        })
    }
}

import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AsyncPipe, NgForOf } from '@angular/common';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { map, Observable, startWith } from 'rxjs';
import { RequestService } from '../../../services/request.service';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';

@Component({
    selector: 'mobilize-requests-filter',
    standalone: true,
    imports: [
        AsyncPipe,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatInput,
        MatLabel,
        MatOption,
        MatSuffix,
        NgForOf,
        ReactiveFormsModule,
        ReactiveTypedFormsModule,
        ButtonLoaderDirective
    ],
    templateUrl: './requests-filter.component.html',
    styleUrl: './requests-filter.component.scss'
})
export class RequestsFilterComponent implements OnInit, OnChanges {

    private _fb = inject(FormBuilder);
    private _requests = inject(RequestService);

    @Input() processing: boolean = false;
    @Input() filterActive: boolean = false;
    @Output() filtersLoaded = new EventEmitter<any>();
    @Output() onCloseFilters = new EventEmitter<any>();
    @Output() onApplyFilters = new EventEmitter<any>();

    partners: any[] = [];
    contracts: any[] = [];
    categories: any[] = [];
    statuses: any[] = [];
    filterForm: FormGroup;
    filters = false;

    filteredPartners!: Observable<any[]>;
    filteredContracts!: Observable<any[]>;
    filteredCategories!: Observable<any[]>;
    filteredStatuses!: Observable<any[]>;

    constructor() {
        this.filterForm = this._fb.group({
            partner: [null],
            contract: [null],
            category: [null],
            startDate: [null],
            endDate: [null],
            status: [null],
        });
    }

    ngOnChanges(changes: any) {
        if (changes.filterActive) {
            console.log('filterActive changed:', this.filterActive);
            // Handle changes to filterActive
        }
    }

    sortRequests(requestTypes: any[]): any[] {
        return requestTypes.sort((a, b) => {
            if (a.request_type_name === "Altele") return 1;
            if (b.request_type_name === "Altele") return -1;
            return 0;
        });
    }

    ngOnInit() {
        this._requests.getFilters().subscribe(rsp => {
            this.partners = rsp.partners;
            this.contracts = rsp.contracts;
            this.categories = rsp.categories;
            this.statuses = rsp.statuses;
            this.filtersLoaded.next(true);
            this.filters = true;

            this.categories = this.sortRequests(this.categories);
        });
        this.filteredPartners = this.filterForm.controls['partner'].valueChanges.pipe(
            startWith(''),
            map(value => {
                return this._filterPartners(value || '')
            }),
        );
        this.filteredContracts = this.filterForm.controls['contract'].valueChanges.pipe(
            startWith(''),
            map(value => this._filterContracts(value || '')),
        );
        this.filteredCategories = this.filterForm.controls['category'].valueChanges.pipe(
            startWith(''),
            map(value => this._filterCategories(value || '')),
        );
        this.filteredStatuses = this.filterForm.controls['status'].valueChanges.pipe(
            startWith(''),
            map(value => this._filterStatuses(value || '')),
        );
    }

    private _filterPartners(value: string): string[] {
        const filterValue = value;

        if (!Number.isInteger(filterValue)) {
            return this.partners.filter(partner => partner.partner_name.toLowerCase().includes(filterValue));
        } else {
            return this.partners.filter(partner => partner.partner_id.toString().includes(filterValue));
        }
    }

    private _filterContracts(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.contracts.filter(contract => contract.contract_number.includes(filterValue));
    }

    private _filterStatuses(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.statuses.filter(status => status.bo_status.toLowerCase().includes(filterValue));
    }

    private _filterCategories(value: string): string[] {
        const filterValue = value.toLowerCase();

        if (!this.isUuid(filterValue)) {
            return this.categories.filter(category => category.request_type_name.toLowerCase().includes(filterValue));
        } else {
            return this.categories.filter(category => category.request_type_uid.includes(filterValue));
        }
    }

    getPartnerName(partnerId: any) {
        if (this.partners.find((partner) => partner.partner_id === partnerId)) {

            return this.partners.find((partner) => partner.partner_id === partnerId).partner_name;
        }
    }

    getCategoryName(categoryUid: any) {
        if (this.categories.find((category) => category.request_type_uid === categoryUid)) {
            return this.categories.find((category) => category.request_type_uid === categoryUid).request_type_name;
        }
    }

    getContractNumber(contractNumber: any) {
        if (this.contracts.find((contract) => contract.contract_number === contractNumber)) {
            return this.contracts.find((contract) => contract.contract_number === contractNumber).contract_number;
        }
    }

    getStatusName(status_name: any) {
        if (this.statuses.find((status) => status.bo_status === status_name)) {
            return this.statuses.find((status) => status.bo_status === status_name).bo_status;
        }
    }

    isUuid(value: string): boolean {
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidPattern.test(value);
    }

    applyFilters() {
        if (Object.keys(this.filterForm.value).every(key => this.filterForm.value[key] === null)) {
            return;
        }
        this.processing = true;
        this.onApplyFilters.next(this.filterForm.value);
    }

    closeFilter() {
        this.filterForm.reset();
        this.onCloseFilters.next(true);
    }

    resetFilter() {
        this.filterForm.reset();
        this.onApplyFilters.next({...this.filterForm.value, reset: true });
        this.onCloseFilters.next(true);
        this.filterActive = false;
    }
}

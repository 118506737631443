import { Menu } from '../interfaces/menu';

const iconPrefix = 'icon-mobilize-';
export const BACKOFFICE_MENU: Menu[] = [
	{
		title: 'Cereri clienti',
		icon: iconPrefix + 'folder-outline',
		route: '/backoffice/client-requests'
	},
	{
		title: 'Cereri clienti escaladate',
		icon: iconPrefix + 'folder-plus-outline',
		route: '/backoffice/client-escalated-requests'
	},
	{
		title: 'Raport cereri clienti',
		icon: iconPrefix + 'folder-check',
		route: '/backoffice/requests-reports'
	},
	{
		title: 'User management',
		icon: iconPrefix + 'user-edit-outline',
		route: '/backoffice/user-management'
	},
	// {
	// 	title: 'Confirmari plati online',
	// 	icon: iconPrefix + 'credit-card-check-outline',
	// 	route: '/insurance'
	// },
	// {
	// 	title: 'Rapoarte feedback',
	// 	icon: iconPrefix + 'star-outline',
	// 	route: '/existing-documents'
	// },
	{
		title: 'Impersonare client',
		icon: iconPrefix + 'users-outline',
		route: '/backoffice/client-impersonation'
	},
	{
		title: 'Logout',
		icon: iconPrefix + 'key-outline',
		route: 'logout'
	},
]

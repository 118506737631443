import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatHint, MatLabel } from '@angular/material/select';
import {
	MatDatepicker,
	MatDatepickerInput,
	MatDatepickerModule,
	MatDatepickerToggle
} from '@angular/material/datepicker';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { MatCheckbox } from '@angular/material/checkbox';

interface JsonFormValidators {
	min?: number;
	max?: number;
	required?: boolean;
	requiredTrue?: boolean;
	email?: boolean;
	minLength?: boolean;
	maxLength?: boolean;
	pattern?: string;
	nullValidator?: boolean;
}

interface JsonFormControlOptions {
	min?: string;
	max?: string;
	step?: string;
	icon?: string;
	endpoint?: string;
	selectObject?: string;
}

interface JsonFormControls {
	name: string;
	label: string;
	value: string;
    placeholder?: string;
	hidden?: boolean;
	type: string;
	validators: JsonFormValidators;
}

export interface JsonFormData {
	controls: JsonFormControls[];
}

@Component({
	selector: 'mobilize-json-form',
	templateUrl: './json-form.component.html',
	standalone: true,
	providers: [
		provideNativeDateAdapter(),
		DatePipe
	],
	imports: [
		ReactiveFormsModule,
		NgForOf,
		NgIf,
		MatFormField,
		MatInput,
		MatLabel,
		MatDatepickerModule,
		MatNativeDateModule,
		MatButton,
		MatSuffix,
		MatHint,
		FormsModule,
		MatCheckbox
	],
	styleUrls: ['./json-form.component.scss']
})
export class JsonFormComponent implements OnChanges, OnInit {


	private route = inject(ActivatedRoute);

	@Input() jsonFormData!: JsonFormData;
	@Input() documentName!: string;

	myForm: FormGroup;
	date!: any;
	datePipe = inject(DatePipe);
	params: any;
	otherSelection = false;


    constructor(private fb: FormBuilder) {
        this.myForm = this.fb.group({});
    }
	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
				this.params = params;
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes['jsonFormData'].firstChange) {
			this.myForm = this.fb.group({});
		}

        this.createForm(this.jsonFormData.controls);
	}

	createForm(controls: JsonFormControls[]) {
		for (const control of controls) {
			const validatorsToAdd = [];
			for (const [key, value] of Object.entries(control.validators)) {
				switch (key) {
					case 'min':
						validatorsToAdd.push(Validators.min(value));
						break;
					case 'max':
						validatorsToAdd.push(Validators.max(value));
						break;
					case 'required':
						if (value) {
							validatorsToAdd.push(Validators.required);
						}
						break;
					case 'requiredTrue':
						if (value) {
							validatorsToAdd.push(Validators.requiredTrue);
						}
						break;
					case 'email':
						if (value) {
							validatorsToAdd.push(Validators.email);
						}
						break;
					case 'minLength':
						validatorsToAdd.push(Validators.minLength(value));
						break;
					case 'maxLength':
						validatorsToAdd.push(Validators.maxLength(value));
						break;
					case 'pattern':
						validatorsToAdd.push(Validators.pattern(value));
						break;
					case 'nullValidator':
						if (value) {
							validatorsToAdd.push(Validators.nullValidator);
						}
						break;
					default:
						break;
				}
			}
			this.myForm.addControl(
				control.name,
				this.fb.control(control.value, validatorsToAdd)
			);
		}
	}

    submitForm() {

    }
}

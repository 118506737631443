<mobilize-page-header [title]="pageTitle" [reverse]="true"></mobilize-page-header>

<div class="payment-response-wrapper mobilize-card">
    @if (params.status === '0') {
        <span class="icon-mobilize-check-mark font-orange"></span>
        <p>Tranzactia a fost trimisa catre <b>BRD</b>. In scurt timp vei primi un mail de confirmare.</p>
        <span class="infoPaymentSpan">Plata efectuata cu cardul va fi reflectata in contul dumneavoastra din platforma MyMobilize in maximum 5 zile.</span>
    } @else {
        <span class="icon-mobilize-close"></span>
        <p>Tranzactia a fost respinsa.</p>
    }
    <div class="actions">
        <button class="mobilize-btn btn-orange" routerLink="/dashboard">Mergi la ecranul principal</button>
    </div>
</div>

@if (dataLoaded) {
    <div class="mobilize-card">
        <form [formGroup]="form" class="">

            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Judet/Sector*:</mat-label>
                        <input matInput type="text" formControlName="county" placeholder="">
                    </mat-form-field>
                </div>
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Oras*:</mat-label>
                        <input matInput type="text" formControlName="city" placeholder="">
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Strada*:</mat-label>
                        <input matInput type="text" formControlName="street" placeholder="">
                    </mat-form-field>
                </div>
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Nr.*:</mat-label>
                        <input matInput type="text" formControlName="streetNumber" placeholder="">
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Bloc:</mat-label>
                        <input matInput type="text" formControlName="building" placeholder="">
                    </mat-form-field>
                </div>
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Ap:</mat-label>
                        <input matInput type="text" formControlName="apartment" placeholder="">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
}

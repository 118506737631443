

const DocumentDetailsPersonControls = [
    {
        name: 'name',
        label: 'Nume imputernicit',
        value: '',
        type: 'text',
        placeholder: 'scrie valoarea',
        validators: {
            required: true
        }
    },
    {
        name: 'id',
        label: 'Serie si numar CI imputernicit',
        value: '',
        type: 'text',
        placeholder: 'scrie valoarea',
        validators: {
            required: true
        }
    }
];

const DocumentDetailsDateControls = [
    {
        name: 'startDate',
        label: 'Data start',
        value: '',
        type: 'date',
        placeholder: 'zz/ll/aaaa',
        validators: {
            required: true
        }
    },
    {
        name: 'endDate',
        label: 'Data end',
        value: '',
        type: 'date',
        placeholder: 'zz/ll/aaaa',
        validators: {
            required: true
        }
    }
];

export const DocumentTypes = [
    {
        name: 'Imputernicire deschidere dosar dauna',
        controls: DocumentDetailsPersonControls,
        visible: true
    },
    {
        name: 'Imputernicire iesire din tara',
        controls: [...DocumentDetailsPersonControls, ...DocumentDetailsDateControls],
        visible: true
    },
    {
        name: 'Cerere modifcari contractuale',
        controls: [],
        visible: true
    },
    {
        name: 'Copie Asigurarea ADI',
        controls: [],
        visible: true
    },
    {
        name: 'Copie CASCO',
        controls: [],
        visible: true
    },
    {
        name: 'Copie RCA',
        controls: [],
        visible: true
    },
    {
        name: 'Copie CIV (cartea de identitate a vehiculului)',
        controls: [],
        visible: true
    }
];

import { Component } from '@angular/core';
import { InsuranceBoxComponent } from '../../components/insurance-box/insurance-box.component';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { DocumentBoxComponent } from '../../components/document-box/document-box.component';
import { Document } from '../../interfaces/document';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'mobilize-documents',
    standalone: true,
    imports: [
        InsuranceBoxComponent,
        PageHeaderComponent,
        DocumentBoxComponent,
        RouterLink
    ],
    templateUrl: './existing-documents.component.html',
    styleUrl: './existing-documents.component.scss'
})
export class ExistingDocumentsComponent {

    documents: Document[] = [
        {
            name: 'Imputernicire iesire din tara',
            createdAt: '20/05/2021',
            expireAt: '20/10/2024',
            contract: 'CR RON234343 - Renault Megane',
            documentUrl: 'bla bla'
        },
        {
            name: 'Imputernicire deschidere dosar dauna',
            createdAt: '20/05/2021',
            expireAt: '20/07/2021',
            contract: 'CR RON234343 - Renault Megane 1.6',
            documentUrl: 'bla bla'
        }
    ]
}

import { Component, inject, OnInit } from '@angular/core';
import { ScheduleService } from '../../../services/schedule.service';
import { DatePipe, NgClass } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateDocumentService } from '../../../services/generate-document.service';
import { DataFilterComponent } from '../../../components/data-filter/data-filter.component';
import { saveAs } from 'file-saver';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { BaseComponent } from '../../../components/base.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MiscService } from '../../../services/misc.service';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'mobilize-future-due-dates',
    standalone: true,
    imports: [
        DatePipe,
        DataFilterComponent,
        MatProgressBar,
        MatProgressSpinner,
        PaginationComponent,
        NgClass
    ],
    providers: [
        provideMomentDateAdapter(MY_FORMATS),
        DatePipe
    ],
    templateUrl: './future-due-dates.component.html',
    styleUrl: './future-due-dates.component.scss'
})
export class FutureDueDatesComponent extends BaseComponent implements OnInit {

    private _schedule = inject(ScheduleService);
    private _datePipe = inject(DatePipe);
    private _snackbar = inject(MatSnackBar);
    private _router = inject(Router)
    private _route = inject(ActivatedRoute);
    private _generateDocument = inject(GenerateDocumentService);
    private _misc = inject(MiscService);

    currentPage = 1;
    limit = 10;
    totalPages: any;
    pagesArray: any[] = [];
    processedData: any;
    dataReady = false;
    filterProcessing = false;
    endOfMonth: any;
    filterArgs: any;
    currentDate: any;
    contractsArrayIds: any;
    amountRon = 0
    amountEur = 0;
    filterOptions = ['range', 'contracts']

    ngOnInit() {
        this._misc.sendData({tab: 'Scadente viitoare'});
        this.currentDate = new Date();
        this.endOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    }

    getSchedules(payload?: any) {
        this.amountRon = 0
        this.amountEur = 0;
        this._route.queryParams.subscribe(params => {
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;

            this._schedule.getFutureSchedules({
                ...payload,
                page: this.currentPage,
                limit: this.limit
            }).subscribe((schedules) => {
                this.amountEur = schedules.summedAmounts.amountEur
                this.amountRon = schedules.summedAmounts.amountRon
                this.processedData = this._misc.processData(schedules.data);
                this.dataReady = true;
                this.filterProcessing = false;
                this.totalPages = schedules.totalPages;
                this.currentPage = schedules.currentPage;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
            });
        });
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    export() {
        const preparedJson: any[] = []
        this.processedData.forEach(() => {
            preparedJson.push({})
        });

        if (!this.filterArgs) {
            this.filterArgs = {
                startDate: this.currentDate,
                endDate: this.endOfMonth,
                contracts: this.contractsArrayIds
            }
        }
        this._generateDocument.excel({
            body: preparedJson,
            filter: this.filterArgs,
            section: '07c3ab66-dda2-4a52-aebc-a55a94eccc42'
        })
            .pipe(
                catchError((err: any) => {
                    if (err.status === 404) {
                        this._snackbar.open('Nu s-a putut genera documentul', 'Ok', {
                            panelClass: 'error-snack'
                        })
                    }
                    return of();
                })
            )
            .subscribe(rsp => {
                const date = this._datePipe.transform(new Date(), 'dd/MM/YYYY HH:mm')
                saveAs(rsp, `${date}-scadente-viitoare.xlsx`);
            });
    }

    contractIds(e: any) {
        this.getSchedules({startDate: this.currentDate, endDate: this.endOfMonth, contracts: e});
        this.contractsArrayIds = e;
    }

    toggleRow(row: any) {
        row.opened = !row.opened;
    }

    execFilter(e: any) {
        this.dataReady = false;
        this.filterProcessing = true;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.getSchedules(e);
        });
    }

}

import { Component, inject, Input } from '@angular/core';
import { Document } from '../../interfaces/document';
import { DatePipe, NgClass } from '@angular/common';
import { BaseComponent } from '../base.component';
import { GenerateDocumentService } from '../../services/generate-document.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';

@Component({
    selector: 'mobilize-document-box',
    standalone: true,
    imports: [
        NgClass,
        DatePipe,
        ButtonLoaderDirective
    ],
    templateUrl: './document-box.component.html',
    styleUrl: './document-box.component.scss'
})
export class DocumentBoxComponent {

    private _generate = inject(GenerateDocumentService);

    @Input() document!: Document;

    processing = false;

    documentDownload() {
        this.processing = true;
        const payload = {
            metadata: this.document.metadata_uid
        }
        this._generate.generatePdf(payload).subscribe({
            next: (blob: Blob) => {
                this.processing = false;
                const downloadURL = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = `${this.document.document_type_name}.pdf`;
                link.click();

                // Clean up the URL object after download
                window.URL.revokeObjectURL(downloadURL);
            },
            complete: () => {
                this.processing = false;
            }
        });
    }

    get expireAt() {
        return this.document.metadata.endDate;
    }

    get isValid() {
        return true;
    }
}

<mobilize-page-header title="Raport cereri clienti" [reverse]="true"></mobilize-page-header>

<div class="requests-filter">
    <div class="requests-filter__search">
        <mobilize-requests-search (onSearch)="search($event)" [query]="query"></mobilize-requests-search>
    </div>
    <div class="requests-filter__filter-trigger" (click)="toggleFilter()">
        <div class="label"><i class="icon-mobilize-filter"></i> Filtreaza</div>
        <i class="icon-mobilize-chevron-down" [@rotateChevron]="showFilter ? 'open' : 'closed'"></i>
    </div>
    <button class="mobilize-btn btn-orange" (click)="downloadReport()">Descarca Raport</button>
</div>

<div class="filter-wrapper mobilize-card" [@slideToggle]="showFilter ? 'open' : 'closed'">
    <mobilize-requests-filter
        [processing]="isProcessing"
        [filterActive]="filterActive"
        (filtersLoaded)="canShowFilters($event)"
        (onApplyFilters)="applyFilters($event)"
        (onCloseFilters)="toggleFilter()"></mobilize-requests-filter>
</div>

@if (dataReady) {
    <div class="requests-stats">
        <div class="requests-stats__box">
            <span class="requests-stats__box-label">Primite</span>
            <span>{{ counts.received }}</span>
        </div>
        <div class="requests-stats__box">
            <span class="requests-stats__box-label">Citite</span>
            <span>{{ counts.read }}</span>
        </div>
        <div class="requests-stats__box">
            <span class="requests-stats__box-label">Escalate</span>
            <span>{{ counts.escalated }}</span>
        </div>
        <div class="requests-stats__box">
            <span class="requests-stats__box-label">Raspunse</span>
            <span>{{ counts.responded }}</span>
        </div>
    </div>
}

<div class="requests-table">
    <div class="requests-table__header">
        <div class="requests-table-row">
            <div class="table-cell reference">Referinta</div>
            <div class="table-cell partner">Partener</div>
            <div class="table-cell contract">Contract</div>
            <div class="table-cell">Categorie</div>
            <div class="table-cell status">Status</div>
            <div class="table-cell general-date">Primita</div>
            <div class="table-cell general-date">Last Update</div>
            <div class="table-cell deadline">In time</div>
        </div>
    </div>
    <div class="requests-table__content">
        @for (report of reports; track report) {
            <div class="requests-table-row" (click)="reportDetails(report);">
                <div class="table-cell reference">{{ report.request_number | padZero: 7 }}</div>
                <div class="table-cell partner">{{ report.partner.partner_name }}</div>
                <div class="table-cell contract">{{ report.contract_number }}</div>
                <div class="table-cell">{{ report.request_subject }}</div>
                <div class="table-cell status">{{ report.bo_status }}</div>
                <div class="table-cell general-date">{{ report.created_at | date: 'dd/MM HH:mm' }}</div>
                <div class="table-cell general-date">{{ report.updated_at | date: 'dd/MM HH:mm' }}</div>
                <div class="table-cell deadline"
                     [ngClass]="{'in-time': report.inTime}">{{ report.inTime ? 'Yes' : 'No' }}
                </div>
            </div>
        } @empty {
            <div class="requests-table-row no-requests">
                Nu exista cereri clienti
            </div>
        }
    </div>
</div>
@if (dataReady) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}

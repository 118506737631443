import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '../../services/register.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReactiveTypedFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'mobilize-confirm-admin-account',
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        MatLabel,
        MatTooltip,
        NgIf,
        ReactiveFormsModule,
        ReactiveTypedFormsModule,
        ButtonLoaderDirective
    ],
    templateUrl: './confirm-admin-account.component.html',
    styleUrl: './confirm-admin-account.component.scss'
})
export class ConfirmAdminAccountComponent implements OnInit {
    private _route = inject(ActivatedRoute);
    private _register = inject(RegisterService);
    private _router = inject(Router);
    private _loader = inject(LoadingService);
    private _fb = inject(FormBuilder);

    showPasswordForm = false;
    form: FormGroup;
    showPassword = false;
    showConfirmPassword = false;
    processing = false;
    uid: string = '';

    constructor() {
        this.form = this._fb.group({
            newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/)]],
            newPasswordConfirmation: ['', [Validators.required, RxwebValidators.compare({fieldName: 'newPassword'})]]
        });
    }

    ngOnInit() {
        this._route.params.subscribe((params: any) => {
            if (params && params.uid) {
                this.uid = params.uid;
                // check if enrollment exists and is not active
                this._register.validateAdmin({uid: params.uid}).subscribe(rsp => {
                    if (rsp.success) {
                        this.showPasswordForm = true;
                    }
                });
            }
        })
    }

    setPassword() {
        this._loader.setLoadingState(true);
        this.processing = true;

        this._register.setAdminPassword({uid: this.uid, password: this.form.value.newPassword}).subscribe((rsp: any) => {
            if (rsp.success) {
                //redirect to auth
                this._router.navigateByUrl('/dashboard');
            }
        }).add(() => {
            this._loader.setLoadingState(false);
            this.processing = false;
        });
    }
}

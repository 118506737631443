import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    private _http = inject(HttpClient);

    registerStep = new BehaviorSubject<any>({});

    checkInitialData(payload: any): Observable<any> {
        return this._http.post('register/validate-data', payload);
    }

    validateOtp(payload: { otp: string; enrollment_uid: string }): Observable<any> {
        return this._http.post('register/validate-otp', payload);
    }

    createUser(payload: any): Observable<any> {
        return this._http.post('register/create-user', payload);
    }

    createAssociatedUser(payload: any): Observable<any> {
        return this._http.post('register/associate-user', payload);
    }

    validateEnrollment(payload: { enrollment_uid: string }): Observable<any> {
        return this._http.post('register/validate-enrollment', payload);
    }

    sendRegisterData(payload: any) {
        this.registerStep.next(payload);
    }

    validateRecaptcha(payload: any): Observable<any> {
        return this._http.post('recaptcha/validate', payload);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GenerateDocumentService {

    constructor(private _http: HttpClient) {
    }

    excel(payload: any) {
        return this._http.post('excel/export', payload, { responseType: 'blob'});
    }
}
